import React,{useState,useEffect} from 'react'
import DrawerForm from '../../../Common/Forms/DrawerForm/DrawerForm'
import Button from '@material-ui/core/Button'
import { useFormik } from 'formik';
import MySelect from '../../../Common/Forms/MySelect'
import MyInput from '../../../Common/Forms/MyInput'
import { useMutation, useQueryClient } from 'react-query';
import { api_callback, postDataCarrier, trans } from '../../../../globals';
import * as yup from 'yup';

export default ({shipment_id,stops}) => {

    const [isOpen, setOpen] = useState(false)
    const queryClient = useQueryClient()
    const [stopsOptions,setstopOptions] = useState([])
    
    // handle stops options for multiselect
    useEffect(() => {
        let temp =[]
        for (let index = 0; index < stops; index++) {
            temp.push({label:`Στάση ${index+1}`,value:index})
        }
        setstopOptions(temp) 
    },[stops])
    // Form
    const formik = useFormik({
        initialValues:{shipment_id},
        validationSchema,
        onSubmit:(body)=>{
            //alert(JSON.stringify(body))
            changeShipmentState.mutate(body)
            formik.resetForm({shipment_id})
        }
    })
    useEffect(() => formik.resetForm({shipment_id}), [isOpen])

    //Mutation
    const changeShipmentState = useMutation((body)=> postDataCarrier(body,`api/shipments/state`),{
        onSuccess:()=>{
        api_callback(true,trans('notifications.success'),'Η κατάσταση της μεταφοράς άλλαξε')
        setOpen(false)
        //update shipment
        queryClient.invalidateQueries(['carrier','shipmentinfo',shipment_id])
        } 
    })

    return (
        <>
        <Button
         style={{textTransform:'none'}}
         variant='contained' 
         color='primary'
         onClick={()=>setOpen(!isOpen)}
         >{trans('shipmentinfo.change_state')}</Button>
 
        <DrawerForm
        isOpen={isOpen} 
        setOpen={setOpen}
        title={trans('shipmentinfo.change_shipment_state')}
        >
        <form onSubmit={formik.handleSubmit}>
        <MySelect 
        isMulti={true}
        options={stopsOptions}
        name='stops'
        error={formik.errors.stops}
        onChange={formik.setFieldValue}
        label={'Στάσεις'}
        />

        <MySelect 
        options={stateOptions}
        name='state'
        error={formik.errors.state}
        onChange={formik.setFieldValue}
        label={trans('common.labels.state')}
        />
        <MyInput
        name='comment'
        error={formik.errors.comment}
        label={trans('common.labels.comment')}
        onChange={formik.handleChange}
        
        />
        <Button
         style={{textTransform:'none'}}
        type='submit'
        variant='contained'
        color='primary'
        className='mt-3'
        >
        {changeShipmentState.isLoading? "Loading...":trans('shipmentinfo.change_state') }
        </Button>
        </form>

    </DrawerForm>
 </>
    )
}

const stateOptions = [
    {label:'Σε 15 λεπτά θα φτάσω στην τοποθεσια παραλαβής',value:'START'},
    {label:'Παρέλαβα τα προιόντα',value:'PICKUP'},
    {label:'Σε 15 λεπτά θα φτάσω στην τοποθεσια παράδοσης',value:'DROPOFF'},
    {label:'Παρέδωσα τα προιόντα',value:'END'},
]

const validationSchema =  yup.object().shape({
    state: yup.string().required().label('State'),
    comment: yup.string().label('Comment'),
  });