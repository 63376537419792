import React from 'react'
import Row from '../../Common/Layout/Row'
import Divider from '@material-ui/core/Divider'
export default function NotificationCard({notification,type='customer'}) {
      
  var Difference_In_Time = new Date().getTime() - new Date(notification.createdAt).getTime();
  var Difference_In_Days = parseInt(Difference_In_Time / (1000 * 3600 * 24))
  var Difference_In_minutes = parseInt(Difference_In_Time / (1000 * 60 ))
  var Difference_In_seconds= parseInt(Difference_In_Time / (1000 ))
  var Difference_In_hours= parseInt(Difference_In_Time / (1000 * 3600 ))
  let timeAgo=''

  if(Difference_In_Days==0){
    if(Difference_In_hours==0){
      if(Difference_In_minutes==0){
        timeAgo = `${Difference_In_seconds} δευτ. πριν`
      }else{
      timeAgo = `${Difference_In_minutes} λεπτά πριν`
      }
    }else{
      timeAgo = `${Difference_In_hours} ώρες πριν` 
    }
  }else{
    timeAgo = `${Difference_In_Days} μέρες πριν`  
  }
  

    return (
        <>
        <div
        style={{cursor: "pointer"}}
        className='notification_card'
         onClick={()=>type=='customer' ? window.location=`/shipment/${notification.shipment_id}`: window.location=`/carrier_shipment/${notification.shipment_id}`} >
          <p className='blue_text'>{notification.notification_title}</p>
          <Row style={{justifyContent:'space-between'}}>
          <p>{notification.notification_content}</p>
          </Row>
           <p className='gray_text'>{timeAgo}</p>
        </div>
        <Divider/>
        </>
      )
}


