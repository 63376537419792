import { Button } from '@material-ui/core';
import React,{useEffect,useState} from 'react'
import { useParams } from 'react-router-dom';
import ShipmentFullCard from '../../../Components/Common/Shipments/ShipmentFullCard/ShipmentFullCard';
import useFetchData from '../../../Components/CustomerSide/Shipments/ShipmentSection/useFetchData';
import { notEmptyArray } from '../../../globals';
import NavBar from '../NavBar/NavBar';
import Row from '../../../Components/Common/Layout/Row';
import Loading from '../../../Components/Common/Loading';
import AssignCarrier from './AssignCarrier/AssignCarrier';
import Offers from './Offers/Offers';

export default function ShipmentInformation() {
    const {id}  = useParams();
    const [data,isLoading]= useFetchData(`api/shipments?_id=${id}`,['admin','shipmentinformation',id],'customer','data')
    const [shipment, setshipment] = useState(null)
    const [showAssignCarrierDrawer, setshowAssignCarrierDrawer] = useState(false)
    const [showOffers, setShowOffers] = useState(true)

    useEffect(() => {
        if(notEmptyArray(data)){
            //get the first shipment of get shipment by id 
            setshipment(data[0])
        }
    }, [data])

    return (
        <div>
            <NavBar/>
            {isLoading?<Loading/>:
            <div className='col-8'>
                <Row>
                    <Button variant='contained' color='primary' onClick={()=>setShowOffers(true)}>Offers</Button>
                    <Button variant='contained' color='secondary' onClick={()=>setshowAssignCarrierDrawer(true)}>Assign Carrier</Button>
                    <Button>Edit</Button>
                    <Button>Delete</Button>
                </Row>
                <ShipmentFullCard showStatusChange={false}  shipment={shipment||{}} hasTitle={true}  />
                <AssignCarrier 
                shipment_id={shipment?._id}
                assignedCarriers={Array.isArray(shipment?.carriers_selected_to_offer)?shipment?.carriers_selected_to_offer:[]} 
                isOpen={showAssignCarrierDrawer} 
                setOpen={setshowAssignCarrierDrawer}
                />
                <Offers
                isOpen={showOffers}
                setOpen={setShowOffers}
                shipment_id={shipment?._id}
                />
            </div>
            }
        </div>
    )
}
