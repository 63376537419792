import React, { Component } from "react";
import i18next from 'i18next'
import { ignore } from "joi-browser";
class InputField extends Component {

  render() {
    if(this.props.typename=='date'){
      console.log(this.props.defaultValue)
    }
    return (
      <div>
        <label
          className="gray_text">
          {i18next.t(this.props.labeltitle)}
        </label>
        <input
          placeholder={this.props.placeholder}
           defaultValue={this.props.typename=='date' ? new Date(this.props.defaultValue): this.props.defaultValue}
           type={this.props.typename}
           name={this.props.fieldname}
           className={`form-control ${this.props.guest_class_name}`}
           value={this.props.value}
           onChange={this.props.changeHandler}
          style={{borderColor:`${this.props.error?'rgba(214, 56, 56, 0.7)':''}`,backgroundColor:`${this.props.error?'rgba(255,0,0,0.1':''}`}}
        />
        {this.props.error && (
          <p className="red_text d-block" style={{ fontWeight:400,position:'relative',marign:0}}>
            {this.props.error}
          </p>
        )}
      </div>
    );
  }
}

export default InputField;
