import React, { Component } from "react";
import ModalBody from "react-bootstrap/ModalBody";
import { connect } from "react-redux";
import {
  AddDriverCarrierSide,
  EditDriver,
  DeleteDriver,
} from "../../../Redux/Actions/actioncreators";
import { getCarrierUserId, getCarrier } from "../../../LocalStorage/Auth";
import { api_callback, trans } from "../../../globals";
import * as yup from 'yup';
import InputField from "../../Common/InputField";
import Switch from 'react-switch';
import Images from "../../../Pics/Images";
import close from "../../../Pics/close.png";


const driverSchema = yup.object().shape({
 driver_name: yup.string().required().label('Name'),
 driver_email: yup.string().required().email().label('Email'),
 driver_mobilenum: yup.number().required().required().label('Mobile Number'),
 driver_password:yup.string().required().label('Password'),
});
const driverSchema_edit = yup.object().shape({
  driver_name: yup.string().required().label('Name'),
  driver_email: yup.string().required().email().label('Email'),
  driver_mobilenum: yup.number().required().positive().required().label('Mobile Number'),
 
 });

class AddDriverModal extends Component {
  state = {
    token: getCarrier(),
    data: { ...this.returnState() },
    loadingButton: false,
    addDriverError: false,
    
    errors:{}
  };
  returnState() {
    if (this.props.title === "EditOrDeleteDriver") {
      console.log("Driver",this.props.data)
      return this.props.data;
    } 
  }
 
  changeHandler = (e,isSwitch=false) => {
    const data = { ...this.state.data };
    data[e.currentTarget.name] = isSwitch ?e : e.currentTarget.value;
    this.setState({ data });
  };
  handleDataSwitch = (name) => {
    const data = { ...this.state.data };
    data[name] = data[name]!=undefined ? !data[name]:true;
    this.setState({ data });
  };
  //-------------------INPUT FIELD------------------------
  renderInput(labelTitle, fieldname, typefield) {
    const { data } = this.state;
    return (
      <React.Fragment>
        <label className="gray_text">{trans(labelTitle)}</label>
        <InputField
          typename={typefield}
          fieldname={fieldname}
          value={data[fieldname]}
          changeHandler={this.changeHandler}
          error={this.state.errors[fieldname]}
        />
      </React.Fragment>
    );
  }
  loadingButton = () => {
    this.setState({ loadingButton: true });
  };
  //------------------ADD DRIVER API-----------------
  addDriverForm = () => {
 
    this.loadingButton();
    const { data } = this.state;
    const  _id  = getCarrierUserId();
    const requestBody = {
      driver_name: data.driver_name,
      driver_mobilenum: data.driver_mobilenum,
      driver_email: data.driver_email,
      carrier_id: _id,
      driver_password: data.driver_password,
      canOffer: data.canOffer,
    };
    
    driverSchema.validate(requestBody,{abortEarly:false})
    .then(res=>{
      const config = {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
          "Content-Type": "application/json",
        },
      };
      this.props.addDriver(
        requestBody,
        config,
        this.AddDriversSucess,
        this.AddDriversFailure
      );
    })
    .catch((err)=> {
      
      let errors={}
      err.inner.map(err=>{
         errors[err.path] =err.message;
       });
       this.setState({errors,loadingButton:false});
      });

   
    }
  
  AddDriversSucess = () => {
    this.setState({ loadingButton: false });
    api_callback(true,'Επιτυχία','Ο οδηγός προστέθηκε με επιτυχία')
    this.props.handleModal();
    window.location.reload();
  };

  AddDriversFailure = () => {
    this.setState({ loadingButton: false, addDriverError: true });
  };
  //---------------EDIT DRIVER API----------------
  editDriver = () => {
    const { data } = this.state;
    const driverId = this.props.data._id;
   
    const requestBody = {
      driver_name: data.driver_name,
      driver_mobilenum: data.driver_mobilenum,
      driver_email: data.driver_email,
      canOffer: data.canOffer,
    };
   

    driverSchema_edit.validate(requestBody,{abortEarly:false})
    .then(res=>{
      const config = {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
          "Content-Type": "application/json",
        },
      };
      this.props.editDriverData(
        requestBody,
        config,
        driverId,
        this.editDeleteSuccess,
        this.editDeleteFail
      );
    })
    .catch((err)=> {
      let errors={}
      err.inner.map(err=>{
         errors[err.path] =err.message;
       });
       this.setState({errors,loadingButton:false});
      });

  };
  //---------------DELETE DRIVER API----------------
  deleteDriver = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
        "Content-Type": "application/json",
      },
    };
    this.props.deleteDriverData(
      config,
      this.props.data._id,
      this.editDeleteSuccess,
      this.editDeleteFail
    );
  };
  editDeleteSuccess = () => {
    //api_callback(true,'Επιτυχία','Success') 
    this.props.handleModal();
    window.location.reload();
  };
  editDeleteFail = () => {
    //api_callback(false,'Αποτυχία','Error Occured!')
  };
  render() {
    console.log(this.state.data)
    const { loadingButton, addDriverError } = this.state;
    return (
      <ModalBody>
        <div className="p-4">
          <div className="container">
          <button
          style={{
            position: "fixed",
            top: 10,
            right: 10,
            background: "transparent",
            border: 0,
          }}
          onClick={this.props.handleModal}
        >
          <img style={{height:'2em',width:'2em'}} src={close}></img>
        </button>
            <h4 className="black_text">{trans("drivers.addtitle")}</h4>
            {/*---------DRIVER EMAIL ERROR START-------- */}
            {addDriverError && (
              <div
                className="alert alert-danger float-right"
                style={{ fontSize: "14px" }}
              >
                Current Driver Email is already taken.Try another one
              </div>
            )}
            {/*---------DRIVER EMAIL ERROR END-------- */}
            <p className="gray_text mt-2">
            {trans('drivers.addsubtitle')} 
            </p>
            <div>
              <div className="form-row">
                <div className="form-group col-md-4">
                  {this.renderInput("common.labels.name", "driver_name", "text")}
                </div>
                <div className="form-group col-md-4">
                  {this.renderInput("common.labels.email", "driver_email", "email")}
                </div>
               
              </div>
              <div className="form-row">
                {this.props.title != "EditOrDeleteDriver" && 
                  <div className="form-group col-md-4">
                    {this.renderInput("common.labels.password", "driver_password", "password")}
                  </div>
                }

                <div className="form-group col-md-4">
                  {this.renderInput("common.labels.mobilenum", "driver_mobilenum", "number")}
                </div>
                  <div className="form-group col-md-4">
                    <label className="gray_text d-block" style={{marginBottom:'2em'}} >{trans('shipmentinfo.can_offer')}</label>
                 
                    <Switch
                      name="canOffer"
                      onChange={() =>this.handleDataSwitch('canOffer')}
                      checked={this.state.data.canOffer}
                    />
                  </div>
              </div>
              {this.props.title === "EditOrDeleteDriver" ? (
                <div className="row">
                 
                  <div className="col-md-4">
                    <button
                      className="btn btn-danger btn-block"
                      onClick={this.deleteDriver}
                    >
                     {trans('common.buttons.delete')} 
                    </button>
                  </div>
                  <div className="col-md-4">
                    <button
                      onClick={this.editDriver}
                      className="btn btn-success btn-block"
                    >
                       {trans('common.buttons.save')} 
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-4">
                    <button
                      onClick={this.addDriverForm}
                      disabled={loadingButton}
                      className="btn btn-primary btn-block"
                    >
                      {loadingButton ? (
                        <span>Loading ...</span>
                      ) : (
                        <span>{trans("common.buttons.add")}</span>
                      )}
                    </button>
                  </div>
                  <div className="col-md-4 offset-md-4">
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addDriver: (requestBody, config, AddDriversSucess, AddDriversFailure) =>
      dispatch(
        AddDriverCarrierSide(
          requestBody,
          config,
          AddDriversSucess,
          AddDriversFailure
        )
      ),
    editDriverData: (requestBody, config, driverId, editSuccess, editFail) =>
      dispatch(
        EditDriver(requestBody, config, driverId, editSuccess, editFail)
      ),
    deleteDriverData: (config, driverId, editSuccess, editFail) =>
      dispatch(DeleteDriver(config, driverId, editSuccess, editFail)),
  };
};
export default connect(null, mapDispatchToProps)(AddDriverModal);
