import { getCarrierUserId } from "../../LocalStorage/Auth";
import { httpRequest } from "../services_utils";

export const shipmentServices ={
    //Customers
    getCustomerShipments:
    (requestbody,loading)=> 
    dispatch=>httpRequest({http_type:'get',url:`api/shipments/customer/${requestbody.customerid}`,loading,action:'GET_CUSTOMER_SHIPMENTS',type:'customer'},dispatch),

    getCustomerShipmentById:
    (shipmentid,callback)=> 
    dispatch=>httpRequest({http_type:'get',url:`api/shipments/${shipmentid}`,loading:false,action:'GET_CUSTOMER_SHIPMENT_BYID',type:'customer',callback},dispatch),

    getShipmentOffers:
    (id,callback)=> 
    httpRequest({http_type:'get',url:`api/shipments/${id}/offers`,loading:true,type:'customer',callback},null),

    addShipment:
    (body,callback)=> 
    dispatch => httpRequest({http_type:'post',url:`api/shipments`,action:'ADD_NEW_SHIPMENT_CUSTOMER',loading:true,type:'customer',body,callback},dispatch),

    addShipperReceiver:
    (body,callback)=> 
    dispatch => httpRequest({http_type:'post',url:`api/shipments/add_shipper_receiver`,action:'ADD_SHIPPER_RECEIVER',loading:true,type:'customer',body,callback},dispatch),

    //Carriers
    getCarrierShipments:
    (carrier_id,loading)=> 
    dispatch=>httpRequest({http_type:'get',url:`api/carriers/${carrier_id}/newshipments`,loading,action:'GET_CARRIER_SHIPMENTS',type:'carrier'},dispatch),

    getCarrierShipmentById:
    (id,callback)=> 
    dispatch=>httpRequest({http_type:'get',url:`api/carriers/${getCarrierUserId()}/shipments/${id}`,loading:true,action:'GET_CARRIER_SHIPMENT_BY_ID',type:'carrier',callback},dispatch),

    addDriversTrucks:
    (body,shipment,callback)=> 
    dispatch=>httpRequest({http_type:'post',url:`api/shipments/${shipment.id}/addtruckdriver/`,action:'ADD_DRIVER_TRUCK',body,loading:false,type:'carrier',callback},dispatch),
    
    acceptOffer:
    (body,shipment_id,callback)=>
    httpRequest({http_type:'post',url:`api/shipments/${shipment_id}/acceptoffer`,body,loading:false,type:'customer',callback},null),

 
}
