import React,{Component,createRef} from 'react'
import { handleSockets, send_message } from './handleSockets';
import ReactLoading from 'react-loading'
import { handleReadMessages, make_messages_read, set_messages_read_async } from './api';
import {store} from '../../index'
import { count_unread_messages, set_messages_read } from '../../Redux/Actions/actions';
import { connect } from 'react-redux'
import { trans } from '../../globals';

const messagesEndRef = React.createRef()
  class  ChatArea extends Component {
   
constructor(props){
    super(props)
    this.state={
        message:'',
        unread_messages:0
    }
    this.myRef = createRef();
}
    message_view=(msg,sender)=>{
	if(msg.from == sender){
	return <p className='receiver'>{msg.message}</p>
	}else{
	return <p className='sender'>{msg.message}</p>
	}
	}
  componentDidMount() {
    this.scroll_to_bottom()
  }

  componentWillMount(){
   console.log("Will Mount Chat Area")
   this.props.set_messages_read(this.props.chat_room_id,this.props.sender)
   //this.props.set_messages_read(this.props.chat_room_id,this.props.sender) 
  }
  componentWillUnmount(){
   console.log("Will Unmoun Chat Area")
   this.props.set_messages_read(this.props.chat_room_id,this.props.sender) 
  }
  componentDidUpdate(){
   this.scroll_to_bottom()
  }
  
  
  scroll_to_bottom =()=>{
    const message_container= this.myRef.current
    message_container.scrollIntoView(false,{behavior:'smooth'})
    this.myRef.current.scrollBy(0,10000 )
  }
  handleSendMessage=(e)=>{
      e.preventDefault();
      const sender = this.props.sender;
      const receiver = this.props.sender=='carrier'? 'customer':'carrier';
    if(this.props.socket){
        this.setState({message:''})
        const msg={
            message:this.state.message,
            shipment_id:this.props.shipment_id,
            from:sender,
            to:receiver,
            chat_room_id:this.props.chat_room_id,
            isRead:false
        }
        this.props.socket.emit('send_message',msg)

        this.props.count_unreads(this.props.chat_room_id,this.props.sender) 
        }
    }
   render() {
	  const props = this.props;
	  console.log(props.messages)
    return (
        <div className='chat_container shadow'>
        <div className='chat_header'>
            <h4>Carrier Chat</h4>
        </div>
       
        <div className='chat_room_content'>
        <div id='chat'>
            <div  ref={this.myRef} className='message_area'>
            {props.messages? props.messages.map(m=>{
                return  this.message_view(m,props.sender)
            }):  <ReactLoading type='spin' color='#2E5BFF' height='3em' width='3em'/>}
            </div>
            <hr></hr>
            <form className='input_area' onSubmit={this.handleSendMessage}>
                <input value={this.state.message} onChange={(e)=>this.setState({message:e.target.value})} placeholder='Type a Message...'/>
                <button type='submit' >{trans("common.buttons.send")}</button>
                </form>
        </div>									
        </div>
       
        </div>
    )
}
}

const mapStateToProps = (state) => {
    return {
        customer_shipments: state.customerShipmentReducer.CustomerShipments,
        chat_rooms:state.chat_reducer.chat_rooms,
        loading:state.chat_reducer.loading,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
        set_messages_read:(id,sender)=>dispatch(set_messages_read_async(id,sender)),
        count_unreads :(id,sender)=>dispatch(count_unread_messages(id,sender))
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(ChatArea);

