import React from 'react';
import i18next from 'i18next'
function StateCard({state}) {

   let mystate=String(state).toLowerCase();
   mystate = mystate.charAt(0).toUpperCase() + mystate.slice(1);
  
   switch (state) {
      case 'WAITING':
         return <p style={{backgroundColor:'rgba(247, 193, 55, 0.212)',width:'9em',textAlign:'center',maxHeight:20,color:'#F7C137',borderRadius:20,paddingLeft:10,paddingRight:10}}>{i18next.t('common.status.waiting')}</p>;
         break;
      case 'UPCOMING':
         return <p style={{backgroundColor:'rgba(141, 84, 255, 0.247)',width:'9em',maxHeight:20,textAlign:'center',color:'#8C54FF',borderRadius:20,paddingLeft:10,paddingRight:10}}>{i18next.t('common.status.upcoming')}</p>;

      break;
      case 'INPROGRESS':
         return <p style={{backgroundColor:'rgba(0, 194, 212, 0.219)',width:'9em',textAlign:'center',maxHeight:20,color:'#00C1D4',borderRadius:20,paddingLeft:10,paddingRight:10}}>{i18next.t('common.status.inprogress')}</p>;;
      break;
      case 'COMPLETED':
         return <p style={{backgroundColor:'rgba(50, 172, 46, 0.212)',width:'9em',textAlign:'center',maxHeight:20,color:'#33AC2E',borderRadius:20,paddingLeft:10,paddingRight:10}}>{i18next.t('common.status.completed')}</p>;
        break;   
      default:
         return null;
         break;
   }
 
}
 
export default StateCard;