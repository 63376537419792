import { createAction } from '@reduxjs/toolkit'

//---------------------LOGIN CUSTOMER----------------------
export const LoginAction = (data) => {
  return {
    type: "LOGIN_CUSTOMER",
    data,
  };
};
//----------------------------LOGIN CARRIER--------------------------
export const LoginActionCarrier = (data) => {
  return {
    type: "LOGIN_CARRIER",
    data,
  };
};
//----------------------------UPDATE  CUSTOMER--------------------------
export const updateCustomerAction = (data) => {
  
  return {
    type: "UPDATE_CUSTOMER",
    data,
  };
  
};
//----------------------------UPDATE  CUSTOMER--------------------------
export const update_carrier_action = (data) => {
  
  return {
    type: "UPDATE_CARRIER",
    data,
  };
  
};
//----------------------------GET CUSTOMER BY ID--------------------------
export const get_customer_by_id_action = (data) => {
  
  return {
    type: "GET_CUSTOMER_BY_ID",
    data,
  };
};
//----------------------------GET CARRIER BY ID--------------------------
export const get_carrier_by_id_action = (data) => {
  
  return {
    type: "GET_CARRIER_BY_ID",
    data,
  };
};
//----------------------------CHAT  CUSTOMER WITH ADMIN --------------------------
export const chat_customer_admin_action = (messages,shipment_id) => {
  return {
    type: "CHAT_CUSTOMER_WITH_ADMIN",
    payload:messages,
      shipment_id
  };
};
//----------------------------ADD SHIPPER RECEIVER TO SHIPMENT--------------------------
export const add_shipper_receiver_action = (shipment) => {
  return {
    type: "ADD_SHIPPER_RECEIVER",
    shipment
  };
};
//----------------------------SIGNUP CUSTOMER------------------------

export let signup_customer_action={
  start:()=>({type:'SIGNUP_CUSTOMER_START'}),
  fail:(error_message='Error Occured')=>({type:'SIGNUP_CUSTOMER_FAIL',error_message}),
  success:(data)=>({type:'SIGNUP_CUSTOMER_SUCCESS',data}),
}

//----------------------------SIGNUP CARRIER------------------------

export let signup_carrier_action={
  start:()=>({type:'SIGNUP_CARRIER_START'}),
  fail:(error_message='Error Occured')=>({type:'SIGNUP_CARRIER_FAIL',error_message}),
  success:(data)=>({type:'SIGNUP_CARRIER_SUCCESS',data}),
}

//----------------------------ADD SHIPMENTS CUSTOMER------------------
export const AddShipmentActionCustomer = (data) => {
  return {
    type: "ADD_NEW_SHIPMENT_CUSTOMER",
    data,
  };
};
//----------------------------GET SHIPMENTS----------------------------
export const GetShipmentActionCustomer = (data) => {
  return {
    type: "GET_SHIPMENT_CUSTOMER",
    data,
  };
};
// //-------------GET SHIPMENT BY ID
// export const GetSingleShipmentActionCustomer = (data) => {
//   return {
//     type: "ADD_SINGLESHIPMENT",
//     data,
//   };
// };
//----------------------------GET DRIVERS---------------------------------
export const get_vehiclesAction = (data) => {
  return {
    type: "GET_VEHICALS",
    data,
  };
};
//---------------------------ADD DRIVER-----------------------------------
export const AddDriverAction = (data) => {
  return {
    type: "ADD_DRIVER",
    data,
  };
};
//----------------------------GET VEHICALS-------------------------------
export const GetDriversAction = (data) => {
  return {
    type: "GET_DRIVERS",
    data,
  };
};
//-----------------------------ADD VEHICAL-------------------------------------
export const AddVehicalAction = (data) => {
  return {
    type: "ADD_VEHICAL",
    data,
  };
};
//--------------------------------GET CARRIER SHIPMENTS--------------------------

export const GetCarrierShipmentsAction = (data) => {
  return {
    type: "GET_CARRIER_SHIPMENTS",
    data,
  };
};


//-----------------------------------ACCEPT CUSTOMER OFFER(SIPMPLE OR SPLIT)--------------
export const AcceptCustomerOfferAction = (data) => {
  return {
    type: "ACCEPT_CUSTOMER_OFFER",
    data,
  };
};

//----------------------------------CARRIER OFFER SEND------------------------
export const CarrierOfferSendAction = (data) => {
  return {
    type: "CARRIER_OFFER_SEND",
    data,
  };
};
//--------------------------ADD DRIVER TRUCK TO SHIPMENT(CARRIER)-----------------
export const AddDriverTruckToShipmentAction = (data) => {
  return {
    type: "ADD_DRIVER_TRUCK",
    data,
  };
};

//--------------------------CARRIER NOTIFICATION-----------------
export const CarrierNotificationAction = (data) => {
  return {
    type: "CARRIER_NOTIFICATION",
    data,
  };
};
//--------------------------EDIT DRIVER-----------------
export const EditDriverAction = (data, id) => {
  return {
    type: "EDIT_DRIVER",
    data,
    id,
  };
};
//--------------------------EDIT VEHICAL-----------------
export const edit_vehicleAction = (data, id) => {
  return {
    type: "EDIT_VEHICAL",
    data,
    id,
  };
};
//--------------------------DELETE DRIVER-----------------
export const DeleteDriverAction = (data) => {
  return {
    type: "DELETE_DRIVER",
    data,
  };
};
//--------------------------DELETE VEHICAL-----------------
export const delete_vehicleAction = (data) => {
  return {
    type: "DELETE_VEHICAL",
    data,
  };
};
 //GET CARRIER NOTIFICATIONS
export const GET_CARRIER_NOTIFICATIONS_FAIL= 'GET_CARRIER_NOTIFICATIONS_FAIL'
export const GET_CARRIER_NOTIFICATIONS_SUCCESS= 'GET_CARRIER_NOTIFICATIONS_SUCCESS'
export const GET_CARRIER_NOTIFICATIONS_START= 'GET_CARRIER_NOTIFICATIONS_START'

export const get_carrier_notifications_success =(payload)=>({type: GET_CARRIER_NOTIFICATIONS_SUCCESS,payload})
export const get_carrier_notifications_start=()=>({type:GET_CARRIER_NOTIFICATIONS_START})
export const get_carrier_notifications_fail=(error_message='Error Occured')=>({type:GET_CARRIER_NOTIFICATIONS_FAIL,error_message})

//ADD GUEST SHIPMENT
export const ADD_GUEST_SHIPMENT_FAIL= 'ADD_GUEST_SHIPMENT_FAIL'
export const ADD_GUEST_SHIPMENT_SUCCESS= 'ADD_GUEST_SHIPMENT_SUCCESS'
export const ADD_GUEST_SHIPMENT_START= 'ADD_GUEST_SHIPMENT_START'

export const add_guest_shipment_success =(payload)=>({type: ADD_GUEST_SHIPMENT_SUCCESS,payload})
export const add_guest_shipment_start=()=>({type:ADD_GUEST_SHIPMENT_START})
export const add_guest_shipment_fail=(error_message='Error Occured')=>({type:ADD_GUEST_SHIPMENT_FAIL,error_message})

//FILTER SHIPMENTS
export const FILTER_CUSTOMER_SHIPMENTS= 'FILTER_CUSTOMER_SHIPMENTS'
export const FILTER_CARRIER_SHIPMENTS= 'FILTER_CARRIER_SHIPMENTS'
export const RESET_FILTER_APPLIED= 'RESET_FILTER_APPLIED'

export const filter_customer_shipments=(filtered_customer_shipments)=>({type:FILTER_CUSTOMER_SHIPMENTS,filtered_customer_shipments})
export const filter_carrier_shipments=(filtered_carrier_shipments)=>({type:FILTER_CARRIER_SHIPMENTS,filtered_carrier_shipments})
export const reset_filter_applied_action=()=>({type:RESET_FILTER_APPLIED})


// ADD CARRIER PAYMENT METHOD
export const ADD_CARRIER_PAYMENT_METHOD_FAIL= 'ADD_CARRIER_PAYMENT_METHOD_FAIL'
export const ADD_CARRIER_PAYMENT_METHOD_SUCCESS= 'ADD_CARRIER_PAYMENT_METHOD_SUCCESS'
export const ADD_CARRIER_PAYMENT_METHOD_START= 'ADD_CARRIER_PAYMENT_METHOD_START'

export const add_carrier_payment_method_success =(payload)=>({type: ADD_CARRIER_PAYMENT_METHOD_SUCCESS,payload})
export const add_carrier_payment_method_start=()=>({type:ADD_CARRIER_PAYMENT_METHOD_START})
export const add_carrier_payment_method_fail=(error_message='Error Occured')=>({type:ADD_CARRIER_PAYMENT_METHOD_FAIL,error_message})

export const GET_CHATROOM_BY_ID_FAIL= 'GET_CHATROOM_BY_ID_FAIL'
export const GET_CHATROOM_BY_ID_SUCCESS= 'GET_CHATROOM_BY_ID_SUCCESS'
export const GET_CHATROOM_BY_ID_START= 'GET_CHATROOM_BY_ID_START'

export const get_chatroom_by_id_success =(payload,chat_room_id)=>({type: GET_CHATROOM_BY_ID_SUCCESS,payload,chat_room_id})
export const get_chatroom_by_id_start=()=>({type:GET_CHATROOM_BY_ID_START})
export const get_chatroom_by_id_fail=(error_message='Error Occured')=>({type:GET_CHATROOM_BY_ID_FAIL,error_message})

export const UPDATE_CHAT = 'UPDATE_CHAT'
export const update_chat =(payload)=>({type:UPDATE_CHAT,payload})

export const SET_MESSAGES_READ='SET_MESSAGES_READ'
export const set_messages_read=(id,chat_room)=>({type:SET_MESSAGES_READ,id,chat_room})

export const COUNT_UNREAD_MESSAGES='COUNT_UNREAD_MESSAGES'
export const count_unread_messages=(id,mytype)=>({type:COUNT_UNREAD_MESSAGES,id,mytype})

export const GET_CUSTOMER_SHIPMENTS_FAIL= 'GET_CUSTOMER_SHIPMENTS_FAIL'
export const GET_CUSTOMER_SHIPMENTS_SUCCESS= 'GET_CUSTOMER_SHIPMENTS_SUCCESS'
export const GET_CUSTOMER_SHIPMENTS_START= 'GET_CUSTOMER_SHIPMENTS_START'

export const get_customer_shipments_success =(payload)=>({type: GET_CUSTOMER_SHIPMENTS_SUCCESS,payload})
export const get_customer_shipments_start=()=>({type:GET_CUSTOMER_SHIPMENTS_START})
export const get_customer_shipments_fail=(error_message='Error Occured')=>({type:GET_CUSTOMER_SHIPMENTS_FAIL,error_message})

//GET CUSTOMER SHIPMENT BY ID
export const GET_CUSTOMER_SHIPMENT_BYID_FAIL= 'GET_CUSTOMER_SHIPMENT_BYID_FAIL'
export const GET_CUSTOMER_SHIPMENT_BYID_SUCCESS= 'GET_CUSTOMER_SHIPMENT_BYID_SUCCESS'
export const GET_CUSTOMER_SHIPMENT_BYID_START= 'GET_CUSTOMER_SHIPMENT_BYID_START'

export const get_customer_shipment_byid_success =(payload)=>({type: GET_CUSTOMER_SHIPMENT_BYID_SUCCESS,payload})
export const get_customer_shipment_byid_start=()=>({type:GET_CUSTOMER_SHIPMENT_BYID_START})
export const get_customer_shipment_byid_fail=(error_message='Error Occured')=>({type:GET_CUSTOMER_SHIPMENT_BYID_FAIL,error_message})

//GET CARRIER SHIPMENTS
export const GET_CARRIER_SHIPMENTS_FAIL= 'GET_CARRIER_SHIPMENTS_FAIL'
export const GET_CARRIER_SHIPMENTS_SUCCESS= 'GET_CARRIER_SHIPMENTS_SUCCESS'
export const GET_CARRIER_SHIPMENTS_START= 'GET_CARRIER_SHIPMENTS_START'

export const get_carrier_shipments_success =(payload)=>({type: GET_CARRIER_SHIPMENTS_SUCCESS,payload})
export const get_carrier_shipments_start=()=>({type:GET_CARRIER_SHIPMENTS_START})
export const get_carrier_shipments_fail=(error_message)=>({type:GET_CARRIER_SHIPMENTS_FAIL,error_message})

//GET CARRIER SHIPMENT BY ID
export const GET_CARRIER_SHIPMENT_BY_ID_FAIL= 'GET_CARRIER_SHIPMENT_BY_ID_FAIL'
export const GET_CARRIER_SHIPMENT_BY_ID_SUCCESS= 'GET_CARRIER_SHIPMENT_BY_ID_SUCCESS'
export const GET_CARRIER_SHIPMENT_BY_ID_START= 'GET_CARRIER_SHIPMENT_BY_ID_START'

export const get_carrier_shipment_by_id_success =(payload)=>({type: GET_CARRIER_SHIPMENT_BY_ID_SUCCESS,payload})
export const get_carrier_shipment_by_id_start=()=>({type:GET_CARRIER_SHIPMENT_BY_ID_START})
export const get_carrier_shipment_by_id_fail=(error_message)=>({type:GET_CARRIER_SHIPMENT_BY_ID_FAIL,error_message})




export const createActions= (namespace) =>{

  const start= `${namespace}_START`;
  const success= `${namespace}_SUCCESS`;
  const fail= `${namespace}_FAIL`

  const action_start= createAction(start);
  const action_success= createAction(success);
  const action_fail= createAction(fail);

  return {
   start:action_start,
   success:action_success,
   fail:action_fail
  };
}