import React from 'react'
import { trans } from '../../../../globals'
import Column from '../../../Common/Layout/Column'

export default function Price({price}) {
    
    return (
        <Column>
         <h2 className="blue_text" style={{fontWeight:600}}>{price} €</h2>
         <p className="gray_text">{trans('novat')}</p>
        </Column>
    )
}   
