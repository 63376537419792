import React from 'react'
import { trans, notEmptyArray } from '../../../globals';
import MyInput from './MyInput';
import MySelect from './MySelect';
import OptionField from '../../CustomerSide/OptionField';
import MySwitch from './MySwitch';

export default function GridForm({structure,handleChange,formik,index,handleFieldValue}) {
  return (
    <>
    {structure.map(s=>{
       if(s.type=='text' && s.name && s.datatype){
           return  <div className={`${s.gridClass}`}>
           <MyInput
           name={inputNameFromIndex(index,s.name)}
           label ={trans(s.label)}
           value={saExists(formik,'values',index)&&formik.values.data[index].shipment_attributes[0]?.[s.name]}
           error={saExists(formik,'errors',index)&&formik.errors.data[index].shipment_attributes[0]?.[s.name]}
           defaultValue={saExists(formik,'initialValues',index) && formik.initialValues.data[index].shipment_attributes[0][s.name]}
           onChange={handleChange} 
           type={s.datatype}
           />
           </div>
       }else if(s.type=='select'){
           return  <div className={`${s.gridClass}`}>
                    <MySelect
                      name={inputNameFromIndex(index,s.name)}
                      label ={trans(s.label)}
                      options={s.options}
                      defaultValue={s.defaultValue}
                      onChange={handleFieldValue}
                     />
                    </div>
       }else if(s.type=='optionfield'){
           return  <div className={`${s.gridClass}`}>
               <OptionField
                name={inputNameFromIndex(index,s.name)}
                changeHandler={handleChange}
                labelTitle ={trans(s.label)}
                Option={s.options}
                defaultValue={s.defaultValue}
                withLabel={true}
                />
                </div>
       }else if(s.type=='switch'){
           return <div className={`${s.gridClass}`}>
             <MySwitch 
               name={inputNameFromIndex(index,s.name)}
               label ={trans(s.label)}
               onChange={formik.handleChange}
               defaultChecked={s.defaultValue} />
             </div>
       }

   })}
   </>
  )
}

const inputNameFromIndex = (index,name)=> `data[${index}].shipment_attributes[0].${name}`



const saExists = (formik,value='values',index)=>{
    if(notEmptyArray(formik[value].data,index)){
        if(notEmptyArray(formik[value].data[index]?.shipment_attributes)){
            return true
        }else{
            return false
        }
    }else{
            return false
        }
    }

