import React,{useEffect,useState} from 'react'
import {useFormik} from 'formik'
import { kibvtia_types_with_labels, trans, palets_types_with_labels, notEmptyObject, notEmptyArray, packaging_options } from '../../../../globals';
import * as yup from 'yup';
import { getCustomerUserId } from '../../../../LocalStorage/Auth';
import Location from './Location';
import Time from './Time';
import ProductsSection from './ProductsSection';
import { Button } from '@material-ui/core';
import Add from '@mui/icons-material/Add';
import Typography from '@material-ui/core/Typography';
import  Divider  from '@material-ui/core/Divider';
import { Row } from '@dimararist/woocargo.layout.row';

function SNDayDeliveryForm ({repeat,shipment,mutation,calculatePrice,price}) {

    const [products, setproducts] = useState([{id:0}])
    const formik = useFormik({
        enableReinitialize:true,
        validationOnChange:false,
        validateOnBlur:false,
        validationSchema:schema,
        initialValues:defaultShipment,
        
        onSubmit: (body) =>{            
            let mybody = {...body}
            console.log(mybody)
            if(mybody.type ==='same-day-courier'){
                mybody.pickup.date = new Date()
                mybody.dropoff.date = new Date()
            }else if(mybody.type ==='next-day-courier'){
                mybody.pickup.date = new Date()
                mybody.dropoff.date = new Date(new Date())
            }
            mutation.mutate(mybody)
        }
    })
    
    useEffect(() => {

        if(formik.values?.pickup?.location?.postal_code!= undefined && formik.values?.dropoff?.location?.postal_code!= undefined ){
            calculatePrice(formik.values?.pickup?.location?.postal_code,formik.values?.dropoff?.location?.postal_code)
        }
        
    }, [formik.values])
   

    const handleFieldValue =(name,value)=>{
        formik.setFieldValue(name,value)
        
    }
    const handleChange =(e)=> formik.setFieldValue(e.target.name,e.target.value,true) 
    
    const onAddBtnClick = event => {
        setproducts(products.concat({id:products.length}));
      };
    

     const deleteProduct = (index)=>{
        const temp =  [...products];
        temp.splice(index,1)
        setproducts(temp)
        const sa = [...formik.values.shipment_attributes]
        sa.splice(index,1)
        formik.setValues({...formik.values,shipment_attributes:sa})
    }
    


    return (
         <>

          <Location
          formik={formik}
          handleFieldValue={handleFieldValue}
          />
          <div className='bg-white'>
          <Time
          setFieldValue={formik.setFieldValue}
          
          />
          </div>

          <div className='bg-white p-4 mt-3 shadow-sm'>
            <Typography variant='h6' style={{fontWeight:500}} >Προιόντα</Typography>
            <Divider/>
            {products.map((t,index)=><ProductsSection
            id={t.id}
            key={t.id}
            handleChange={handleChange}
            handleFieldValue={handleFieldValue}
            formik={formik} 
            index={index}
            deleteProduct={deleteProduct}
            />)}

            <div className='col-12 d-flex justify-content-end'>
            <Button onClick={onAddBtnClick} color='success' variant="outlined" startIcon={<Add/>}>
            Προσθηκη Προιοντος
            </Button>
            </div>
            <div  className='center-absolute d-flex flex-row justify-content-between'>
                <Typography variant='h5' color='primary'>Price: {price>0?price:"-"}€</Typography>
                <Button onClick={()=>formik.submitForm()} color='primary' variant="contained" >
                   Καταχωρηση
                </Button>

            </div>
          </div>
          

          </>
    )
}

export default SNDayDeliveryForm


const schema = yup.object().shape({
   
    pickup: yup.object().shape({
            location:yup.object({
            address:yup.string().required().label("Address"),
            coordinates:yup.array().min(2).max(2).required().label("Address"),
        })
    }),
    dropoff: yup.object().shape({
        location:yup.object({
            address:yup.string().required().label("Address"),
            coordinates:yup.array().min(2).max(2).required().label("Address"),
        })
    }),
    shipment_attributes: yup.array().of(
        yup.object().shape({
            weight: yup.number().required().label('Weight'),
            quantity: yup.number().required().label('Quantity'),
            height: yup.number().required().label('Height'),
            width: yup.number().required().label('Width'),
            length: yup.number().required().label('Length'),
            description: yup.string().required().label('Description'),
          })
    ),
  });




const defaultShipment={
    customer_id:getCustomerUserId()?.id,
    type:'same-day-courier',
    shipment_attributes:[{
        length:120,
        width:80,
        height:100,
        quantity:1,
        weight:300,
    }],
}

const attributesToKeep=['shipment_attributes','pickup_time','pickup_date','pickup_location','dropoff_time','dropoff_location','dropoff_date','shipment_description','customer_id','shipment_notes']

const keep =(obj)=>{
    Object.keys(obj).map(key =>{
        if(!attributesToKeep.includes(key)){
            delete obj[key]
        }
    })
    return obj;
}