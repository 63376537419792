import React, { Component } from "react";
import NavBarCarrier from "./NavbarCarrier";
import AddDriverModal from "../CarrierSide/Drivers/AddDriverModal";
import AddVehicalModal from "../CarrierSide/Vehicle/AddVehicalModal";
import Modal from "react-bootstrap/Modal";
import "../../Styles/CustomerHome.css";
import { trans } from "../../globals";

export default class EmptyCarrier extends Component {
  state = {
    modalDriver: false,
    modalVehical: false,
  };
  handleModalDriver = () => {
    this.setState({ modalDriver: !this.state.modalDriver });
  };
  handleModalVehical = () => {
    this.setState({ modalVehical: !this.state.modalVehical });
  };
  render() {
    const { title,isDriver } = this.props;
    return (
      <React.Fragment>
        <NavBarCarrier />
        <div className="min_Height">
          <div className="container pt-5">
            <div className="row">
              <div className="col-md-10">
                <span className="vd-title">{this.props.title}</span>
                <span className="vd-total ml-3">0 {trans('common.buttons.total')}</span>
              </div>
            </div>
          </div>
          {isDriver==true && (
            <button
              className="btn btn-primary positionCenter"
              onClick={this.handleModalDriver}
            >
            {trans('drivers.adddriver')}
            </button>
          )}
          {isDriver==false && (
            <button
              className="btn btn-primary positionCenter"
              onClick={this.handleModalVehical}
            >
              {trans('vehicles.addvehicle')}
            </button>
          )}
          {/*--------DRIVER MODAL------ */}
          <Modal
            size="lg"
            show={this.state.modalDriver}
            onHide={this.handleModalDriver}
            backdrop="static"
            animation="true"
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <AddDriverModal
              handleModal={this.handleModalDriver}
              title="AddDriver"
            />
          </Modal>
          {/*---------VEHICAL MODAL-------- */}
          <Modal
            size="lg"
            show={this.state.modalVehical}
            onHide={this.handleModalVehical}
            backdrop="static"
            animation="true"
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <AddVehicalModal
              handleModal={this.handleModalVehical}
              title="AddVehical"
            />
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}
