import React from 'react'
import ShipmentPhotos from '../../../CustomerSide/ShipmentInformation/InfoRepresantation/ShipmentPhotos';
import ShipmentAttributes from '../../../CustomerSide/ShipmentInformation/InfoRepresantation/ShipmentAttributes';
import ShowCustomer from '../../../CarrierSide/ShipmentInformation/ShowCustomer';
import { notEmptyArray, trans } from '../../../../globals';
import Row from '../../Layout/Row';
import Price from '../../../CustomerSide/ShipmentInformation/InfoRepresantation/Price';
import Column from '../../Layout/Column';
import ChangeShipmentState from '../../../CarrierSide/ShipmentInformation/ChangeShipmentState/ChangeShipmentState';
import ShipperReceiverCard from './ShipperReceiverCard';
import StateCard from '../../StateCard';
import LocationTime from '../LocationTime/LocationTime';
import StopsDetails from '../../../CustomerSide/ShipmentInformation/InfoRepresantation/StopsDetails';
import ShipmentDetails from '../../../CustomerSide/ShipmentInformation/InfoRepresantation/ShipmentDetails';
import { useEffect } from 'react';

export default function ShipmentFullCard({
  shipment,
  showStatusChange=true,
  shadowClass='shadow-sm',
  showPrice=true,
  hasTitle=true,
  isContainer=true,
  marginBottomClass='mb-4'
  

}) {

    const {_id,status,shipment_attributes,shipper,receiver,price,pickup,dropoff,data} = shipment

    useEffect(()=>{
      console.log("Customer",shipment.customer_id)
    },[shipment])
    
    return (
        <div className={`container ${marginBottomClass}`} style={{padding:0}}>
        {(hasTitle || showStatusChange) && 
          <Row style={{justifyContent:'space-between',marginTop:'1.5em',marginBottom:'.5em'}}>
            {hasTitle && <h2>{trans('shipmentinfo.title')}</h2>}
            {showStatusChange && <ChangeShipmentState stops={shipment.data.length} shipment_id={_id} />}
          </Row>
        }
        <div
          className={`${isContainer?"container":""} ${shadowClass}`}
          style={{ background: "#ffffff" }}
        >
        
          <div className="pt-4">
            <Row style={{justifyContent:'space-between'}}>
            <StateCard   state={status}/>
            {showPrice &&<Price price={price}/> }
            </Row>

                      <ShipmentDetails shipment={shipment}/>
                 
                  {shipment?.customer_id && <ShowCustomer customer_id={shipment.customer_id}/>}
                
              </div>
            </div>
            {/* form */}
           
         
      </div>
    )
}
