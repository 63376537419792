import React from 'react'
import { trans } from '../../../globals'

export default function TitleRow() {
    return (
        <div className="container mt-5">
                      <div className="row">
                        <div className="col-3">
                          <span className="numbercolor">{trans('vehicles.titlerow.nameid')}</span>
                        </div>
                        <div className="col-2">
                          <span className="numbercolor">{trans('vehicles.titlerow.model')}</span>
                        </div>
                        <div className="col-1">
                          <span className="numbercolor">{trans('vehicles.titlerow.type')}</span>
                        </div>
                        <div className="col-2">
                          <span className="numbercolor">{trans('vehicles.titlerow.capacity')}</span>
                        </div>
                        <div className="col-2">
                          <span className="numbercolor">{trans('vehicles.titlerow.weight')}</span>
                        </div>
                      </div>
                    </div>
    )
}
