import React, { useState,useEffect } from 'react'
import './style.scss'
import * as yup from 'yup'
import background from "../../../Pics/guyintruck.jpg";
import { calculatePrice } from '../NewShipment/helpers';
import { useQuery, useMutation } from 'react-query';
import { api_callback, getDataCustomer, postDataCustomer, trans, palets_types_with_labels, packaging_options, getNextWorkDay } from '../../../globals';
import NewShipmentForm from '../NewShipment/NewShipmentForm/NewShipmentForm';
import { Typography } from '@material-ui/core';
import { createLocalStorageCustomer, getCustomerUserId } from '../../../LocalStorage/Auth';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';


function GuestShipment() {
  const params = useParams()
  const history = useHistory()
  const [zones, setZones] = useState(null)
  const [autoPrice, setautoPrice] = useState(null)
  const [showSuccessModal, setshowSuccessModal] = useState(false)
  const [showSuccessModalAutoPrice, setshowSuccessModalAutoPrice] = useState(false)

  const [defaultShipment,setdefaultShipment]= useState({
    customer_id:getCustomerUserId()?.id,
    data:[{
      pickup:{
          date: getNextWorkDay(new Date()),
          time:'09:00 - 12:00',
          flexible:false,
          location:{}
      },
      dropoff:{
          date:getNextWorkDay(new Date()),
          time:'09:00 - 12:00',
          flexible:false,
          location:{}
      }, 
      shipment_attributes:[{
        length:120,
        width:80,
        height:100,
        quantity:1,
        weight:100,
        hydraulic_door:false,
        packing:packaging_options[0].value,
        dimensions:palets_types_with_labels[0].value
    }],
    dropoff_flexible:false,
    pickup_flexible:false,
    }],
})

// Manages Params
  useEffect(() => {
     
     const tempDefaultShipment = {...defaultShipment}
     tempDefaultShipment.data[0].shipment_attributes[0].description = params.description;

     tempDefaultShipment.data[0].pickup.location.coordinates = [params.pickup_coord_0,params.pickup_coord_1]
     tempDefaultShipment.data[0].pickup.location.address= params.pickup_location

     tempDefaultShipment.data[0].dropoff.location.address= params.dropoff_location
     tempDefaultShipment.data[0].dropoff.location.coordinates = [params.dropoff_coord_0,params.dropoff_coord_1]

     //document.getElementById('pickup.location').value= params.pickup_location|| ""
     //document.getElementById('dropoff.location').value= params.dropoff_location|| ""
     console.log(tempDefaultShipment)
     setdefaultShipment(tempDefaultShipment)
    
  },[])

  

  //Add Shipment Mutation
  const addShipment = useMutation((body)=> postDataCustomer(body,`api/customers/guest_shipment_add`),{
    onSuccess:(data)=>{
      createLocalStorageCustomer(data.data.authToken,data.data.customer);
      history.push("/Shipments");
      api_callback(true,"Success","Success")
     
    },
    onError:err=>{
      if(err.response.data){
        api_callback(false,"Fail",err.response.data.error_message||"Αποτυχία")
      }
    }
})

  return (
    <div className="fullscreenc" style={{overflow:'scroll',height:'100vh'}}>  
      <div className="row h-100">
        <div className="col-lg-8 col-md-12" style={{padding:'3em',overflow:'scroll'}}>
        <Typography style={{marginBottom:'.5em'}} variant='h4'>{trans('newshipment.title')}</Typography>
        {Object.keys(defaultShipment?.data[0].dropoff?.location||{}).length>0&&
        <NewShipmentForm 
          calculatePrice={calculatePrice}
          mutation={addShipment} 
          shipment={{}} 
          repeat={false}
          showSuccessModal={showSuccessModal}
          setshowSuccessModal={setshowSuccessModal}
          showSuccessModalAutoPrice={showSuccessModalAutoPrice}
          setshowSuccessModalAutoPrice={setshowSuccessModalAutoPrice}
          zones={zones}
          autoPrice={autoPrice}
          schema={schema}
          userRegistration={true}
          defaultShipment={defaultShipment}
          />
        }
        </div> 

      <div className='col-lg-4 d-lg-block d-md-none d-sm-none' style={{ overflow:'hidden', backgroundImage: `url(${background})`,backgroundClip:'cover',backgroundSize:'cover',backgroundPositionX:'-2em',width:'40vw' }}></div>
    </div>
   </div>
    
  )
}

export default GuestShipment





  const schema = yup.object().shape({
    customer: yup.object().shape({
      customer_name: yup.string().required().label('Name'),
      customer_email: yup.string().required('Email is required').email().label('Email'),
      customer_password: yup.string().required().label('Password'),
    }),
    data:yup.array().of(
      yup.object().shape({
        pickup: yup.object().shape({
          location:yup.object({
          address:yup.string().required().label("Address"),
          coordinates:yup.array().min(2).max(2).required().label("Address"),
          postal_code:yup.string().min(4).label("Address"),
      })
    }),
    dropoff: yup.object().shape({
        location:yup.object({
            address:yup.string().required().label("Address"),
            coordinates:yup.array().min(2).max(2).required().label("Address"),
        })
    }),
    shipment_attributes: yup.array().of(
        yup.object().shape({
            weight: yup.number().min(1).max(3000).required().label('Weight'),
            quantity: yup.number().min(1).max(100).required().label('Quantity'),
            height: yup.number().min(1).required().label('Height'),
            width: yup.number().min(1).required().label('Width'),
            length: yup.number().min(1).required().label('Length'),
            description: yup.string().required().label('Description'),
          })
  ),
        
      })),
   
  }) 