import React from 'react'
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import Row from './Layout/Row'
export default function Telephone() {
    return (
        <Row 
        style={{
            backgroundColor:'#2E5BFF',
            position:'fixed',
            bottom:'.5em',
            left:'.5em',
            paddingTop:'.8em',
            paddingBottom:'.8em',
            paddingLeft:'1.5em',
            paddingRight:'1.5em',
            borderRadius:30,
            justifyContent:'center',
            alignItems:'center',
            

        }}
        >
     <PhoneRoundedIcon fontSize='small' style={{color:'#fff',marginRight:'.3em'}}/>
     <span style={{marginTop:0,textAlign:'center,'}} className='white_text'>210 2205412</span>
    </Row>
    )
}
