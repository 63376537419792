import React,{useState} from 'react'
import NewShipmentForm from '../../../Components/CustomerSide/NewShipment/NewShipmentForm/NewShipmentForm';
import { calculatePrice } from '../../../Components/CustomerSide/NewShipment/helpers';
import { getDataAdmin, palets_types_with_labels, packaging_options } from '../../../globals';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import NavBar from '../../Components/NavBar/NavBar';
import BoxContainer from '../../../Components/CustomerSide/ShipmentInformation/BoxContainer';

export default function NewShipment() {

    const queryClient = useQueryClient()
    let { repeat ,shipment_id } = useParams();
   
    const [shipment, setshipment] = useState(null)
    const [shipmentOption, setshipmentOption] = useState(2)
    const [zones, setZones] = useState(null)
    const [autoPrice, setautoPrice] = useState(null)
    const [showSuccessModal, setshowSuccessModal] = useState(false)
    const [showSuccessModalAutoPrice, setshowSuccessModalAutoPrice] = useState(false)

    //get zones 
    const {data:zonesData} = useQuery('zones',()=> getDataAdmin(`api/zones`),{
    staleTime:2000,
    cacheTime:5000,
    onSuccess:(data)=>{
      setZones(data.data)
    },
  })
  //get auto pricing 
  const {data:autoPriceData} = useQuery('autoprice',()=> getDataAdmin(`api/autoprice`),{
    staleTime:2000,
    cacheTime:5000,
    onSuccess:(data)=>{
      setautoPrice(data.data)
    },
  })

  //Add Shipment Mutation
  const addShipment = useMutation((body)=> getDataAdmin(body,`api/shipments`),{
    onSuccess:(data)=>{
      let shipment = data.data.newshipment;
      if(shipment.price>0 && shipment.needsOffer===false){
        setshowSuccessModalAutoPrice(true)
      }else if(shipment.needsOffer===true){
        setshowSuccessModal(true)
      }
    }
})


  return (
    <div>
        <NavBar/>
        <BoxContainer additionalStyle={{width:'60vw',marginTop:'1em'}} withLine={false} title='New Shipment'>

            <NewShipmentForm
            calculatePrice={calculatePrice}
            mutation={addShipment} 
            shipment={{}} 
            repeat={false}
            showSuccessModal={showSuccessModal}
            setshowSuccessModal={setshowSuccessModal}
            showSuccessModalAutoPrice={showSuccessModalAutoPrice}
            setshowSuccessModalAutoPrice={setshowSuccessModalAutoPrice}
            zones={zones}
            autoPrice={autoPrice}
            schema={schema}
            centeredPriceContainer={true}
            defaultShipment={defaultShipment}
            admin={true}
            />
        </BoxContainer>
    </div>
  )
}

const schema = yup.object().shape({
    customer_id: yup.string().required(),
    data:yup.array().of(
      yup.object().shape({
        pickup: yup.object().shape({
          location:yup.object({
          address:yup.string().required().label("Address"),
          coordinates:yup.array().min(2).max(2).required().label("Address"),
      })
    }),
    dropoff: yup.object().shape({
        location:yup.object({
            address:yup.string().required().label("Address"),
            coordinates:yup.array().min(2).max(2).required().label("Address"),
        })
    }),
    shipment_attributes: yup.array().of(
        yup.object().shape({
            weight: yup.number().min(1).required().label('Weight'),
            quantity: yup.number().min(1).required().label('Quantity'),
            height: yup.number().min(1).required().label('Height'),
            width: yup.number().min(1).required().label('Width'),
            length: yup.number().min(1).required().label('Length'),
            description: yup.string().required().label('Description'),
          })
  ),
        
      })),
   
  })
  const defaultShipment={
    data:[{
      pickup:{
          date:new Date(),
          time:'09:00 - 12:00',
      },
      dropoff:{
          date:new Date(),
          time:'09:00 - 12:00',
      }, 
      shipment_attributes:[{
        length:120,
        width:80,
        height:100,
        quantity:1,
        weight:100,
        hydraulic_door:false,
        packing:packaging_options[0].value,
        dimensions:palets_types_with_labels[0].value
    }],
    dropoff_flexible:false,
    pickup_flexible:false,
    }],
   
   
}