import React from 'react'
import dateformat from 'dateformat'
import i18next from 'i18next'
export default function OfferCard({offer}) {
    return (
        <div>
       <div className='container m-3 shadow-sm' style={{margin:'auto',padding:0}} >
        <div className='col-12 row'>
         <p className='black_text col'>{i18next.t('common.labels.pickup')}</p>
         <p className='gray_text col'>{dateformat(offer.date_pickup,'d-m-yyyy')}</p>
         <p className='gray_text col'>{offer.time_pickup}</p>
         <p className='gray_text col'>{offer.offer_type}</p>
          <h3 className='blue_text bold col'>{offer.cost} €</h3>
        </div>
        <div className='col-12 row'>
         <p className='black_text col'>{i18next.t('common.labels.dropoff')}</p>
         <p className='gray_text col'>{dateformat(offer.date_dropoff,'d-m-yyyy')}</p>
         <p className='gray_text col'>{offer.time_dropoff}</p>
         <p className='gray_text col'></p>
         <p className='gray_text col'></p>
        </div>
     </div> 
        </div>
    )
}
