import React,{useState,useEffect} from 'react'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {useQuery,useQueryClient,useMutation} from 'react-query'
import { getDataCarrier, getDataCustomer, putDataCustomer, trans, URL } from '../../../globals';
import { getCustomerUserId,getCarrierUserId } from '../../../LocalStorage/Auth';
import CircularProgress from '@material-ui/core/CircularProgress'
import NotificationCard from './NotificationCard';
import Divider from '@material-ui/core/Divider'
import socketIOClient from "socket.io-client";
import { store } from 'react-notifications-component';

export default function NotificationContainer({
  isOpen,
  setOpen,
  setUnreadNotification,
  queryString,
  type='customer',
  user_id,
  fetchUrl,

}) {
    const queryClient = useQueryClient();
    const [notifications, setnotifications] = useState(null)
    const {isLoading,data} = useQuery(queryString,()=> type=='customer'? getDataCustomer(`api/customers/${getCustomerUserId().id}/getnotifications`):getDataCarrier(`api/carriers/${getCarrierUserId()}/getnotifications`),{
        cacheTime:5000,
        statetime:5000,
        onSuccess:(data)=>{
          type=='customer'? setnotifications(data?.Notifications):setnotifications(data?.notifications)
          setUnreadNotification(data?.unreadMessages)
        }
      })
    const markAsUnreadNotifications = useMutation((body)=> putDataCustomer(body,`api/notifications`),{
        onSuccess:()=> queryClient.invalidateQueries(queryString)
    })

      useEffect(() => {
        const notificationsocket = socketIOClient(`${URL}notifications?userid=${user_id}`);
        notificationsocket.on('connect', () => console.log("Connected from Notifications"));

         notificationsocket.on('disconnect', () => {
            notificationsocket.removeAllListeners();
        }); 
        notificationsocket.on('notification', (notification) => {
            store.addNotification({
              title: notification.notification_title,
              message: notification.notification_content,
              type: 'info',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 4000,
                onScreen: true,
              },
            });
            queryClient.invalidateQueries(queryString)
        })
        return () => notificationsocket.disconnect()
      }, [])
      useEffect(() => {
         if(isOpen===true){
            markAsUnreadNotifications.mutate({
                type,
                id:user_id,
                hasRead:true
            })
         }
      }, [isOpen])
    return (
        <div>
            <SwipeableDrawer
            anchor={'right'}
            open={isOpen}
            onClose={()=>setOpen(false)}
            onOpen={()=>setOpen(true)}
           
          >
              <div style={{padding:'1em'}}>
              <h3 className='blue_text mt-2 mb-3'>{trans('notifications.title')}</h3>
              <Divider/>
              {isLoading ? <CircularProgress />:
              (notifications!==null && Array.isArray(notifications))
              && notifications.map(notification =><NotificationCard type={type} notification={notification} />)

              }
              </div>
          </SwipeableDrawer>
        </div>
    )
}
