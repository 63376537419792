import { Component } from "react";
import { getCarrierUserId, getCarrier } from "../../../LocalStorage/Auth";

export default class DriveApiCall extends Component {
  //----------------Request to local storage after refreshing the page-------------
  requestToLocalStorage = () => {
    const token = getCarrier();
    const  _id  = getCarrierUserId();
    const requestBody = {
      carrierid: _id,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    this.props.getAllDrivers(
      requestBody,
      config,
      this.GetDriversSucess,
      this.GetDriversFailure
    );
  };
  GetDriversSucess = () => {
    console.log("driver fetch success", this.props.carrier_AllDrivers);
    const record = this.props.carrier_AllDrivers;
    this.setState({ record, loading: false });
  };
  GetDriversFailure = () => {
    console.log("driver fetch Fail");
    this.setState({loading:false})
  };
}
