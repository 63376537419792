import React, { Component } from 'react'
import  AddPaymentMethod  from './AddPaymentMethod';

export default class PaymentMethod extends Component {
    constructor(props){
        super(props)
        this.state = {
            show:false
        }
    }
    toggleAddPaymentMethod =()=>{
        this.setState({show:!this.state.show})
    }
    render() {

        const { data } = this.props;
        return (
              <div className='bg-white container pb-4'>
                <h3>Payment Method</h3>
                {data.carrier_payment_public_key && data.carrier_payment_secret_key ?
                <div >
                <p className=' d-inline blue_text mr-2'>Primary Method: </p>
                <p className='d-inline black_text'>Stripe Account</p>
                <button onClick={this.toggleAddPaymentMethod} style={{paddingHorizontal:30,paddingVertical:4}} className='ml-3 btn-secondary'>{this.state.show? 'Close' :'Edit'}</button>
                </div>
                
                :null}

                {!(data.carrier_payment_public_key && data.carrier_payment_secret_key) || this.state.show ?
                <AddPaymentMethod/> :null}
              </div> 
        
        )
    }
}
