import React,{useState} from 'react'
import {Badge,IconButton,StyledBadge} from '@material-ui/core';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import NotificationContainer from './NotificationContainer';
export default function NotificationButton({queryString,user_id,type='customer'}) {
    
    const [isnotificationOpen, setnotificationOpen] = useState(false)
    const [unReadMessages, setUnreadMessages] = useState(0)
    return (
        <div>
         <IconButton aria-label="cart" style={{padding:'.2em'}} onClick={()=>setnotificationOpen(!isnotificationOpen)}>
        <Badge badgeContent={unReadMessages} color="primary">
            <NotificationsNoneRoundedIcon fontSize='large' style={{color:'#8798AD'}}/>
        </Badge>
        </IconButton>
        <NotificationContainer 
        unReadMessages={unReadMessages} 
        setUnreadNotification={setUnreadMessages} 
        isOpen={isnotificationOpen} 
        setOpen={setnotificationOpen}
        queryString={queryString}
        user_id={user_id}
        type={type}
        //['customers','notifications',getCustomerUserId().id]

        />
        </div>
    )
}
