import React from 'react'
import MyInput from '../../Common/Forms/MyInput'
import { trans } from '../../../globals';

export default function NewUserForm({formik}) {
  return (
    <div className={`shadow-sm pl-4 pr-4 pb-5  bg-white`}>
         <p className=''>{trans('profile.edit.title')}</p>
         <p className='gray_text'>{trans('profile.edit.message')}</p>
    <div className='row'>
          <div className='col-md-3 col-sm-12'>
                <MyInput
                name='customer.customer_name'
                label ={trans('common.labels.fullname')}
                value={formik.values?.customer?.customer_name}
                error={formik.errors?.customer?.customer_name}
                onChange={formik.handleChange} 
                type='text'
                    />
           </div>
           <div className='col-md-3 col-sm-12'>
                <MyInput
                name='customer.customer_email'
                label ={trans('common.labels.email')}
                value={formik.values?.customer?.customer_email}
                error={formik.errors?.customer?.customer_email}
                onChange={formik.handleChange} 
                type='text'
                    />
           </div>
           <div className='col-md-3 col-sm-12'>
                <MyInput
                name='customer.customer_password'
                label ={trans('common.labels.password')}
                value={formik.values?.customer?.customer_password}
                error={formik.errors?.customer?.customer_password}
                onChange={formik.handleChange} 
                type='text'
                    />
           </div>


        </div>
    </div>
  )
}
