const initialstate = {
  Drivers: [],
  //any other customer object can be added here and later used
};

const driverReducer = (state = initialstate, action) => {

  if (action.type === "GET_DRIVERS") {
    return {
      ...state,
      Drivers: [...action.data.data],
    };
  }

  if (action.type === "ADD_DRIVER") {
    return {
      ...state,
      Drivers: [action.data.data.newDriver, ...state.Drivers],
    };
  }
  if (action.type === "DELETE_DRIVER") {
    const deletedriverId = action.data;
    const DriversRecord = [...state.Drivers];
    const newRecord = DriversRecord.filter((driver) => {
      return driver._id === deletedriverId;
    });
    return {
      ...state,
      Drivers: [...newRecord],
    };
  }
  if (action.type === "EDIT_DRIVER") {
    const updatedDriverId = action.id;
    const updatedDriver = { ...action.data };
    const DriversRecord = [...state.Drivers];
    const Index = DriversRecord.findIndex((driver) => {
      return driver._id === updatedDriverId;
    });
    DriversRecord[Index] = updatedDriver;
    return {
      ...state,
      Drivers: [...DriversRecord],
    };
  }

  return state;
};

export default driverReducer;
