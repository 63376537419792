import React from "react";
import logo from "../../../Pics/logo_with_text.svg";
import InputField from "../../Common/InputField";
import Form from "../../Common/Form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "../../../Styles/LogInForm.css";
import * as yup from 'yup'
import {customerServices } from "../../../Services/Customer/customer_services";
import { trans } from "../../../globals";
import { authServices } from "../../../Services/Auth/auth_services";
import {  createLocalStorageCustomer } from "../../../LocalStorage/Auth";
import ChangeLanguage from "../../../locale/ChangeLanguage";


let carrier_schema = yup.object().shape({
  carrier_name: yup.string().required().label('Name'),
  carrier_password: yup.string().required().label('Password'),
  carrier_password_re: yup.string().required().label('Confirmation Password').required().oneOf([yup.ref('carrier_password'), null], 'Passwords must match'),
  carrier_email: yup.string().required('Email is required').email().label('Email'),
  carrier_phone: yup.number('Not a Number').required('Mobile Number is Required!').label("Mobile Number").positive()
});
let customer_schema = yup.object().shape({
  customer_name: yup.string().required().label('Name'),
  customer_password: yup.string().min(6).required().label('Password'),
  customer_email: yup.string().required('Email is required').email().label('Email'),
  customer_mobilenum: yup.number('Not a Number').required('Mobile Number is Required!').label("Mobile Number").positive(),
  customer_password_re: yup.string().required().min(6).label('Confirmation Password').required().oneOf([yup.ref('customer_password'), null], 'Passwords must match'),
});

class SignUp extends Form {
  state = {
    errors: {},
  };
  
  changeHandler = e => this.setState({ [e.currentTarget.name]: e.currentTarget.value });

  signup= (e) => { 
     let errors={}
     customer_schema.validate(this.state,{abortEarly:false})
     .then(res=>{
      this.setState({errors:{}})
      const body={
        customer_name: this.state.customer_name,
        customer_mobilenum: this.state.customer_mobilenum,
        customer_email: this.state.customer_email,
        customer_password: this.state.customer_password,
      }
      this.props.add_customer(body,this.callback)
     }).catch(err=>{
      err.inner.map(err=>{  
        errors[err.path] =err.message;
      });
        this.setState({errors})
     })
  e.preventDefault()
  };

  callback=(res)=>{
    if(res){ 
      createLocalStorageCustomer(res.data.authToken,res.data.data.newcustomer)
      this.props.history.push("/Shipments");
    }
  }
  
  render() {
    return (
      <div className="container-fluid" style={{ background: "#ffffff",height:'100vh' }}>
            <ChangeLanguage style={{position:'absolute',left:30,top:10,zIndex:1000}} />
        <div className="row" style={{height:'100vh'}}>
          <div className="logo--position">
            <img src={logo} className="img-fluid" alt="logo"></img>
          </div>

          <div className="col-md-4 offset-md-1 top__signup--margin form__padding--smallscreen">
            <span className="fontchange">{trans("signup.customer_signup")}</span>
            <p className="gray_text d-block mb-3">
             {trans("signup.subtitle")}
            </p>
            
            {/* --------Duplicate Email Error---------- */}
            {this.state.invalid_signup && (
              <div className="alert alert-danger">
                This Email Address already has been taken.
              </div>
            )}


            {/* ------------ form start ------------------- */}
            <form onSubmit={this.signup} autoComplete="off" >
             <input autocomplete="false" name="hidden" type="text" style={{display: "none"}}/>
              <div className="form-group">
                <InputField
                  labeltitle="common.labels.fullname"
                  typename="text"
                  fieldname="customer_name"
                  changeHandler={this.changeHandler}
                  value={this.state.customer_name}
                  error={this.state.errors.customer_name}
                />
              </div>
              <div className="form-group">
                <InputField
                  labeltitle="common.labels.mobilenum"
                  typename="text"
                  fieldname="customer_mobilenum"
                  changeHandler={this.changeHandler}
                  value={this.state.customer_mobilenum}
                  error={this.state.errors.customer_mobilenum}
                />
              </div>
              <div className="form-group">
                <InputField
                  labeltitle="common.labels.email"
                  typename="email"
                  fieldname="customer_email"
                  changeHandler={this.changeHandler}
                  value={this.state.customer_email}
                  error={this.state.errors.customer_email}
                />
              </div>
              <div className="form-group">
                <InputField
                  labeltitle="common.labels.password"
                  typename="password"
                  fieldname="customer_password"
                  changeHandler={this.changeHandler}
                  value={this.state.customer_password}
                  error={this.state.errors.customer_password}
                />
              </div>
              <div className="form-group">
                <InputField
                  labeltitle="common.labels.re_password"
                  typename="password"
                  fieldname="customer_password_re"
                  changeHandler={this.changeHandler}
                  value={this.state.customer_password_re}
                  error={this.state.errors.customer_password_re}
                />
              </div>
              <button
                disabled={this.signup}
                type="submit"
                className="btn btn-primary btncolor btn-block btn-lg"
              >
                {this.props.loading ? (
                  <span>Loading ...</span>
                ) : (
                  <span>{trans('common.buttons.signup')}</span>
                )}
              </button>
              <div className="text--color text-center mt-2 my-4">
               {trans("signup.already_account")} &nbsp;
                <Link to="/customerlogin">
                  <span className="font-weight-bold">{trans('common.buttons.signin')}</span>
                </Link>
              </div>
            </form>
            {/*----------------Form end-------------- */}
          </div>
          <div className="col-md-6 offset-md-1 pr-0">
            <div className="bgpic"></div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state=>{
  return {
    loading:state.customerReducer.loading,
    error_message:state.customerReducer.error_message
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    add_customer:(body,callback)=>dispatch(customerServices.customerSignup(body, callback)),
    signupCarrier:(body,callback)=>dispatch(authServices.signupCarrier(body, callback))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
