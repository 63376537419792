import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getCarrier } from "../../LocalStorage/Auth";

const ProtectedRCarrier = ({ path, component: Component, render }) => {
  return (
    <Route
      path={path}
      render={(props) => {
        if (!getCarrier()) return <Redirect to="/" />;
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRCarrier;
