import React from 'react'
import  Typography  from '@material-ui/core/Typography';
import { Column } from '@dimararist/woocargo.layout.column';

export default function LabelWithText({label,text,isRow=false}) {
  return (
      <Column>
            <Typography  color='primary'>{label}</Typography>
            <Typography  color='black'>{text}</Typography> 
      </Column>
  )
}
