import { FILTER_CUSTOMER_SHIPMENTS,GET_CUSTOMER_SHIPMENTS_START,GET_CUSTOMER_SHIPMENTS_FAIL,GET_CUSTOMER_SHIPMENTS_SUCCESS, GET_CUSTOMER_SHIPMENT_BYID_START, GET_CUSTOMER_SHIPMENT_BYID_FAIL, GET_CUSTOMER_SHIPMENT_BYID_SUCCESS} from "../Actions/actions";

const initialstate = {
  CustomerShipments: [],
  filter_applied:false,
  filtered_customer_shipments:[],
  offers: [],
  loading:false
};

const customerShipmentReducer = (state = initialstate, action) => {
  if (action.type === "ADD_NEW_SHIPMENT_CUSTOMER") {
    return {
      ...state,
      CustomerShipments: [
        action.data.data.newshipment,
        ...state.CustomerShipments,
      ],
    };
  }
//GET CUSTOMER SHIPMENTS----------------------------------
if (action.type=== GET_CUSTOMER_SHIPMENTS_START) return{ ...state,loading:true}
if (action.type=== GET_CUSTOMER_SHIPMENTS_FAIL) return { ...state,loading:false,error_message:action.error_message}
if (action.type=== GET_CUSTOMER_SHIPMENTS_SUCCESS)return { ...state,CustomerShipments: [...action.payload.data.data.shipments],loading:false}
//--------------------------------------------------------
//GET CUSTOMER SHIPMENT BY ID ----------------------------
if (action.type=== GET_CUSTOMER_SHIPMENT_BYID_START) return{ ...state,loading:true}
if (action.type=== GET_CUSTOMER_SHIPMENT_BYID_FAIL) return { ...state,loading:false,error_message:action.error_message}
if (action.type=== GET_CUSTOMER_SHIPMENT_BYID_SUCCESS){
  let shipments = state.CustomerShipments;
  const fetched_shipment = action.payload.data.data.shipment;
  const index = shipments.findIndex(s=>s._id==fetched_shipment._id)
  if(index!=-1){
    shipments[index]=fetched_shipment; 
  }else{
    shipments.push(fetched_shipment)
  }
  return {...state,CustomerShipments:shipments};
}

//--------------------------------------------------------


  if (action.type === "ADD_SHIPPER_RECEIVER") {
    const CustomerShipments = state.CustomerShipments.find(shipment=>{
      if(shipment._id===action.shipment._id){
        shipment=action.shipment
      }
    })
    return {
      ...state,
      CustomerShipments
    };
  }
  if(action.type===FILTER_CUSTOMER_SHIPMENTS){
    return {...state,filtered_customer_shipments:action.filtered_customer_shipments,filter_applied:true}
  }

  if (action.type === "ADD_OFFER")
    return {
      ...state,
      offers: [...action.data.data.offers, ...state.offers],
    };

  if (action.type === "ACCEPT_CUSTOMER_OFFER") {
    const updatedshipment = action.data.data.Shipment;
    const allcustomerShipments = [...state.CustomerShipments];
    const index = allcustomerShipments.findIndex(
      (shipment) => shipment._id === updatedshipment._id
    );
    allcustomerShipments[index] = updatedshipment;
    return {
      ...state,
      CustomerShipments: [...allcustomerShipments],
    };
  }

  return state;
};

export default customerShipmentReducer;
