import { httpRequest } from "../services_utils";

const defaultConfig ={
    loading:true,
    type:"carrier",
    showError:true
}
export const carrierServices ={

    addVehicle:
    (body,callback)=> 
    dispatch=>httpRequest({...defaultConfig,http_type:'post',url:`api/carriers/vehicles`,action:'ADD_VEHICAL',body,callback},dispatch),

    updateCarrier:
    (body,callback,carrier_id)=> 
    dispatch=>httpRequest({...defaultConfig,http_type:'put',url:`api/carriers/${carrier_id}`,body,callback},null),

    getCarrierById:
    (carrier_id,callback)=> 
    dispatch=>httpRequest({...defaultConfig,http_type:'get',url:`api/carriers/${carrier_id}`,action:'GET_CARRIER_BY_ID',callback},dispatch),

    getVehicles:
    (body,callback)=> 
    dispatch=>httpRequest({...defaultConfig,http_type:'get',url:`api/carriers/${body.carrierid}/vehicles`,action:'GET_VEHICLES',callback},dispatch),

    editVehicle:
    (body,id,callback)=> 
    dispatch=>httpRequest({...defaultConfig,http_type:'put',url:`api/carriers/vehicles/${id}`,action:'EDIT_VEHICLES',body,callback},dispatch),

    deleteVehicle:
    (id,callback)=> 
    dispatch=>httpRequest({...defaultConfig,http_type:'delete',url:`api/carriers/vehicles/${id}`,action:'DELETE_VEHICLES',callback},dispatch),

}

