import React,{useEffect} from 'react'
import { useHistory,useLocation } from 'react-router';
import  ReactGA  from 'react-ga';
export default function TrackRoutes({children}) {
    const history = useHistory();
    const currentLocation= useLocation();
    useEffect(() => {
      ReactGA.pageview(window.location.hostname+currentLocation.pathname)
      history.listen((location)=>ReactGA.pageview(window.location.hostname+location.pathname))
    }, [])

    return (
       <>
       {children}
       </>
    )
}
