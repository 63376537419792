import axios from "axios";
import { URL } from "../../globals";
import { AcceptCustomerOfferAction } from "../../Redux/Actions/actions";

const SimpleCustomerOffer = (
  requestbody,
  config,
  shipment,
  dispatch,
  callback
) => {
  console.log(requestbody, config, shipment.id);
  axios
    .post(
      `${URL}api/shipments/${shipment.id}/acceptoffer/`,
      requestbody,
      config
    )
    .then(function (response) {
      console.log("offer accepted service", response);
      dispatch(AcceptCustomerOfferAction(response.data));
      callback(true,'Success','Offer Accepted');
      window.location.reload();
    })
    .catch(function (error) {
      console.log("offer fail service");
        callback(false,'Fail','Error Occured');
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.log("Error", error.message);

        console.log("error 1");
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    });
};

export default SimpleCustomerOffer;
