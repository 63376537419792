import React,{useEffect} from 'react'
import { palets_types, trans, kibvtia_types,time_options, notEmptyArray } from '../../../../globals';
import InputPlacesAutoComplete from '../../../Common/Forms/InputPlacesAutoComplete';
import MySelect from '../../../Common/Forms/MySelect';
import MyDatePicker from '../../../Common/Forms/MyDatePicker';
import MySwitch from '../../../Common/Forms/MySwitch';
import MyInput from '../../../Common/Forms/MyInput';
import MyTextField from '../../../Common/Forms/MyTextField';

export default function LocationTime({formik,shipment,handleFieldValue,handleChange,values,index,initialValues}) {

    useEffect(() => console.log("fefef",formik.initialValues),[])
    
  
    const handleChangeSwitch =(e)=>{
        formik.setFieldValue(e.target.name,e.target.checked)
        if(e.target.name=='pickup_flexible'){
       
            if(!e.target.checked){
                formik.setFieldValue('pickup_date',new Date())
                formik.setFieldValue('pickup_time',time_options[2].label)
            }
        }else if(e.target.name=='dropoff_flexible'){
            if(!e.target.checked){
                formik.setFieldValue('dropoff_date',new Date())
                formik.setFieldValue('dropoff_time',time_options[2].label)
            }
        }
    }
    const notEmptyObject =(obj)=> Object.keys(obj).length>0


    return (
        <div className={`shadow-sm pl-4 pr-4 bg-white`}>
          <p className={`pt-4 black_text`}>{trans("newshipment.locationtime.title")}</p>
          <p className='gray_text'> {trans("newshipment.locationtime.message")}</p>
          <div className='row'>

        
                    <div className='col-md-4 col-sm-12'>
                    <InputPlacesAutoComplete
                        label={trans('shipmentinfo.pickup_location')}
                        onChange={handleFieldValue}
                        defaultValue={values?.pickup?.location?.address}
                        name={`data[${index}].pickup.location`}
                        error={notEmptyArray(formik?.errors?.data) &&formik.errors?.data[index]?.pickup?.location?.postal_code}
                        />
                    </div>
                    <div className='col-md-2 col-sm-12'>
                        <MySwitch
                        label={trans('newshipment.locationtime.flexible')}
                        onChange={handleChangeSwitch} 
                        name={`data[${index}].pickup.flexible`}
                        defaultChecked={values.pickup?.flexible || false}
                        checked={values.pickup?.flexible || false}
                         />
                    </div>
                    {values.pickup?.flexible==false?
                    <>

                    <div className='col-md-3 col-sm-12'>
                        <MyDatePicker
                        name={`data[${index}.pickup.date`}
                        label={trans('shipmentinfo.pickup_date')}
                        onChange={handleFieldValue}
                        selectedDate={values.pickup.date||initialValues.pickup.date}
                        />
                    </div>

                    <div className='col-md-3 col-sm-12'>
                        <MySelect 
                        name={`data[${index}.pickup.time`}
                        label={trans('shipmentinfo.time')}
                        options={time_options} 
                        defaultValue={values?.pickup.time||time_options[2].label}
                        onChange={handleFieldValue}
                        />
                        </div>
                    </>
                     : 
                     <div className='col-md-6'></div>}
                        
                        <div className='col-md-4 col-sm-12'>
                        <InputPlacesAutoComplete
                        label={trans('shipmentinfo.dropoff_location')}
                        defaultValue={values?.dropoff?.location?.address}
                        onChange={handleFieldValue}
                        name={`data[${index}.dropoff.location`}
                        error={notEmptyArray(formik?.errors?.data) &&formik.errors?.data[index]?.dropoff?.location?.postal_code}
                        />
                        </div>
                        <div className='col-md-2 col-sm-12'>
                        <MySwitch 
                        label={trans('newshipment.locationtime.flexible')}
                        onChange={handleChangeSwitch} 
                        name={`data[${index}].dropoff.flexible`}
                        defaultChecked={values.dropoff.flexible||false}
                        checked={values.dropoff?.flexible}
                        />
                        </div>
                    {values.dropoff?.flexible==false?
                    <>
                    <div className='col-md-3 col-sm-12'>
                        <MyDatePicker 
                        name={`data[${index}.dropoff.date`}
                        label={trans('shipmentinfo.dropoff_date')}
                        onChange={handleFieldValue}
                        selectedDate={values.dropoff?.date||notEmptyObject(shipment)&&shipment?.dropoff?.date}
                        />
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <MySelect 
                        label={trans('shipmentinfo.time')}
                        name={`data[${index}.dropoff.time`}
                        options={time_options} 
                        defaultValue={values?.dropoff.time||time_options[2].label}
                        onChange={handleFieldValue}
                        />
                    </div>
                    </>
                    : <div className='col-md-6'></div>}
                         <div className='col-md-6 col-sm-12'>
                         <MyInput
                        name={`data[${index}.shipment_attributes[0].description`}
                        label ={trans('shipmentinfo.description')}
                        value={notEmptyArray(values.shipment_attributes)&&values.shipment_attributes[0].description}
                        error={notEmptyArray(formik.errors.data,index)&&notEmptyArray(formik.errors.data[index]?.shipment_attributes)&&formik.errors.data[index]?.shipment_attributes[0].description}
                        defaultValue={notEmptyArray(values.shipment_attributes)&&values.shipment_attributes[0].description}
                        onChange={handleChange} 
                        placeholder='π.χ 2 Υλικά Συσκευασίας'
                         />
                         </div>
                         <div className='col-md-6 col-sm-12'>
                         <MyTextField
                        name={`data[${index}.shipment_attributes[0].notes`}
                        label ={trans('shipmentinfo.notes')}
                        value={notEmptyArray(values.shipment_attributes)&&values.shipment_attributes[0].notes}
                        error={notEmptyArray(formik.errors.shipment_attributes)&&formik.errors.shipment_attributes[0].notes}
                        defaultValue={notEmptyArray(values.shipment_attributes)&&values.shipment_attributes[0].notes} 
                        onChange={handleChange} 
                         />
                         </div>
            </div>
        </div>
    )
}
