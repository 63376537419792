import React,{useEffect} from 'react'
import LocationTime from '../../../Common/Shipments/LocationTime/LocationTime';
import { Button, Typography, IconButton } from '@material-ui/core';
import { notEmptyArray } from '../../../../globals';
import DriverStateBardge from '../../../Common/Badges/DriverStateBadge/DriverStateBardge';
import Column from '../../../Common/Layout/Column';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
export default function StopsDetails({shipment_status,data,setdrawerIndex,index,setstopDrawerOpen}) {
    
  return (
      <div className='shadow-sm mb-3' onClick={()=>{
        setdrawerIndex(index)
        setstopDrawerOpen(true)
        }} >
        {data.map(d=>{
            const sa =notEmptyArray(d.shipment_attributes)? d.shipment_attributes[0]: {}
           return (
           <div>
            <div className='d-flex flex-row col-12 hover-gray p-2 border-rounded'>
              <div className='col-md-2 col-sm-12 '>
                <Column>
                  <Typography>{sa.description}</Typography> 
                  {shipment_status!='WAITING' && <DriverStateBardge driver_state={d.states}/>}
                </Column>
                </div>
                  {showDateTimeLocation(d.pickup)}
                  {showDateTimeLocation(d.dropoff,false)}
              <div className='col-md-2 col-sm-12'>
                <Typography>{sa.quantity} {sa.packing} {sa.length}*{sa.width}*{sa.height} {sa.weight} kg</Typography>
              </div>
            
              <IconButton aria-label="cart" style={{width:'2em',height:'2em',position:'absolute',right:10,top:'50%',transform:'translate(-50%,-50%)'}} 
              onClick={()=>{
                setdrawerIndex(index)
                setstopDrawerOpen(true)
                }}>
              <KeyboardArrowRightIcon fontSize='large' style={{color:'#2E5BFF'}}/>
          </IconButton>
          </div>
        </div>
        )
        })}

      </div>
  )
}

const showDateTimeLocation =(data,isPickup=true)=>{
    return <div className='col-4 row'>
               <LocationTime isPickup={isPickup} data={data} />
         </div>
}

