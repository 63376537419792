import { FormControlLabel,Switch } from '@material-ui/core';
import React from 'react'
export default function MySwitch({label,onChange,name,checked,defaultChecked=false}) {
    return (
        <div className='d-flex flex-column'>
        <label className='gray_text'>{label}</label>
        <FormControlLabel
        control={
          <Switch
            checked={checked}
            defaultChecked={defaultChecked} 
            onChange={onChange}
            name={name}
            color="primary"
          />
        }
      />
        </div>
    )
}
