import React, { Component } from 'react'
import Modal from "react-bootstrap/Modal";
import { trans } from '../../../globals';
import OfferCard from './OfferCard';
import closeButton from '../../../Pics/close.png';

export default class CarrierOffers extends Component {
    


    render() {
      
        return (
            <Modal
              size="lg"
              show={this.props.show}
              onHide={this.props.onClose}
              backdrop="static"
              animation="true"
              dialogClassName="modal2"
              aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header> <h4>{trans("common.buttons.offers")}</h4></Modal.Header>
              <Modal.Body>
               <button
                style={{
                  position: "fixed",
                  top: 10,
                  right: 10,
                  background: "transparent",
                  border: 0,
                }}
                onClick={this.props.onClose}
              >
                <img style={{height:'2em',width:'2em'}}src={closeButton}></img>
              </button>


                <div className='container'>
               
                {this.props.offers.map((offer)=>{
                    return <OfferCard key={offer._id} offer={offer}/>
                })}
                </div>
             
              </Modal.Body>
            </Modal>
          );
    }
}
