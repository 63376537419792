import { combineReducers } from "redux";
import customerReducer from "./customer_reducer";
import carrierReducer from "./carrier_reducer";
import customerShipmentReducer from "./customerShip_reducer";
import NotificationReducer from "./Notification_reducer";
import driverReducer from "./driver_reducer";
import vehicalReducer from "./vehical_reducer";
import chat_reducer from './chat_reducer';
const rootreducer = combineReducers({
  customerReducer,
  carrierReducer,
  customerShipmentReducer,
  NotificationReducer,
  driverReducer,
  vehicalReducer,
  chat_reducer
});

export default rootreducer;
