import { Drawer, Button } from '@material-ui/core';
import React,{useState,useEffect} from 'react'
import { useMutation, useQueryClient } from 'react-query';
import { putDataAdmin, api_callback } from '../../../globals';
import Form from '../Forms/Form';

export default function EditRecord({_id,formStructure,editUrl,queryString,record}) {

    const [editRecordDrawer,seteditRecordDrawer] = useState(false)
    const queryClient = useQueryClient()
    //Edit Mutation
    const editMutation = useMutation((body)=>putDataAdmin(body,editUrl),{
        onSuccess: (data)=>{
            queryClient.invalidateQueries(queryString)
            api_callback(true,"Success","Η αλλαγή έγινε με επιτυχία")
        }
      })
      useEffect(()=>{
      },[formStructure])
    const formikParams = {
        initialValues:{...record},
        onSubmit:body=>{
            //alert(JSON.stringify(body))
            editMutation.mutate(body)
        },
    }

  return (
    <div>
        <Button onClick={()=>seteditRecordDrawer(true)}>Edit</Button>
        <Drawer
         PaperProps={{
            sx: { width: "50%" },
          }}   
            open={editRecordDrawer}
            onClose={()=>seteditRecordDrawer(false)}
            anchor='right'
        >
            <div className='p-4'>
            <Button color='primary' variant='contained' onClick={()=>seteditRecordDrawer(false)}>Close</Button>
            <Form
            title='Edit Deal'
            structure={formStructure}
            formikParams={formikParams}
            />
            </div>
        </Drawer>
    </div>
  )
}
