import React,{useState,useEffect} from 'react'
import Modal from "react-bootstrap/Modal";
import CloseIcon from '@mui/icons-material/Close';
export default function MyModal({show,setShow,children,title=null,titleComponent,hasHeader=true,footer,dialogClassName=''}) {
    
  

    return (
        <Modal
        dialogClassName={dialogClassName}
        show={show}
        onHide={()=>setShow(false)}
        backdrop="static"
        animation="true"
        aria-labelledby="example-modal-sizes-title-lg p-3"
      >
          {hasHeader &&
          <Modal.Header>
             {title? 
             <h4>{title}</h4> :
             {titleComponent}}
              <button
          style={{
            position: "fixed",
            top: 10,
            right: 10,
            background: "transparent",
            border: 0,
          }}
          onClick={()=>setShow(false)}
        >
            <CloseIcon/>
        </button>
              
          </Modal.Header>
          }
          <Modal.Body style={{paddingRight:'3em',paddingLeft:'3em'}}>
              {children}
          </Modal.Body>
          <Modal.Footer>
              {footer}
          </Modal.Footer>

        </Modal>
    )
}
