import React from 'react'
import ShipmentSection from '../../../Components/CustomerSide/Shipments/ShipmentSection/ShipmentSection'
import NavBar from '../NavBar/NavBar'
import useFetchData from '../../../Components/CustomerSide/Shipments/ShipmentSection/useFetchData';

export default function Shipments() {

    const [data,isLoading]= useFetchData(`api/shipments`,['admin','shipments'],'customer','data')

    return (
        <>
            <NavBar />
            <div className="container-fluid pt-5" style={{ minHeight: "90vh" }}>
            <div className="row">
                <ShipmentSection
                type='admin'
                isLoading={isLoading}
                shipments={data}
                />
             </div>
            </div>
        </>
    )
}
