import React,{useState,useEffect} from 'react'
import { trans, packaging_options, palets_types, notEmptyObject, notEmptyArray } from '../../../../globals';
import GridForm from '../../../Common/Forms/GridForm';

export default function Dimensions({formik,shipment,handleChange,handleFieldValue,packingOptions,index,value}) {

    useEffect(() => {
      console.log("object",packingOptions) 
    }, [packingOptions])

    const dimensionsStructure =[
        {
            type:'text',
            label:'shipmentinfo.length',
            name:'length',
            gridClass:'col-md-2 col-sm-12',
            datatype:'number',
            value:  value.shipment_attributes[0]?.['length'],
        },
        {
            type:'text',
            label:'shipmentinfo.width',
            name:'width',
            gridClass:'col-md-2 col-sm-12',
            datatype:'number',
            value:  value.shipment_attributes[0]?.['width'],
        },
        {
            type:'text',
            label:'shipmentinfo.height',
            name:'height',
            gridClass:'col-md-2 col-sm-12',
            datatype:'number',
            value:  value.shipment_attributes[0]?.['height'],
        },
       
    ]
    const structure1 =[
        {
            type:'text',
            label:'shipmentinfo.quantity',
            name:'quantity',
            gridClass:'col-md-2 col-sm-12',
            datatype:'number',
            value:  value.shipment_attributes[0]?.['quantity'],
        },
        {
            type:'select',
            label:'shipmentinfo.packing',
            name:'packing',
            gridClass:'col-md-2 col-sm-12',
            options:packaging_options,
            defaultValue: notEmptyArray(value.shipment_attributes) && value.shipment_attributes[0].packing||packaging_options[0].value
            
        },
        {
            type:'optionfield',
            label:'shipmentinfo.dimensions',
            name:'dimensions',
            gridClass:'col-md-3 col-sm-12',
            options:packingOptions, 
            defaultValue:value.shipment_attributes[0]?.['dimensions']
        
        },
        {
            type:'text',
            label:'shipmentinfo.weight',
            name:'weight',
            gridClass:'col-md-2 col-sm-12',
            datatype:'number',
            value:  value.shipment_attributes[0]?.['weight'],
        },
        {
            type:'switch',
            label:'shipmentinfo.hydraulic doors',
            name:'hydraulic_door',
            gridClass:'col-md-1 col-sm-12',
            defaultValue:  notEmptyArray(value.shipment_attributes) && value.shipment_attributes[0].hydraulic_door||false
        },
    ]



    return (
       <div className={`shadow-sm pl-4 pr-4 pb-5 bg-white`}>
          <p className="pt-4 black_text">
              {trans('newshipment.dimensions.title')}{' '}
            </p>
            <p className='gray_text'>{trans('newshipment.dimensions.message')}</p>
          <div className='row'>
                    {/* Dimensions Form */}       
                    <GridForm
                            structure={structure1}
                            handleChange={handleChange}
                            formik={formik}
                            index={index}
                            handleFieldValue={handleFieldValue}
                        />

                    {String(value.shipment_attributes[0].dimensions).toLowerCase()==='custom'&&
                        <GridForm
                            structure={dimensionsStructure}
                            handleChange={handleChange}
                            formik={formik}
                            index={index}
                            handleFieldValue={handleFieldValue}
                        />
                    }
    {/*
                <div className='col-md-3 col-sm-12'>
                         <MySwitch 
                        name='shipment_attributes[0].hydraulic_door'
                        label ={trans('shipmentinfo.hydraulic doors')}
                        onChange={formik.handleChange}
                        defaultChecked={notEmptyArray(formik.errors.shipment_attributes)&&notEmptyArray(shipment)&&shipment?.shipment_attributes[0].hydraulic_door} />
                        </div>
                       
          */}            
                        
       </div>
       </div>
    )
}


const inputNameFromIndex = (index,name)=> `data[${index}].shipment_attributes[0].${name}`



const saExists = (formik,value='values',index)=>{
    if(notEmptyArray(formik[value].data,index) && notEmptyArray(formik[value].data[index].shipment_attributes)){
        return true
    }else{
        return false
    }
}
