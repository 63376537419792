import axios from 'axios';
import { api_callback, URL } from '../../../../globals';
import { add_carrier_payment_method_start, add_carrier_payment_method_success } from '../../../../Redux/Actions/actions';


export const add_carrier_payment_method_async=(body)=>{
    
    return (dispatch)=>{
      dispatch(add_carrier_payment_method_start())

      axios
      .post(`${URL}api/carriers/add/paymentmethod`,body)
      .then((response)=> {
          console.log(response)
        dispatch(add_carrier_payment_method_success(response.carrier));
        api_callback(true,'Επιτυχία','Επιτυχία')
      })
      .catch(function(error) {
        if (error.response) {
         //  dispatch(add_carrier_payment_method_fail(error.response.data.error_message))
           api_callback(false,'Αποτυχία','Fail Occured')
           console.log(error.response.data);
           console.log(error.response.status);
           console.log(error.response.headers);
           console.log("Error", error.message);
           console.log("error 1");
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
           console.log(error.config);
        });
        };;
      }