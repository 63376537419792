import React,{useEffect} from "react";
import { trans } from "../../globals";
const OptionField = ({
  labelTitle,
  name,
  error,
  Option,
  value,
  changeHandler,
  guest_class_name,
  defaultValue,
  withLabel=false
}) => {

  useEffect(()=>{
    console.log("Default Value :",defaultValue)
  },[defaultValue] )

  return (
    <>
      <label className="gray_text">{trans(labelTitle)}</label>
      <select
        name={name}
        id={name}
        value={value}
        onChange={changeHandler}
        defaultValue={defaultValue}
        className={`form-control ${guest_class_name}`}
        style={{borderColor:`${error?'rgba(214, 56, 56, 0.7)':''}`,backgroundColor:`${error?'rgba(255,0,0,0.1':''}`}}
      >
        {Option.map((item, i) => {
          return (
            <option key={i} value={withLabel ?item.label :item}>
              {withLabel ?item.label :item}
            </option>
          );
        })}
      </select>
      {error && ( <p className="red_text d-block" style={{ fontWeight:400,position:'relative'}}>{error}</p> )}
    </>
  );
};
export default OptionField;
