import React, { Component,useEffect,useState } from 'react'
import Select from 'react-select'
import { getDataCarrier, getDataCustomer } from '../../../globals'
import {useQuery} from 'react-query'

const MySelect = ({isFetching,queryString='',getUrl,onChange,label1,label2,label,defaultValue=null,error,name,options,type='customer',isMulti=false}) => {
  
 const [fetchedOptions, setoptions] = useState([])
 const {isLoading,data,refetch} = useQuery(queryString,()=> type=='customer'? getDataCustomer(getUrl):getDataCarrier(getUrl),{
  enabled:false,
  onSuccess:(data)=>{
    if(data){
      if(Array.isArray(data.data)){
       let temp =[]
      data?.data.map(data=>{
       temp.push({value:data._id,label:data[label1]+" - "+data[label2]})
      })
      setoptions(temp)
  } 
  }
}
})

const [defaultSelectValue, setDefaultSelectValue] = useState(null)
//calculate default value of select
useEffect(() => {
  if(defaultValue){
  if(Array.isArray(options)){
  let found = options ? options.findIndex (o=> o.value===defaultValue)  :fetchedOptions && fetchedOptions.findIndex (o=> o.value===defaultValue)
  if(found!=-1){
    setDefaultSelectValue(found)
  }else{ //not found
    setDefaultSelectValue(-1)
  }
}
}
}, [options,fetchedOptions])


useEffect(() => {
  if(isFetching){
    refetch()
  }
}, [isFetching])



 return (
  <div style={{marginTop:'0em'}}>
<label className='gray_text'>{label}</label>
{defaultValue ?
  defaultSelectValue==null ?""
  :
  defaultSelectValue>=0&&

  <Select 
  isMulti={isMulti}
  options={options||fetchedOptions}
  isLoading={isLoading}
  defaultValue={defaultSelectValue>=0 ? options?options[defaultSelectValue]:fetchedOptions[defaultSelectValue]:null}
  onChange={(e)=>{
    if(isMulti){
      if(Array.isArray(e)){
        e.map(option=>{
          onChange(`${name}[${parseInt(option.value)-1}]`,option.value)
        })
      } 
    }else{
      onChange(name,e.value)
    }
  }}
  style={{borderColor:error&&"red",backgroundColor:error&&"rgba(255,0,0,.1"}}
  />
  :
  <Select 
  isMulti={isMulti}
  options={options||fetchedOptions}
  isLoading={isLoading}
  onChange={(e)=>{
    console.log("Values from multi select",e)
    if(isMulti){
      if(Array.isArray(e)){
        e.map((option,index)=>{
          onChange(`${name}[${parseInt(index)}]`,option.value)
        })
      } 
    }else{
      onChange(name,e.value)
    }
  }}
  style={{borderColor:error&&"red",backgroundColor:error&&"rgba(255,0,0,.1"}}
  />

}
  
  
  
   {error && <p style={{color:'red'}}>{error}</p>}
  </div>
)
}

export default MySelect;