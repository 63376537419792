import React,{useEffect,useState} from "react";
import NavBar from "../../Common/NavBar";
import {
  useParams,
  useHistory
} from "react-router-dom";
import * as yup from 'yup';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import NewShipmentForm from './NewShipmentForm/NewShipmentForm';
import {getDataCustomer,trans,postDataCustomer, packaging_options, palets_types_with_labels,getNextWorkDay} from '../../../globals'
import SuccessFeedbackModal from '../../Common/Modals/SuccessFeeback/SuccessFeedbackModal';
import Row from "../../Common/Layout/Row";
import SNDayDeliveryForm from './SameDay-NextDayDelivery/SNDayDeliveryForm';
import { Typography } from "@material-ui/core";
import { calculatePrice } from "./helpers";
import { getCustomerUserId } from '../../../LocalStorage/Auth';

function NewShipment() {
  const queryClient = useQueryClient()
  let { repeat ,shipment_id } = useParams();
 
  const [shipment, setshipment] = useState(null)
  const [shipmentOption, setshipmentOption] = useState(2)
  const [zones, setZones] = useState(null)
  const [autoPrice, setautoPrice] = useState(null)
  const [showSuccessModal, setshowSuccessModal] = useState(false)
  const [showSuccessModalAutoPrice, setshowSuccessModalAutoPrice] = useState(false)
  

  

  const {isLoading,data,refetch} = useQuery(['customer','shipmentinfo',shipment_id],()=> getDataCustomer(`api/shipments/${shipment_id}`),{
    enabled:false,
    staleTime:2000,
    cacheTime:5000,
    onSuccess:(data)=>{
      setshipment(data?.data?.shipment)
    },
  })


  //Add Shipment Mutation
  const addShipment = useMutation((body)=> postDataCustomer(body,`api/shipments`),{
    onSuccess:(data)=>{
      let shipment = data.data.newshipment;
      if(shipment.price>0 && shipment.needsOffer===false){
        setshowSuccessModalAutoPrice(true)
      }else if(shipment.needsOffer===true){
        setshowSuccessModal(true)
      }
    }
})

  useEffect(() => {
    if(Boolean(repeat)===true){
      if(shipment_id){
        const response = queryClient.getQueryState(['customer','shipmentinfo',shipment_id])
        if(response?.data!=undefined){
          setshipment(response?.data?.data?.shipment)
        }else{
          refetch()
        }
      }
    }

  }, [repeat,shipment_id])

  return (
    <>
    <div
      style={{
        overflowY: "auto",
        height: "100vh",
        overflowX: "hidden",
        msOverflowStyle: "none",
      }}
    >
      <NavBar />
      <div className="container-fluid">
      <div className='container' style={{marginTop:'3em',paddingBottom:'30vh'}}>
        <Typography style={{marginBottom:'.5em'}} variant='h4'>{trans('newshipment.title')}</Typography>
        <Row>
        </Row>
        {shipmentOption==1 &&<SNDayDeliveryForm  calculatePrice={calculatePrice} mutation={addShipment}/>}
        {shipmentOption==2 && (Boolean(repeat)===true ?
        shipment!=null &&
         <NewShipmentForm 
          calculatePrice={calculatePrice}
          mutation={addShipment} 
          shipment={shipment} 
          repeat={repeat}
          showSuccessModal={showSuccessModal}
          setshowSuccessModal={setshowSuccessModal}
          showSuccessModalAutoPrice={showSuccessModalAutoPrice}
          setshowSuccessModalAutoPrice={setshowSuccessModalAutoPrice}
          zones={zones}
          autoPrice={autoPrice}
          schema={schema}
          centeredPriceContainer={true}
          defaultShipment={defaultShipment}
         />
        :<NewShipmentForm 
          shipment={{}} 
          mutation={addShipment} 
          repeat={repeat}
          calculatePrice={calculatePrice}
          showSuccessModal={showSuccessModal}
          setshowSuccessModal={setshowSuccessModal}
          showSuccessModalAutoPrice={showSuccessModalAutoPrice}
          setshowSuccessModalAutoPrice={setshowSuccessModalAutoPrice}
          zones={zones}
          autoPrice={autoPrice}
          schema={schema}
          centeredPriceContainer={true}
          defaultShipment={defaultShipment}
        />
        )}
      </div>
      </div>
    </div>
   
  </>
  )
}

export default NewShipment

const schema = yup.object().shape({
  customer_id: yup.string().required(),
  data:yup.array().of(
    yup.object().shape({
      pickup: yup.object().shape({
        location:yup.object({
        address:yup.string().required().label("Address"),
        coordinates:yup.array().min(2).max(2).required().label("Address"),
        postal_code:yup.string().required().min(4).label("Address"),
      })
  }),
  dropoff: yup.object().shape({
      location:yup.object({
          address:yup.string().required().label("Address"),
          coordinates:yup.array().min(2).max(2).required().label("Address"),
          postal_code:yup.string().required().min(4).label("Address")
      })
  }),
  shipment_attributes: yup.array().of(
      yup.object().shape({
          weight: yup.number().min(1).max(3000).required().label('Weight'),
          quantity: yup.number().min(1).max(100).required().label('Quantity'),
          height: yup.number().min(1).required().label('Height'),
          width: yup.number().min(1).required().label('Width'),
          length: yup.number().min(1).required().label('Length'),
          description: yup.string().required().label('Description'),
        })
),
      
    })),
 
})
  const defaultShipment={
    customer_id:getCustomerUserId()?.id,
    data:[{
      pickup:{
          date:getNextWorkDay(new Date()),
          time:'09:00 - 12:00',
          flexible:false
      },
      dropoff:{
          date:getNextWorkDay(new Date()),
          time:'09:00 - 12:00',
          flexible:false
      }, 
      shipment_attributes:[{
        length:120,
        width:80,
        height:100,
        quantity:1,
        weight:300,
        hydraulic_door:false,
        packing:packaging_options[0].value,
        dimensions:palets_types_with_labels[0].value
    }],
    dropoff_flexible:false,
    pickup_flexible:false,
    }],
   
   
}