import React, { Component } from "react";
import NavBar from "../../Common/NavBar";
import "../../../Styles/CustomerHome.css";
import ShipmentSection from "./ShipmentSection/ShipmentSection";
import { getCustomerUserId } from '../../../LocalStorage/Auth';
import useFetchData from './ShipmentSection/useFetchData';


function Shipments() {

  const [data,isLoading]= useFetchData(`api/shipments?customer_id=${getCustomerUserId().id}`,['customer','shipments'],'customer','data')



  return (
    <>
     <NavBar />
     <div className="container-fluid pt-5" style={{ minHeight: "90vh" }}>
         <div className="row">
             <ShipmentSection
             type='customer'
             isLoading={isLoading}
             shipments={data}
             initialFilters={`customer_id=${getCustomerUserId().id}&`}
             />
           </div>
     </div>
   </>
 );
}

export default Shipments

