import React from 'react'
import { trans } from '../../../globals'

export default function Titlerow() {
    return (
        <div className="container mt-5">
        <div className="row">
          <div className="col-3">
            <span className="numbercolor">{trans("common.labels.name")}</span>
          </div>
          <div className="col-3">
            <span className="numbercolor">{trans("common.labels.mobilenum")}</span>
          </div>
          <div className="col-4">
            <span className="numbercolor">{trans("common.labels.email")}</span>
          </div>
        </div>
      </div>
    )
}
