import { Button } from '@material-ui/core';
import { useFormik } from 'formik';
import React,{useEffect} from 'react'
import MyInput from './MyInput'
import { Typography } from '@material-ui/core';
import MySelect from './MySelect';
export default function Form({
title="Title",
structure=[],
submitButtonText='Submit',
formikParams={},
isLoading=false,
clearForm=false
}) {

    const formik = useFormik(formikParams);

    useEffect(() => console.log(formik.values),[formik.values])

  return (
    <div>
        <Typography variant='h4'>{title}</Typography>
        {clearForm && <Button size='sm' variant='contained' onClick={formik.resetForm}>Clear Form</Button>}
        <form onSubmit={formik.handleSubmit}>
        <div className='row'>

        {structure.map(s=>{
            if(s.type==="input"){
                return <div className={s.divClass}>
                <MyInput
                label={s.label}
                name={s.name}
                value={formik.values[s.name]}
                defaultValue={formik.initialValues[s.name]}
                error={formik.errors[s.name]}
                onChange={formik.handleChange}
                type={s?.datatype||"text"}
                />
               </div>
            }else if(s.type==="select"){
               return <div className={s.divClass}>
                   <MySelect
                options={s?.options||null}
                label1='customer_name'
                label2='customer_email'
                isFetching={s?.isFetching||false}
                getUrl={s?.getUrl||""}
                queryString={s?.queryString||""}
                name={s.name}
                label={s.label}
                onChange={formik.setFieldValue}
                />
             </div>
            }
    })}
     </div>
        
        <Button
        type='submit'
        variant="contained"
        color='primary'
        className='mt-3'
        >{isLoading?"Loading...":submitButtonText}</Button>
        
        </form>



    </div>

  )
}


/*
[
    {
        type:"input",
        label:"e"

    },

]


*/
