import React from 'react'
import ReactLoading  from 'react-loading';

export default function Loading() {
    return (
        <div className='d-flex  justify-content-center align-items-center' style={{width:'100vw',height:'100vh',backgroundColor:'white'}}>
            <ReactLoading type='spin' color='#2E5BFF' height='4em' width='4em'/> 
        </div>
    )
}
