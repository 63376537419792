import React,{useState,useEffect} from "react";
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import NavBarCarrier from "../../Common/NavbarCarrier";
import {trans,getDataCarrier} from '../../../globals'
import ChatRoom from '../../Chat/index'
import AddDriverTruck from "./DriverTruck/AddDriverTruck";
import ShipmentFullCard from "../../Common/Shipments/ShipmentFullCard/ShipmentFullCard";
import ShowDriverTruck from "./DriverTruck/ShowDriverTruck";
import Loading from "../../Common/Loading";
import ShipmentState from '../../CustomerSide/ShipmentInformation/InfoRepresantation/ShipmentState';


function ShipmentInformation() {

  const { id } = useParams();
  const [shipmentobj, setshipmentobj] = useState({})

  //get shipment by id
  const {isLoading,data} = useQuery(['carrier','shipmentinfo',id],()=> getDataCarrier(`api/shipments?_id=${id}`),{
    staleTime:2000,
    cacheTime:5000,
    onSuccess:(data)=>{
      setshipmentobj(data?.data[0])
      console.log(data.data)
    },
  })
  if(isLoading){
    return  <Loading/> 
  }else if(Object.keys(shipmentobj).length>0){
  return (
    
    <div
    style={{
      overflowY: "auto",
      height: "100vh",
      overflowX: "hidden",
      msOverflowStyle: "none",
    }}
  >
    <NavBarCarrier />
    <ChatRoom chat_room_id={shipmentobj?.chat_room_id} shipment_type={shipmentobj?.status} type='carrier' shipment_id={shipmentobj?._id} />
    <ShipmentFullCard hasTitle={true} marginBottomClass="" showPrice={true} shipment={shipmentobj} />
{/*-------if truck and driver already added------- */}
      <div className="container mb-4 pb-4 bg-white">
          {shipmentobj.selected_driver && shipmentobj.selected_truck ?   
          <ShowDriverTruck driver_id={shipmentobj.selected_driver} truck_id={shipmentobj.selected_truck} /> :   
          <AddDriverTruck shipment_id={shipmentobj._id} stops={shipmentobj.data.length}/>}
        
    </div>
            </div>
  )}else return null
}

export default ShipmentInformation
