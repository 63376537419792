import React,{useEffect} from 'react'
import {Row} from '@dimararist/woocargo.layout.row';
import {Column} from '@dimararist/woocargo.layout.column';
import Divider from '@material-ui/core/Divider'
import i18next from 'i18next'
import { Typography } from '@material-ui/core'

export default function ShipmentAttributes({shipment_attributes,hasTrans=true}) {

    const renderObjectOfAttributes = (obj,index=0,isArray=false) =>{
      return  <>

      <Row style={{flexWrap:'wrap'}}>
       <p  style={{marginRight:'1em'}}>{index+1}.</p>
       {Object.keys(transform_shipment_attributes(obj)).map((attribute,index)=>{
        let  key =attribute;
         attribute = hasTrans ?"shipmentinfo."+attribute.toLowerCase() : attribute
             return(
                <Column style={{paddingRight:15}}>
                <span className="black_text">{i18next.t(attribute)}</span>
                <span className="gray_text">{transform_shipment_attributes(obj)[key]}</span>
                </Column>
             )
         })}
      </Row>
      {isArray && <Divider style={{marginTop:'1em',marginBottom:'1em'}}/>}
      </>
    }
    return (
        <>
        <Typography variant='h5' color='primary'>{hasTrans ? i18next.t("shipmentinfo.shipment_attributes"):"Shipment Attributes"}</Typography>
       
          {(Array.isArray(shipment_attributes))?
            shipment_attributes.map((attr,index)=>renderObjectOfAttributes(attr,index+1,index<shipment_attributes.length-1 ?true:false))
            :typeof shipment_attributes === 'object' ?
            renderObjectOfAttributes(shipment_attributes)
            :null}
      
      </>
    )
}

 const transform_shipment_attributes = (object) => {
  let my_obj = {};

  attributePriority.map(attribute=>{
    let name = attribute;
    let value = object[attribute];
    if (attribute == 'hydraulic_door') name = 'Hydraulic Doors';
    if(attribute=='dimensions'){
      value = (object['width'] && object['height'] && object['length'])? `${object['length']}*${object['width']}*${object['height']}` :""
    }
    if (object[attribute] === true) value = i18next.t('yes');
    if (object[attribute] === false) value = i18next.t('no'); 
    my_obj[first_capital(name)] = value;
  })
  return my_obj;
};
 const first_capital = (word) => {
  word = word.toLowerCase();
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const attributePriority =['quantity','packing','weight','dimensions','hydraulic_door']