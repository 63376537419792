import {addDriver,DeleteDriverService,EditDriverService} from "../../Services/Carriers/driver_services";
import SimpleCustomerOffer from "../../Services/Shipments/SimpleCustomerOffer";
import { get_customer_admin_chatService } from "../../Services/Chat/getchathistory";



//------------------------------ADD DRIVER----------------------
export const AddDriverCarrierSide = (
  requestbody,
  config,
  AddDriverSucess,
  AddDriverFailure
) => {
  return (dispatch) => {
    addDriver(requestbody, config, dispatch, AddDriverSucess, AddDriverFailure);
  };
};

//-------------------------ACCEPT CUSTOMER OFFER(SIMPLE)----------------------
export const AcceptSimpleCustomerOffer = (
  requestbody,
  config,
  shipment,
  callback,
) => {
  return (dispatch) => {
    SimpleCustomerOffer(
      requestbody,
      config,
      shipment,
      dispatch,
      callback,
   
    );
  };
};


export const AddTruckDriverToSplitShipment = (
  requestbody,
  config,
  shipment,
  AddSucess,
  AddFailure
) => {
  return (dispatch) => {
   
  };
};





//----------------------EDIT DRIVER RECORD----------
export const EditDriver = (
  requestBody,
  config,
  driverId,
  editDeleteSuccess,
  editDeleteFails
) => {
  return (dispatch) => {
    EditDriverService(
      requestBody,
      config,
      dispatch,
      driverId,
      editDeleteSuccess,
      editDeleteFails
    );
  };
};
//----------------------DELETE DRIVER RECORD----------
export const DeleteDriver = (
  config,
  driverId,
  editDeleteSuccess,
  editDeleteFails
) => {
  return (dispatch) => {
    DeleteDriverService(
      config,
      dispatch,
      driverId,
      editDeleteSuccess,
      editDeleteFails
    );
  };
};

//---------------------GET CUSTOMER CHAT TO ADMIN ----------
export const get_customer_admin_chat = (
  id,
  callback,
) => {
  return (dispatch) => {
    get_customer_admin_chatService(
      dispatch,
      id,
      callback,
    );
  };
};

