import React from 'react'
import LabelWithText from '../../Text/LabelWithText/LabelWithText';
import { Typography } from '@mui/material';
import CarrierCard from '../CarrierCard/CarrierCard';
import { notEmptyArray } from '../../../../globals';
import dateformat from 'dateformat'
import { Row } from '@dimararist/woocargo.layout.row';
import { Button } from '@material-ui/core';
import { useFormik } from 'formik';

export default function OfferCard({offer,acceptOfferMutation}) {

 // Form
 const formik = useFormik({
    initialValues:{offerid:offer._id},
    onSubmit:(body)=>{
        //alert(JSON.stringify(body))
        acceptOfferMutation.mutate(body)
    }
})
const approvalSubmit = (approval) =>{
    formik.setFieldValue('approval',approval)
    formik.handleSubmit()
}

  return (
      <div className='p-3 rounded mb-2 ' style={{ backgroundColor:`${offer.approved?"rgba(0,255,0,.2)":"rgba(200,0,0,.1)"}`}}>
          <Row style={{justifyContent:"space-between"}}>
            <div>
                <LabelWithText label={'Παραλαβή'} text={`${dateformat(offer.date_pickup,'d-m-yyyy')} ${offer.time_pickup}`}/>
                <LabelWithText label={'Παράδοση'} text={`${dateformat(offer.date_dropoff,'d-m-yyyy')} ${offer.time_dropoff}`}/>
            </div>
          <Typography color='secondary' variant='h4'>{offer.cost} $</Typography>
          </Row>

          <Typography color='black' variant='h5'>Carrier</Typography>
          <CarrierCard carrier={notEmptyArray(offer.carrier_populated)?offer.carrier_populated[0]:{}}/>
          <form>
            {offer.approved ? 
              <Button variant='contained' onClick={()=>approvalSubmit(false)} style={{backgroundColor:'red',color:'white'}}>Decline Offer</Button>
            : <Button variant='contained' onClick={()=>approvalSubmit(true)} style={{backgroundColor:'#1abc9c',color:'white'}}>Accept Offer</Button>
            }
          </form>

      </div>
  )
}
