import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootreducer from "./Redux/Reducers/rootreducer";
import './locale/i18n.js'
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import {ThemeProvider} from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-148409589-1"; 

ReactGA.initialize(TRACKING_ID,
  {
  debug: true,
  titleCase: false,
  gaOptions: {
    userId: 123
  }});
 


const queryClient = new QueryClient()
export const storee = createStore(
  rootreducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const theme = createTheme({
    palette:{
        primary:{
            main:'#2E5BFF',
            
        },
        secondary:{
          main:'#1abc9c'
        },
        mygray:{
          main:"#8798AD"
        },
        error: {
          main: '#fff',
        },
    }
})


ReactDOM.render(
  <div className="app-container" style={{height:'100vh'}}>
    <ReactNotification />
    <Provider store={storee}>
    <QueryClientProvider client={queryClient}>
      <ReactNotification />
      <ThemeProvider theme={theme}>
      <App />
      </ThemeProvider>
    </QueryClientProvider>
    </Provider>
  </div>,

  document.getElementById("root")
);
