import { GET_CARRIER_NOTIFICATIONS_START, GET_CARRIER_NOTIFICATIONS_FAIL, GET_CARRIER_NOTIFICATIONS_SUCCESS } from "../Actions/actions";

const initialstate = {
  customer_notification: null,
  carrier_notification: null,
  loading:false,
  error_message:''
};

const NotificationReducer = (state = initialstate, action) => {
  if (action.type=== 'CUSTOMER_NOTIFICATION_START') return{ ...state,loading:true}
  if (action.type=== 'CUSTOMER_NOTIFICATION_FAIL') return { ...state,loading:false,error_message:action.error_message}
  if (action.type === "CUSTOMER_NOTIFICATION_SUCCESS") {
    return { ...state,loading:false,customer_notification:[...action.payload.data.Notifications], };
  }

  if (action.type=== 'CARRIER_NOTIFICATION_START') return{ ...state,loading:true}
  if (action.type=== 'CARRIER_NOTIFICATION_FAIL') return { ...state,loading:false,error_message:action.error_message}
  if (action.type === "CARRIER_NOTIFICATION_SUCCESS") {
    return {
      ...state,
      loading:false,
      carrier_notification: [...action.payload.data.notifications],
    };
  }
if (action.type=== GET_CARRIER_NOTIFICATIONS_START) return { ...state,loading:true}
if (action.type=== GET_CARRIER_NOTIFICATIONS_FAIL) return { ...state,loading:false,error_message:action.error_message}
if (action.type=== GET_CARRIER_NOTIFICATIONS_SUCCESS) return {...state,carrier_notification:action.payload,loading:false}
  return state;
};

export default NotificationReducer;
