import axios from "axios";
import { api_callback, URL } from "../../globals";

export const send_offer_async = (requestbody, config,callback) => {
  return dispatch =>{
  axios
    .post(
      `${URL}api/carriers/${requestbody.carrier}/shipments/${requestbody.shipment_id}`,
      requestbody,
      config
    )
    .then(response=> {
      console.log(response)
      //dispatch(CarrierOfferSendAction(response.data));
      //callback function to change routes or etc
      api_callback(true,"Επιτυχία","Προσθέσατε μια νέα προσφορά")
      callback(true);
      
    })
    .catch(function (error) {
      //offerSendFailure();
      if (error.response) {
       api_callback(false,"Αποτυχία","Αποτυχία") 
       callback(false)
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.log("Error", error.message);

        console.log("error 1");
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    });
};
}