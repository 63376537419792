import { COUNT_UNREAD_MESSAGES, GET_CHATROOM_BY_ID_FAIL, GET_CHATROOM_BY_ID_START, GET_CHATROOM_BY_ID_SUCCESS, SET_MESSAGES_READ, UPDATE_CHAT } from "../Actions/actions";

const initialstate = {
  chat_rooms:[],
  loading:false,
  unread_messages:{},
  error_message:'',

};

const chat_reducer = (state = initialstate, action) => {

  if(action.type===SET_MESSAGES_READ){
    let unread_messages= state.unread_messages;
    unread_messages[action.id]=0
    let chat_rooms = state.chat_rooms;
    let index = chat_rooms.findIndex(c=>c._id==action.id)
    if(index!=-1){
      chat_rooms[index] = action.chat_room
    }
    return {...state,...unread_messages,chat_rooms:chat_rooms[0] };
  }
  if (action.type=== UPDATE_CHAT) {
    if(state.chat_rooms.length>0){
    let chat_rooms= state.chat_rooms.map(c=> {
      if(c.shipment_id===action.payload.shipment_id){
        c.chat.push(action.payload)
      }
      return c
    })
    return {...state,chat_rooms}
  }else{return state}
  }

  if (action.type=== GET_CHATROOM_BY_ID_START) return{ ...state,loading:true}
  if (action.type=== GET_CHATROOM_BY_ID_FAIL) return { ...state,loading:false,error_message:action.error_message}
  if (action.type=== GET_CHATROOM_BY_ID_SUCCESS){
    const id =action.payload._id;
    let chat_rooms = state.chat_rooms;
    chat_rooms.push(action.payload)
    return {...state,loading:false,chat_rooms}
  }
  if(action.type===COUNT_UNREAD_MESSAGES){
    let chat_rooms = state.chat_rooms;
    const id = action.id
    const type=action.mytype
    let count=0;
    let chat_room;
    let index = chat_rooms.findIndex(c=>c._id==id)
    let unread_messages = state.unread_messages;
    if(index!=-1){
      chat_room = chat_rooms[index]
     let chat = chat_room.chat
     chat.map(c=>c.to==type?c.isRead==false?count+=1:null:null)
     unread_messages[id] =count
    }
    return {...state,...unread_messages}
  } 
    return state;
  }

export default chat_reducer;
