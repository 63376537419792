import React from 'react'
import { Row } from '@dimararist/woocargo.layout.row';
import { Button } from '@mui/material';
import { putDataAdmin, deleteDataAdmin, api_callback } from '../../../globals';
import { useQueryClient,useMutation } from 'react-query';
import EditRecord from './EditRecord';

export default function ActionButtons({record,editUrl,deleteUrl,queryString,_id,formStructure}) {

    const queryClient = useQueryClient();
  
    //Delete Mutation
    const deleteMutation = useMutation(()=>deleteDataAdmin({},deleteUrl),{
        onSuccess: (data)=>{
            queryClient.invalidateQueries(queryString)
            api_callback(true,"Success","Διαγράφτηκε με επιτυχία")
        }
      })


  return (
      <Row>
          <EditRecord queryString={queryString} record={record} _id={_id} formStructure={formStructure} editUrl='api/autoprice'/>
          <div>
          <Button  onDoubleClick={()=>deleteMutation.mutate()} sx={{backgroundColor:'red',color:'red'}}>Delete</Button>
          </div>
      </Row>
  )
}
