import React,{useEffect,useState,useMemo} from 'react'
import LocationTime from './LocationTime'
import {useFormik} from 'formik'
import { Button,Typography } from '@material-ui/core'
import Dimensions from './Dimensions'
import { kibvtia_types_with_labels, trans, palets_types_with_labels, notEmptyArray, throttle, MINIMUM_SHIPMENT_PRICE, STOP_PRICE, STOP_PRICE_A_TO_A, getDataCustomer, api_url, get_config, URL } from '../../../../globals';
import { getCustomerUserId } from '../../../../LocalStorage/Auth';
import StickyContainer from '../../../Common/StickyContainer/StickyContainer';
import Row from '../../../Common/Layout/Row';
import SuccessFailMessage from '../SuccessFailMessage/SuccessFailMessage'
import NewUserForm from '../../GuestShipment/NewUserForm';
import MySelect from '../../../Common/Forms/MySelect'
import StopsFirstRow from './StopsFirstRow';
import axios from 'axios'
import  CircularProgress  from '@material-ui/core/CircularProgress';
import { debounce } from "lodash";
import { useDebouncedEffect } from '../../../../helpers/hooks/useDebouncedEffect';
function NewShipmentForm({
    repeat,
    shipment,
    mutation,
    calculatePrice, 
    showSuccessModal,
    setshowSuccessModal,
    showSuccessModalAutoPrice,
    setshowSuccessModalAutoPrice,
    zones,
    autoPrice,
    extraForm,
    schema,
    centeredPriceContainer,
    userRegistration=false,
    defaultShipment,
    admin=false

}) {
    const [packingOptions, setpackingOptions] = useState([palets_types_with_labels])
    const [price, setprice] = useState(-2)
    const [stops, setStops] = useState([0])
    const [shipmentBody, setshipmentBody] = useState(false)
    const [loading, setLoading] = useState(false)
   

    const formik = useFormik({
        
        enableReinitialize:true,
        validationOnChange:true,
        validateOnBlur:false,
        validationSchema:schema,
        initialValues:repeat?{...keep(shipment),...defaultShipmentFromShipment(shipment)}:defaultShipment,
        onSubmit: (body) =>{            

            if(getCustomerUserId()?.id){
            
                let mybody = {...body}
                if(body.price==-2 || body.price==undefined){
                    mybody.price=null
                    mybody.needsOffer=true
                    mybody.price=null
                }else if(body.price>0){
                    mybody.needsOffer=false
                }else if(body.price<=0){
                    mybody.price=null
                    mybody.needsOffer=true 
                }
                
                mybody.customer_id = getCustomerUserId()?.id
                mutation.mutate(mybody)
        }else{
            alert("Please try again")
        }
        }
    })
    const handleChange =(e)=>{
        if(formik.values.customer_id==null ||formik.values.customer_id==undefined ){
            console.log(getCustomerUserId().id)
            formik.setFieldValue('customer_id',"",true) 
        }
        //console.log(e.target.name,e.target.value)
        formik.setFieldValue(e.target.name,e.target.value,true)

            
        if(e.target.name.includes('shipment_attributes[0].dimensions')){
            if(!String(e.target.value).toLowerCase().includes('custom')){
                if(Array.isArray(formik.values.data)){
                    formik.values.data.map((v,index)=>{
                        splitSetDimensions(e.target.value,index)
                    })
                }
               
            }
        }
      
    }

    

 

  const splitSetDimensions =(value,index)=>{
    const  {length,width,height} =splitDimensions(value)
    formik.setFieldValue(`data[${index}].shipment_attributes[0].length`,length)
    formik.setFieldValue(`data[${index}].shipment_attributes[0].width`,width)
    formik.setFieldValue(`data[${index}].shipment_attributes[0].height`,height)
    }

    const calculatePackingOptions =(value,index)=>{
        //its sets the dimensions value based on your packing selection
        if(value=='Παλέτες'|| value=='Παλέτες - Ψυγείο'){
            let temp =[...packingOptions]
            temp[index]=palets_types_with_labels
            setpackingOptions(temp)
            splitSetDimensions(palets_types_with_labels[0].label,index) 
            formik.setFieldValue(`data[${index}].shipment_attributes[0].dimensions`,palets_types_with_labels[0].label)
            formik.setFieldValue(`data[${index}].shipment_attributes[0].weight`,300)
            } else if(value=='Κιβώτια'){
            let temp =[...packingOptions]
            temp[index]=kibvtia_types_with_labels
            setpackingOptions(temp)
            splitSetDimensions(kibvtia_types_with_labels[0].label,index) 
            formik.setFieldValue(`data[${index}].shipment_attributes[0].dimensions`,kibvtia_types_with_labels[0].label)
            formik.setFieldValue(`data[${index}].shipment_attributes[0].weight`,15)
           }else if(value=='Χωρίς Συσκευασία'){
            formik.setFieldValue(`data[${index}].shipment_attributes[0].dimensions`,'')
            let temp =[...packingOptions]
            temp[index]=[]
            setpackingOptions(temp)
        }
    }
    const handleFieldValue =(name,value)=>{
        
        if(name.includes('shipment_attributes[0].packing')){
            const found = name.indexOf("data[") + "data[".length
             calculatePackingOptions(value,parseInt(name[found]))
            
        }
        
        formik.setFieldValue(name,value)
    }
    const splitDimensions =(dim)=>{
        let dim_trimmed = dim.toString().trim().split(/\s/).join('');
          let word_split = dim_trimmed.substring(dim_trimmed.indexOf('-') + 1);
          let dimensions = word_split.split('*');
          let length = parseInt(dimensions[0]);
          let width = parseInt(dimensions[1]);
          let height = parseInt(dimensions[2]);
          return { length,width,height}
    }

   
   
    useEffect(()=>{
        setLoading(true)
},[formik.values])
   

    useDebouncedEffect(async () =>{
        
        //setLoading(true)

            console.log(JSON.stringify(formik.values.data))
           
            
            setLoading(true)
            await axios.get(`${URL}api/shipments/calculatePrice/${JSON.stringify(formik.values)}`,get_config())
            .then(response=>{
                setLoading(false)
                if(response.data){
                    if(response.data.price){
                        console.log("PRICE :",response.data.price)
                        setprice(response.data.price)
                    }
                }
            }).catch(err=>{
                setLoading(false)
                setprice(-1)
                console.log(err)
            })
    }, [formik.values], 1500);

    const addStop =()=>{
        let temp = [...packingOptions]
        temp.push(temp.length>0?temp[0]:palets_types_with_labels)
        setpackingOptions(temp)
        temp = [...formik.values.data]
        const sa = (notEmptyArray(temp) && notEmptyArray(temp[0].shipment_attributes)) ? temp[0].shipment_attributes[0]:{}
        temp.push({shipment_attributes:[{...sa}],pickup:temp[0].pickup,dropoff:temp[0].dropoff})
        formik.setFieldValue("data",temp)
    }
    const deleteStop = (index)=>{
        let temp = [...packingOptions]
        temp.splice(index,1)
        setpackingOptions(temp)
        temp = [...stops]
        temp.splice(index, 1);
        let data = [...formik.values.data]
        data.splice(index,1)
        formik.setFieldValue("data",data)
        setStops(temp)
        
    }
    useEffect(()=>{
        console.log("Errors",formik.errors) 

    },[formik.errors])
   
    

    useEffect(() => {
        //console.log("Packing Options",packingOptions)
        }, [packingOptions]) 
    
    
    useEffect(() => {
    if(formik.values.data[0].pickup.location!=null){    
     handleFieldValue('price',price)
    }
    }, [price])

    
    return (
    <>
        
        {formik.values.data.map((value,index)=>{
            
            return <div className='bg-white'>
                <StopsFirstRow deleteStop={deleteStop} index={index}/>
                { 
                 <LocationTime 
                formik={formik} 
                handleChange={handleChange}
                handleFieldValue={handleFieldValue} 
                values={value} 
                shipment={shipment}
                index={index}
                initialValues={notEmptyArray(formik.initialValues.data,index)?formik.initialValues.data[index]:{}}
                />
                }
                <Dimensions 
                handleChange={handleChange}
                handleFieldValue={handleFieldValue}
                formik={formik} 
                shipment={shipment} 
                packingOptions={notEmptyArray(packingOptions,index) ?packingOptions[index]:[]}
                index={index}
                value={value}
                />
            </div>
        })}
       

       
        {admin && 
        <div className='bg-white pl-3'>
            <MySelect
            isFetching={true}
            queryString='customers'
            options={null}
            getUrl={'api/crud/customers'}
            label1={'customer_name'}
            label2={'customer_email'}
            onChange={formik.setFieldValue}
            label='Customer'
            name='customer_id'
            error={formik.errors.customer_id}
            />
        </div>
        }
         <div className={`shadow-sm p-4 pb-5 bg-white`}>
         <Button onClick={addStop} variant='contained' color='primary'>Προσθηκη Στασης</Button>
        </div>
        {userRegistration && <NewUserForm formik={formik}/>}
        
        <StickyContainer centered={centeredPriceContainer}>
        <Row>
        <Typography variant='h4' color='primary'>{trans('shipmentinfo.price')}:</Typography>
        {!formik.isValid ? <Typography className='ml-1 mt-1' align='center' variant='h6'>Συμπληρώστε τα κένα για εμφάνιση της τιμής</Typography> :loading?<CircularProgress className='ml-2 mt-1 bold' size='1.6rem'/>: price>0?
        <Typography className=' ml-1' color='primary' variant='h4'>{price} €</Typography>
        :
        <Typography className='ml-1 mt-1' align='center' variant='h6'> κατόπιν προσφοράς</Typography>}
        </Row>
            <Button 
            variant='contained' 
            disabled={(loading || !formik.isValid)}
            color='primary' 
            style={{float:'right'}}
            onClick={()=>formik.submitForm()}>{mutation.isLoading?"Loading...":"Καταχωρηση"}</Button>

        </StickyContainer>
        <SuccessFailMessage 
        showSuccessModalAutoPrice={showSuccessModalAutoPrice} 
        setshowSuccessModalAutoPrice={setshowSuccessModalAutoPrice} 
        showSuccessModal={showSuccessModal} 
        setShowSuccessModal={setshowSuccessModal} 
        />
    </>
    )
}

export default NewShipmentForm





const defaultShipmentFromShipment = (shipment)=>{
return {
    pickup_flexible:shipment?.pickup_date!=null?false:true,
    dropoff_flexible:shipment?.dropoff_date!=null?false:true,
}
}



const attributesToKeep=['shipment_attributes','pickup_time','pickup_date','pickup_location','dropoff_time','dropoff_location','dropoff_date','shipment_description','customer_id','shipment_notes']
const keep =(obj)=>{
    Object.keys(obj).map(key =>{
        if(!attributesToKeep.includes(key)){
            delete obj[key]
        }
    })
    return obj;
}