import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import AddDriverModal from "./AddDriverModal";
import "../../../Styles/CarrierDashboard.css";
import { trans } from "../../../globals";
export default class DriverCard extends Component {
  state = {
    modal: false,
  };
  handleModal = () => {
    this.setState({ modal: !this.state.modal });
  };
  render() {
    console.log("driver card", this.props.list);
    const { driver_name, driver_email, driver_mobilenum } = this.props.list;
    return (
      <div
        className="container p-4 mt-3 shadow-sm rounded-lg"
        style={{ background: "#ffffff" }}
      >
        <div className="row">
          <div className="col-3">
            <span
              className="d-inline-block ml-2 black_text">
              {driver_name}
            </span>
          </div>
          <div className="col-3">
            <span className="black_text" >
              {driver_mobilenum}
            </span>
          </div>
          <div className="col-4">
            <span className="black_text" >
              {driver_email}
            </span>
          </div>
          <div className="col-2">
          <button className="nobg_btn_purple" style={{ cursor: "pointer" }}onClick={this.handleModal}>
             {trans("common.buttons.open")} 
            </button>
          </div>
          {/*---------MODAL FOR EDIT OR DELETE RECORD-------- */}
          <Modal
            size="lg"
            show={this.state.modal}
            onHide={this.handleModal}
            backdrop="static"
            animation="true"
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <AddDriverModal
              handleModal={this.handleModal}
              title="EditOrDeleteDriver"
              data={this.props.list}
            />
          </Modal>
        </div>
      </div>
    );
  }
}
// DriverCard.defaultProps = {
//   list: {
//     driver_name: "",
//     driver_email: "",
//     driver_mobilenum: ""
//   }
// };
