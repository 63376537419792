import { Component } from "react";
import { getCarrier, getCarrierUserId } from "../../../LocalStorage/Auth";

export default class VehicalApiCall extends Component {
  //----------------Request to local storage after refreshing the page-------------
  requestToLocalStorage = () => {
    const token = getCarrier();
    const  _id  = getCarrierUserId();
    const requestBody = {
      carrierid: _id,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    this.props.getAllVehicals(
      requestBody,
      this.get_vehiclesSucess,
    );
  };
  get_vehiclesSucess = () => {
    console.log("Vehical fetch success");
    const record = this.props.carrier_AllVehicals;
    this.setState({ record, loading: false });
  };
  get_vehiclesFailure = () => {
    console.log("Vehical fetch Fail");
   // this.setState({loading:false})
  };
}
