import React ,{useState}from 'react'
import {Divider, Typography } from '@material-ui/core';
import { Row } from '@dimararist/woocargo.layout.row';
import { useQuery, useQueryClient } from 'react-query';
import { getDataCustomer } from '../../../../globals';

function CarrierCard({carrier=null,carrier_id=null}) {

const queryClient = useQueryClient()

   const [fetchedCarrier,setFetchedCarrier] = useState(null);
    //Get Carrier By Id 
  const {data, isLoading,refetch} = useQuery(['carrier',carrier_id],()=>getDataCustomer('api/crud/carriers?_id='+carrier_id),{
    initialData: () => {
     return queryClient.getQueryData(['carrier',carrier_id])
      },
      enabled:carrier_id?true:false,
      onSuccess :(data)=>{
          setFetchedCarrier(data.data[0])
        }
  })

    return (
        <div className='mt-3'>
         <Row>
            <Typography className='mr-3' color='primary' variant='p' >Όνομα :</Typography>
            <Typography color='gray.500' variant='p' >{carrier ? carrier?.carrier_name: fetchedCarrier && fetchedCarrier.carrier_name}</Typography>
         </Row>
         <Row>
            <Typography className='mr-3'  color='primary' variant='p' >Email :</Typography>
            <Typography color='gray.500' variant='p' >{carrier ? carrier?.carrier_email:fetchedCarrier && fetchedCarrier.carrier_email}</Typography>
         </Row>
         <Row>
            <Typography className='mr-3'  color='primary' variant='p' >Τηλέφωνο:</Typography>
            <Typography color='gray.500' variant='p' >{carrier ? carrier?.carrier_phone:fetchedCarrier && fetchedCarrier.carrier_phone}</Typography>
         </Row>
         <Row>
            <Typography className='mr-3'  color='primary' variant='p' >Διέυθυνση:</Typography>
            <Typography color='gray.500' variant='p' >{carrier ? carrier?.carrier_address:fetchedCarrier && fetchedCarrier.carrier_address}</Typography>
         </Row>
    </div>     
    
    )
}

export default CarrierCard


