import React from "react";
import AddButton from "../../Common/AddButtonCarr";
import Empty from "../../Common/EmptyCarrier";
import Modal from "react-bootstrap/Modal";
import DriverCard from "./DriverCard";
import NavBarCarrier from "../../Common/NavbarCarrier";
import DriveApiCall from "./DriveApiCall";
import AddDriverModal from "./AddDriverModal";
import { connect } from "react-redux";
import { GetDriversCarrierSide } from "../../../Redux/Actions/actioncreators";
import Titlerow from "./Titlerow";
import { trans } from "../../../globals";
import { GetDrivers } from "../../../Services/Carriers/driver_services";
import Loading from "../../Common/Loading";

class DriverDashboard extends DriveApiCall {
  state = {
    record: [],
    loading: true,
    modal: false,
  };
  componentDidMount() {
    if (this.props.carrier_AllDrivers.length > 0) {
      return this.setState({
        record: this.props.carrier_AllDrivers,
        loading: false,
      });
    }
    this.requestToLocalStorage();
  }
  handleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    console.log("modal", this.state.modal);
    const { loading } = this.state;
    const count = this.state.record.length;
    if (loading) {
      return <Loading/> 

    } else if (count === 0) {
      return <Empty isDriver={true} title={trans('drivers.title')} />;
    } else {
      return (
        <React.Fragment>
          <NavBarCarrier />
          <div
            style={{
              height: "90vh",
              overflowY: "auto",
              overflowX: "none",
              msOverflowStyle: "none",
            }}
          >
            <div className="container pt-5">
              <div className="row">
                <div className="col-md-10">
                  <span className="vd-title">{trans("drivers.title")}</span>
                  <span className="vd-total ml-3"> {trans("common.buttons.total")} </span>
                 <div>
                  <Titlerow/> 
                    <div
                      style={{
                        height: "70vh",
                        overflowX: "none",
                        overflowY: "auto",
                        msOverflowStyle: "none",
                      }}
                    >
                      {this.state.record.map((item) => {
                        return <DriverCard key={item._id} list={item} />;
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div onClick={this.handleModal}>
                    <AddButton />
                  </div>
                  <Modal
                    size="lg"
                    show={this.state.modal}
                    onHide={this.handleModal}
                    backdrop="static"
                    animation="true"
                    aria-labelledby="example-modal-sizes-title-lg"
                  >
                    <AddDriverModal
                      handleModal={this.handleModal}
                      title="AddDriver"
                    />
                  </Modal>
                </div>
                </div>
              </div>
            </div>
        </React.Fragment>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    carrier_AllDrivers: state.driverReducer.Drivers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllDrivers: (requestBody, config, GetDriversSucess, GetDriversFailure) =>
      dispatch(
        GetDrivers(
          requestBody,
          config,
          GetDriversSucess,
          GetDriversFailure
        )
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DriverDashboard);
