import { Typography } from '@material-ui/core';
import React,{useEffect} from 'react'
import {useQuery,useQueryClient,useMutation} from 'react-query'
import OfferCard from '../../../../Components/Common/Cards/OfferCard/OfferCard';
import DrawerForm from '../../../../Components/Common/Forms/DrawerForm/DrawerForm';
import Loading from '../../../../Components/Common/Loading';
import { api_callback, getDataAdmin, postDataAdmin, notEmptyArray } from '../../../../globals';

export default function Offers({isOpen,setOpen,shipment_id}) {

  const queryClient = useQueryClient()
  const {data:offers, isLoading} = useQuery(['offersbyshipment',shipment_id],()=>getDataAdmin(`api/crud/offers?shipment_id=${shipment_id}`),{
    onSuccess: (da)=>console.log(da),
    initialData: () => {
     return queryClient.getQueryData(['offersbyshipment',shipment_id])
      }
  })
   //Accept Offer 
   const acceptOffer= useMutation((body)=>postDataAdmin(body,`api/admin/shipments/${shipment_id}/offers`),{
    onSuccess: (data)=>{
       api_callback(true,"Success","Αποδετήκατε μια πρσοφορά")
      queryClient.invalidateQueries(['offersbyshipment',shipment_id])
    }
  })



    return (
       <DrawerForm isOpen={isOpen} setOpen={setOpen}>
         <div>

          <Typography variant='h4'>Offers</Typography>
          <hr></hr>
            {isLoading? <Loading/>:
              (notEmptyArray(offers?.data)?
              offers?.data.map(offer=><OfferCard acceptOfferMutation={acceptOffer} offer={offer}/>) 
              :<p>No Offers</p>)
              
            }
         </div>
       </DrawerForm> 
    )
}



