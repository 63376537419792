import i18n from "i18next";
import { withTranslation,initReactI18next} from "react-i18next";
import translationEN from './en/translation.json';
import translationGR from './gr/translation.json';
import detector from "i18next-browser-languagedetector";
import backend from "i18next-xhr-backend";
// the translations
const resources = {
  gr: {
    translation: translationGR
  },
  en:{
    translation:translationEN
  }

};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(detector)
  .use(backend)
  .init({
    resources,
    keySeparator: '.',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    fallbackLng: 'gr',
    debug: false,

    
  });

export default i18n;