import axios from 'axios';
import { get_chatroom_by_id_fail, get_chatroom_by_id_start, get_chatroom_by_id_success ,set_messages_read} from '../../Redux/Actions/actions';
import {api_callback, URL} from '../../globals'
import {store} from '../../index'
 
export const get_chatroom_by_id_async=(chat_room_id)=>{
  return (dispatch)=>{
    dispatch(get_chatroom_by_id_start())
    axios
    .get(`${URL}api/chat/${chat_room_id}`)
    .then((response)=> {
      dispatch(get_chatroom_by_id_success(response.data.chat_room));
      console.log(response);
    })
    .catch(function(error) {
      if (error.response) {
         dispatch(get_chatroom_by_id_fail(error.response.data.error_message))
         console.log(error.response.data);
         console.log(error.response.status);
         console.log(error.response.headers);
         console.log("Error", error.message);
         console.log("error 1");
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
         console.log(error.config);
      });
      };;
    }
  export const set_messages_read_async =(id,to)=>{
    return dispatch =>{
    axios.put(`${URL}api/chat/messages`,{id,to})
    .then((response)=> {
      console.log(response)
      dispatch(set_messages_read(id,response.data.chat_room))
    }).catch(function(error) {
      if (error.response) {
         console.log(error.response.data);
         console.log(error.response.status);
         console.log(error.response.headers);
         console.log("Error", error.message);
         console.log("error 1");
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
         console.log(error.config);
      });
  }  
}
  
  

  export const handleReadMessages= async (messages,isChecking,type,chat_room_id)=>{
    return  dispatch =>{
      let count=-1;
      let unread_messages=[]
          if(messages){
              messages.forEach(m=>{
                if(m.to==type && m.isRead==false){
                  unread_messages.push(m._id)
                }
              })
            console.log("MESSAGES",unread_messages)
            if(isChecking) {
              // count= unread_messages.length
               //dispatch(set_messages_read(chat_room_id,type))
              }
              else{
              if(unread_messages.length>0){
              
              }}
          }else{
          count=-1;
          }
          console.log(count)
          return count;
    }
    
    }
  