import React from 'react'
import Row from '../../../Common/Layout/Row';
import { Typography, IconButton } from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';

function StopsFirstRow({index,deleteStop}) {
    return (
        <>
         {index>0 && <hr/>}
        <Row style={{justifyContent:'space-between'}}>
             <Typography className='pl-4 pt-3' variant='h5'>Στάση {index+1}</Typography>
             {index>0 && 
             <IconButton  aria-label="cart" style={{marginRight:'1em'}} onClick={()=>deleteStop(index)}>
                    <ClearIcon fontSize='large' style={{color:'red'}}/>
             </IconButton>} 
        </Row>
        </>
    )
}

export default StopsFirstRow
