import React from 'react'
import  SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import  Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    paper: {
      minWidth:'20vw',
      padding:'1em'
    }
  });

export default function DrawerForm({isOpen,setOpen,title,children}) {
    const styles = useStyles();
    return (
      <SwipeableDrawer
      anchor='right'
      variant='temporary'
      open={isOpen}
      onClose={()=>setOpen(false)}
      onOpen={()=>setOpen(true)}
      classes={{ paper: styles.paper }}
    >
     <Typography variant='h5' color='primary'>{title}</Typography>
        
        {children}
    </SwipeableDrawer>
    )
}
