import React,{useState} from "react";
import OfferModule from "./OfferModule";
import EmptyOffer from "./EmptyOffer";
import { withRouter } from "react-router-dom";
import "../../../Styles/DetailCusShip.css";
import BoxContainer from "../ShipmentInformation/BoxContainer";

const OfferInformation = ({offers,shipment_obj,setshowOfferSuccessModal}) => {

  if (Array.isArray(offers)) {
    if (offers.length === 0) return <EmptyOffer shipment_status={shipment_obj.status}/>;
  }
  return (
    <BoxContainer title='shipmentinfo.offers.title'>

      {Array.isArray(offers) ? offers.map((item, index) => {
            return <OfferModule 
            setshowOfferSuccessModal={setshowOfferSuccessModal}  
            key={index} 
            shipment={shipment_obj}
            offer={item} />;
          })
        : ""}
    </BoxContainer>
  );
};

export default withRouter(OfferInformation);
