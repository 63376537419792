import React from 'react'
import logo from '../../../Pics/logo_with_text.svg'

export default function NavBarLogo({children}) {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container">
            <span className="navbar-brand">
              <div className="d-flex align-item-center">
                <button style={{background:'transparent',borderWidth:0,cursor:'pointer'}} onClick={()=>this.props.history.push('/Shipments')}>
                <img src={logo} alt="logo" />
                </button>
              </div>
            </span>
            {children}
           </div>
          </nav>
    )
}
