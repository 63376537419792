import React,{useState} from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Divider, Typography } from '@material-ui/core';
import Price from '../../CustomerSide/ShipmentInformation/InfoRepresantation/Price';
import { getCarrierUserId } from '../../../LocalStorage/Auth';
import {useMutation} from 'react-query'
import { api_callback, putDataCarrier } from '../../../globals';
import SuccessFeedbackModal from '../../Common/Modals/SuccessFeeback/SuccessFeedbackModal';
import { useHistory } from 'react-router-dom';
import { useQueryClient} from 'react-query'

export default function AcceptDeclineShipmentForm({price,shipment_id,setShowFormModal}) {
    const history = useHistory()
    const [showSuccessModal, setshowSuccessModal] = useState(false)
   const queryClient = useQueryClient()
    //Mutation
    const acceptDeclineAutoPriceShipment = useMutation((body)=> putDataCarrier(body,`api/carriers/autoprice/acceptdecline`),{
        onSuccess:(data)=>  {
            queryClient.invalidateQueries(['carrier','shipments','search'])
            setShowFormModal(false)
            if(data.data?.shipment?.status==='UPCOMING'){
            api_callback(true,'Αποδοχή Μεταφοράς','Η αποδοχή της μεταφοράς έγινε με επιτυχία')
            }else{
               if(Array.isArray(data.data?.shipment.carrier_decline)){
                   if(data.data?.shipment.carrier_decline.includes(getCarrierUserId())){
                    api_callback(true,'Απόρριψη Μεταφοράς','Η απόρριψη της μεταφοράς έγινε με επιτυχία')
                   }
               }
            }
        }
    })

     // Form
     const formik = useFormik({
        initialValues:{carrier_id:getCarrierUserId(),shipment_id},
        validationSchema,
        onSubmit:(body)=>{
            let mybody ={...body}
            mybody.carrier_id = getCarrierUserId()
            acceptDeclineAutoPriceShipment.mutate(mybody)
        }
    })

    return (
        <div>
            <Divider/>
            
            <Typography variant='h4' color='primary'>Τιμή</Typography>
            <Price price={price}/>
            <Typography>Πατώντας "Αποδοχή" σημαίνει οτι βρίσκεστε στη θέση να εκτελέσετε τη συγκεκριμένη μεταφορά στην τιμή που αναγράφεται.</Typography>
            <form className='mt-3' onSubmit={formik.handleSubmit}>
                <Button 
                type='submit'
                className='mr-4'
                style={{backgroundColor:'#D63649',color:'white'}}
                onClick={()=>formik.setFieldValue('accept',false)} variant="outlined" color='error' >
                    Απορριψη
                </Button>
                <Button 
                type='submit'
                style={{backgroundColor:'#1abc9c',color:'white'}}
                onClick={()=>{
                    formik.setFieldValue('accept',true)
                    }} variant="contained" color='error'>
                    Αποδοχη
                </Button>

            </form>

        </div>
    )
}

const validationSchema =  yup.object().shape({
    accept: yup.bool() 
  });