import React,{useEffect,useState} from 'react'
import "./style.css";
import  dateformat  from 'dateformat';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getDataCustomer,notEmptyArray } from '../../globals';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReactToPdf from "react-to-pdf"
const useStyles = makeStyles({
  table: {
    maxWidth: 650,
    borderColor:'transparent',
    borderWidth:0,
  },
  
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#1abc9c',
    color:  'white',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);





export default function Invoice() {

  const {shipment_id,customer_id}= useParams();
  const [rows, setRows] = useState([])
  const [totalAmount, settotalAmount] = useState(-1)
  const [shipment, setshipment] = useState(null)
  const ref = React.createRef();

  const options = {
    orientation: 'portait',
};
   
  //get shipment by id
   const {isLoading,data} = useQuery(['customer','shipmentinfo',shipment_id],()=> getDataCustomer(`api/shipments/${shipment_id}`),{
    staleTime:60*1000,
    onSuccess:(data)=>{
      console.log(data.data.shipment)
      const shipment =  data.data.shipment
      setshipment(shipment)
      console.log(shipment)
      setRows([createData(shipment.shipment_description, shipment.shipment_attributes[0].packing, shipment.price  )])

    },
  })
  const createData = (description, packaging, price) => {
    return { description, packaging, price };
  }
  
  //calculates total amount
  useEffect(() => {
   if(notEmptyArray(rows)){
    let amount=0;
    rows.forEach(row=> {
      amount+=row.price 
    });
    settotalAmount(amount)
   }else{
     settotalAmount(-1)
   }
  }, [rows])


  const classes = useStyles();


 
    if(rows.length>0){
      return (
        <>
        <ReactToPdf 
        targetRef={ref} 
        filename="div-blue.pdf"
        options={options} 
        scale={1}
        
        >
        {({toPdf}) => (
            <button onClick={toPdf}>Generate pdf</button>
        )}
      </ReactToPdf>
    
    <div id="example" style={{width:1000,height: 800,backgroundColor:'red'}} ref={ref}>
      <div className="box wide hidden-on-narrow">
        <div className="box-col" />

        <div className="box-col" />
      </div>

      <div className="page-container hidden-on-narrow">
        <div className="pdf-page size-a4">
          <div className="inner-page">
            <div className="pdf-header">
              <span className="company-logo">
                <img src={'https://woocargo.com/wp-content/uploads/2020/05/logo.png'} width='180' alt="Kendoka Company Logo" />
              </span>

              <span className="invoice-number">Invoice #23543</span>
            </div>

            <div className="pdf-footer">
              <p>IBAN :</p>
            </div>

            <div className="addresses">
              <div className="for">
                <h3 className='green_text'>Invoice For</h3>
              {shipment!==null &&
                <>
                <p>{shipment?.customer_id?.customer_name}</p>
                <p>{shipment?.customer_id?.customer_email}</p>
                <p>{shipment?.customer_id?.customer_address}</p>
                </>
              }
              </div>

              <div className="from">
              <h3 className='green_text'>From</h3>
              {shipment!==null &&
                <>
                <p>{shipment?.selected_carrier?.carrier_name}</p>
                <p>{shipment?.selected_carrier?.carrier_email}</p>
                <p>{shipment?.selected_carrier?.carrier_address}</p>
                <h3 className='green_text'>Invoice Details</h3>
                <p>Invoice ID : {shipment._id}</p>
                <p>Invoice Date: {dateformat(new Date(),'dd-mm-yyyy')}</p>
                <p>Due Date: {dateformat(new Date(new Date().getTime()+(20*24*60*60*1000)),'dd-mm-yyyy')}</p>
                </>
              }
              </div>
            </div>

            <div className="products">
             <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Description</StyledTableCell>
                      <StyledTableCell align="left">Packaging</StyledTableCell>
                      <StyledTableCell align="left">Amount</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {rows.length>0 &&rows.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell align="left">{row.description}</TableCell>
                        <TableCell align="left">{row.packaging}</TableCell>
                        <TableCell align="left">{row.price}€</TableCell>
                      </TableRow>
                    ))}
                    <TableRow key='fefe'>
                        <TableCell align="left"></TableCell>
                        <TableCell align="right">Total:</TableCell>
                        <TableCell align="left">{totalAmount} €</TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
    </TableContainer>
            </div>

            <div className="pdf-chart" />

            <div className="pdf-body">
              <div id="grid" />

              <p className="signature">
                Signature: ________________ <br />
                <br />
                Date: {dateformat(new Date(),'dd-mm-yyyy')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
}else{
  return null
}

}


