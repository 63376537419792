import React, { useState,useEffect } from 'react';
import { toMomentObject } from 'react-dates';
import GooglePlacesAutocomplete,{geocodeByAddress} from 'react-google-places-autocomplete';
import { notEmptyArray } from '../../../globals';
import { usePlacesWidget } from "react-google-autocomplete";

export default function InputPlacesAutoComplete({label,name,onChange,defaultValue,error}) {
    const [value, setValue] = useState(null);
    const { ref } = usePlacesWidget({
      apiKey: "AIzaSyClzjPWU5R8kAfeFZ43fb1MKCKZgQOeguQ",
      onPlaceSelected: async (place) => {
        console.log(place.formatted_address);
        await getGeoCoding(place.formatted_address)
      },
      options: {
        types: "address",
        componentRestrictions: { country: "gr" },
      },
    });



     const getGeoCoding = async(address)=>{
      geocodeByAddress(address)
      .then((results) => {
       // Managing postal_code,city,country //------------------------------------------
       let temp =  ['postal_code','locality','country','administrative_area_level_3'];
       let additionalLocationData={};
       if(notEmptyArray(results)){
        const address_components = results[0].address_components
        if(notEmptyArray(address_components)){
          address_components.map(component=>{
            const types= component.types
            temp.map(locationField=>{
             if(types.includes(locationField)){
               additionalLocationData[locationField] = component.long_name
             }
            })
           })
        }
       }
       //----------------------------------------------------------------------------------------
      
       const locationObject ={
           type:'Point',
           coordinates:[results[0].geometry.location.lat(),results[0].geometry.location.lng()],
           address:results[0].formatted_address,
           ...additionalLocationData
       }
       if(!additionalLocationData.postal_code){
       }
       console.log(locationObject)
       onChange(name,locationObject)
     })
     .catch((error) => console.error("Error", error));
    }

    useEffect(() => {
      const getGeo = async ()=>{
       await getGeoCoding(defaultValue)
      }
      getGeo();
    },[defaultValue])

  return (
    <div>
        <label className='gray_text'>{label}</label>
    
      <input ref={ref} className='form-control' defaultValue={defaultValue} />
       {error && <p style={{color:'red'}}>{error}</p>} 
    </div>
  );
}
