import React, { Component } from 'react'
import ShipmentCard from './ShipmentCard'
import ChatArea from './ChatArea'
import { connect } from 'react-redux'
import  './style.scss'
import { get_chatroom_by_id_async, handleReadMessages, set_messages_read_async } from './api'
import { chat_socket, handleSockets } from './handleSockets'
import {storee} from '../../index'
import { count_unread_messages, set_messages_read, update_chat } from '../../Redux/Actions/actions'
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
class ChatRoom extends Component {
    constructor(props){
        super(props);
        this.state ={
            show:false,
            messages:[],
            mysocket:null,
        }
    }
    componentWillMount(){
        //get the chat room
        this.props.get_chatroom_by_id(this.props.chat_room_id);
        //create the socket
        const socket = chat_socket(this.props.shipment_id);
        //store the socket to state
        this.setState({mysocket:socket})
        //socket events
        socket.on('connect',()=> console.log("Connected to Chat"))
        socket.on('get_message',(obj)=>{
          storee.dispatch(update_chat(obj))  
        })
    }
    set_unread_messages=(unread_messages)=>{
        console.log(unread_messages)
    }
    componentWillUnmount(){
        //Disconnect from socket when you leave the {i18next.t('Πληροφορίες Μεταφοράς')} Page
        this.state.mysocket.disconnect();
    }
    
    componentWillReceiveProps(props){
        if(props.chat_rooms.length >0){
        this.props.count_unreads(props.chat_room_id,props.type)
        }
    }
    
    render() {
        if(this.props.chat_room_id && this.props.chat_room_id.length>5 && this.props.shipment_type.toLowerCase()!='waiting'){
        return (
            <>
            <button id='chat_button' onClick={()=>this.setState({show:!this.state.show})}>
        {this.props.unread_messages && <button className='badge'>{this.props.unread_messages[this.props.chat_room_id]>0&&this.props.unread_messages[this.props.chat_room_id]}</button>}
             <LocalShippingIcon fontSize='large' />
            </button>
              {this.state.show &&<ChatArea 
              set_unread_messages={this.set_unread_messages} 
              chat_room_id={this.props.chat_room_id} 
              shipment_id={this.props.shipment_id} 
              socket={this.state.mysocket} 
              loading={this.props.loading} 
              messages={this.props.chat_rooms.find(c=>c._id==this.props.chat_room_id).chat}  
              sender={this.props.type}/>}
            </>
        )}else{
           return null 
        }

    }
}
const mapStateToProps = (state) => {
  
    return {
        customer_shipments: state.customerShipmentReducer.CustomerShipments,
        chat_rooms:state.chat_reducer.chat_rooms,
        loading:state.chat_reducer.loading,
        unread_messages:state.chat_reducer.unread_messages
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
        get_chatroom_by_id:(id)=>dispatch(get_chatroom_by_id_async(id)),
        set_messages_read:(id,sender)=>dispatch(set_messages_read_async(id,sender)),
        count_unreads :(id,sender)=>dispatch(count_unread_messages(id,sender)),
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(ChatRoom);

