import React from 'react'
import { NavLink } from 'react-router-dom';
import { trans } from '../../../globals';

export default function NavList({navlinks=[]}) {
    return (
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="border--color">
              <ul className="navbar-nav">
                  {navlinks.map(navlink=>{
                      return   <li className="nav-item">
                      <NavLink
                        to={navlink.link}
                        style={{ fontWeight: 500 }}
                        className="px-4"
                        activeClassName="active"
                        className="nav--item"
                      >
                       {trans(navlink.label)}
                      </NavLink>
                    </li>
                  })}
               
              </ul>
            </div>
          </div> 
    )
}
