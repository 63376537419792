import jwtDecode from "jwt-decode";
import { notAuthRedirectURL } from "../globals";
const Customer = "Customer_AuthToken";
const CustomerObj = "Customer_Obj";
const Carrier = "Carrier_AuthToken";
const CarrierObj = "Carrier_Obj";
const adminToken = "AdminToken"
const adminObj = "AdminObj"

export function createLocalStorageAdmin(token, obj) {
  localStorage.setItem(adminToken, token);
  localStorage.setItem(adminObj, JSON.stringify(obj));
}

export function createLocalStorageCustomer(token, obj) {
  localStorage.setItem(Customer, token);
  localStorage.setItem(CustomerObj, JSON.stringify(obj));
}
export function createLocalStorageCarrier(token, obj) {
  localStorage.setItem(Carrier, token);
  localStorage.setItem(CarrierObj, JSON.stringify(obj));
}
export function getCustomerObj() {
  try {
    const Object = JSON.parse(localStorage.getItem(CustomerObj));
    return Object;
  } catch (ex) {
    return null;
  }
}
export function getCarrierObj() {
  try {
    const Object = JSON.parse(localStorage.getItem(CarrierObj));
    return Object;
  } catch (ex) {
    return null;
  }
}
export function removeToken() {
  localStorage.clear();
  window.location = notAuthRedirectURL();
}
export function removeTokenOnly() {
  localStorage.clear();
}
export function getCustomer() {
  try {
    const jwt = localStorage.getItem(Customer);
    return jwt;
  } catch (ex) {
    return null;
  }
}
export function getCarrier() {
  try {
    const jwt = localStorage.getItem(Carrier);
    return jwt
  } catch (ex) {
    return null;
  }
}
export function getAdminToken() {
  try {
    const jwt = localStorage.getItem(adminToken);
    return jwt
  } catch (ex) {
    return null;
  }
}

export function getCustomerUserId() {
  try {
    const jwt = localStorage.getItem(Customer);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}
export function getCarrierUserId() {
  try {
    const carrier = localStorage.getItem(Carrier);
    return jwtDecode(carrier).id;
  } catch (ex) {
    return null;
  }
}
export function CustomerDetail(obj) {
  localStorage.setItem("Customer_Detail", JSON.stringify(obj));
}
export function getCustomerDetail() {
  try {
    const obj = JSON.parse(localStorage.getItem("Customer_Detail"));
    return obj;
  } catch (ex) {
    return null;
  }
}


// export const checkPaymentStatus = async () => {
//   const username = JSON.parse(localStorage.getItem("Carrier_Obj"))
//   const config = {
//     headers: {
//       "Authorization": "Bearer " + localStorage.getItem("Customer_AuthToken"),
//       "Content-Type": "application/json",
//     },
//   };
//   const requestbody = {
//     carrier_id: username._id,
//     status: 1
//   }
//   return await axios
//     .post(`${URL}api/stripe/payment-status`, requestbody, config)
//     .then(function (response) {
//       console.log('update-invoice response', response);
//       return response.data;
//     })
//     .catch((err) => {
//       console.log(err);
//       console.log(err.response);
//     });
//   // console.log('data', data);
//   // return data;
// }