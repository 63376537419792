import io from 'socket.io-client'
import { update_chat } from '../../Redux/Actions/actions'
import { URL } from '../../globals'
import {store} from '../../index'

export const chat_socket = (shipment_id)=>io(`${URL}chatrooms?shipment_id=${shipment_id}`,{transports: ['websocket'], upgrade: false})



export const send_message=(socket,msg)=>{
socket.broadcast.emit('send_message',msg)
}


