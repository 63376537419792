import React,{useState,useEffect} from 'react'
import { Link } from "react-router-dom";
import OfferModal from "../CarrierSide/Offers/OfferModal";
import "../../Styles/Card.css";
import StateCard from './StateCard'
import CarrierOffers from "../CarrierSide/Offers/CarrierOffers";
import i18next from 'i18next';
import { notEmptyArray, trans } from "../../globals";
import { Button } from "@material-ui/core";
import LocationTime from "./Shipments/LocationTime/LocationTime";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { SaveTwoTone } from '@material-ui/icons';

export default function Card({list,offerButton,type}) {

  const {
    data,
    _id,
    status,
    pickup,
    dropoff,
    shipment_attributes,
    offer
  } = list;
  const [shipmentUrl, setShipmentUrl] = useState('')
  const [offerModalVisible, setOfferModalVisible] = useState(false)
  const [carrierOffersModalVisible, setcarrierOffersModalVisible] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
   if(type=='customer'){
    setShipmentUrl('/shipment')
   }else if(type=='carrier'){
    setShipmentUrl('/carrier_shipment')
   }else if(type=='admin'){
    setShipmentUrl('/admin/shipment')
   }
  }, []) 

  const sa = notEmptyArray(data) ? notEmptyArray(data[0].shipment_attributes)?data[0].shipment_attributes[0]:{} :{}

  return (
    <div className="bgcolor mb-3 shadow-sm mt-2 p-2">
    <div className="row pt-2">
      <div className="col-md-3 col-sm-12">
        <div className="ID">
          {notEmptyArray(data)&&
          <>
          <p>{sa.description}</p>
          <p className='gray_text'>{sa.quantity} {sa.packing} {sa.length}*{sa.width}*{sa.height} {sa.weight} kg</p>
          </>
          }
          <div>
          <StateCard state={status}/>
          </div>
        
        </div>
      </div>
      <div className="col-md-4 col-sm-12 ">
      <LocationTime data={notEmptyArray(data)?data[0].pickup:pickup} isPickup={true}/>
      </div>
      <div className="col-md-4 col-sm-12 ">
        <LocationTime data={notEmptyArray(data)?data[0].dropoff:dropoff} isPickup={false}/>
        {notEmptyArray(data) && data.length >1 &&
        
        <div>
          <Button aria-describedby={id} color='primary' variant="text" onClick={handleClick}>
           + {data.length-1} Στασεις 
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
           <div className='p-3'>
            <Typography variant='h6'>Προορισμόι</Typography>
            <hr></hr>
            {data.map((d,index)=>{
              return  <Typography>{index+1}. {d?.dropoff?.location?.address}</Typography>
            })}
           </div>
          </Popover>
        </div>
        }
      </div>
    
      <div className="col-md-1 col-sm-12" >
        <p className="blueandbold">
          {list.price ? list.price :list.offer? list.offer.length>0? list.offer[0].cost:'---':'---' } €
        </p>
        {/*--------  --------Show offer button---------------- */}
        {offerButton ? (
          <>
            {Array.isArray(offer) && offer.length>0?
            <button className="btn btnsize" 
            style={{fontSize:8,fontWeight:'bold',marginLeft:-20}} 
            onClick={()=>setcarrierOffersModalVisible(!carrierOffersModalVisible)}> 
            {trans("common.buttons.myoffers")}
            </button>:
             <Button
             onClick={()=>setOfferModalVisible(!offerModalVisible)}
             variant='contained' color='primary' size='small' 
             style={{position:'absolute',right:'2em',bottom:0,fontSize:'0.7em',textTransform:'none'}}>
               {i18next.t('common.buttons.offer')}
              </Button>
            }
        
            {offerModalVisible && (
              <OfferModal
                shipmentobj={list}
                offerModalVisible={offerModalVisible}
                modalVisibility={setOfferModalVisible}
                showMakeOfferForm={list.needsOffer}
                showAcceptDeclineShipmentForm={!list.needsOffer}
              />
            )}
            {/*--------  --------Show offer End---------------- */}
          </>
        ) : (
          <Link to={shipmentUrl + `/${_id}`}>
            <Button variant='contained' color='primary' size='small' style={{position:'absolute',right:'2em',bottom:10,fontSize:'0.7em',textTransform:'none'}}>{i18next.t('common.buttons.open')}</Button>
          </Link>
        )}
      </div>
      {list.offer && 
      <CarrierOffers onClose={()=>setcarrierOffersModalVisible(false)} show={carrierOffersModalVisible} offers={list.offer}/>
      }
    </div>
  </div>
  )
}
