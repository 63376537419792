import React from 'react'

export default function MyTextField({error,
    label,
    onChange,
    id,
    name,
    placeholder,
    value,
    type='text',
    defaultValue='',
    rows=3
}) {
    return (
        <div style={{marginTop:'0em'}}>
           <label className='gray_text'>{label}</label> 
           <textarea
           id={id}
           name={name}
           value={value}
           defaultValue={defaultValue}
           type={type}
           onChange={onChange}
           placeholder={placeholder}
           className='form-control'
           rows={rows}
           style={{borderColor:error&&"red",backgroundColor:error&&"rgba(255,0,0,.1"}}
           />
        {error && <p style={{color:'red'}}>{error}</p>}
        </div>
    )
}
