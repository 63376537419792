import { useState, useEffect } from 'react';
import { getDataCarrier, getDataCustomer } from '../../../../globals';
import { useQuery } from 'react-query';

export default function useFetchData(getUrl,queryString,type,access) {

   const [fetchedData, setfetchedData] = useState(null)

   const {isLoading,data,refetch} = useQuery(queryString,()=> type=='customer'? getDataCustomer(getUrl): getDataCarrier(getUrl),{
     onSuccess:(data)=>{
         console.log("Shipments",data)
        setfetchedData(objectByString(data,access)) 
     },
    })

  return [fetchedData,isLoading];
}


const objectByString = (o, s)=> {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}