import React, { Component } from "react";
import "../../Styles/CustomerHome.css";
import  i18next from 'i18next';
export default class CardTitle extends Component {
  render() {
    return (
      <div className="mt-5">
        <div className="row">
          <div className="col-3">
            <span className="numbercolor">{i18next.t('shipmentinfo.desc_id')}</span>
          </div>
          <div className="col-4">
            <span className="numbercolor">{i18next.t('shipmentinfo.pickup_loc')}</span>
          </div>
          <div className="col-4">
            <span className="numbercolor">{i18next.t('shipmentinfo.dropoff_loc')}</span>
          </div>
          <div className="col-1">
            <span className="numbercolor">{i18next.t('shipmentinfo.price')}</span>
          </div>
        </div>
      </div>
    );
  }
}
