import React,{useEffect} from 'react'
import {useQuery,useQueryClient,useMutation} from 'react-query'
import { useFormik} from 'formik'
import { postDataAdmin,getDataAdmin,trans,api_callback } from '../../../../globals'
import MySelect from '../../../../Components/Common/Forms/MySelect';
import DrawerForm from '../../../../Components/Common/Forms/DrawerForm/DrawerForm';
import { Button, Typography, Divider } from '@material-ui/core';
import CarrierCard from '../../../../Components/Common/Cards/CarrierCard/CarrierCard';

export default function AssignCarrier({assignedCarriers,isOpen,setOpen,shipment_id,selected_carriers=[]}) {

  const queryClient = useQueryClient()
  //Get Carriers 
  const {data, isLoading,refetch} = useQuery('carriers',()=>getDataAdmin('crud/carriers'),{
    initialData: () => {
     return queryClient.getQueryData('carriers')
      },
      enabled:false
  })

   //Accept Offer 
   const assignCarriers= useMutation((body)=>postDataAdmin(body,`api/shipments/${shipment_id}/addcarriers`),{
    onSuccess: (data)=>{
      api_callback(true,"Success","Οι μεταφορείς προστέθηκαν με επιτυχία")
      formik.resetForm()
      setOpen(false)
    }
  })

  const formik = useFormik({
    initialValues: {},
    onSubmit: body =>{
      //alert(JSON.stringify(body))
      assignCarriers.mutate(body) 
    }
  });

    return (
      <DrawerForm setOpen={setOpen} isOpen={isOpen}>
          <form onSubmit={formik.handleSubmit}>
          <Typography variant='h6'>Assign Carrier to make an offer.</Typography>
          <MySelect 
          isMulti={true}
          isFetching={true}
          queryString={'carriers'}
          type='carrier'
          getUrl={`api/crud/carriers`}
          name='carriers'
          label1='carrier_name'
          label2='carrier_email'
          error={formik.errors.carriers}
          onChange={formik.setFieldValue}
          label={trans('shipmentinfo.carrier')}
          />

          <Button type='submit' className='mt-3' color='primary' variant='contained'>Assign</Button>

          </form>
          
          <Divider className='mt-3 mb-3'/>

          <Typography variant='h6'>Assigned Carriers</Typography>
          {Array.isArray(assignedCarriers) && 
            assignCarriers.length == 0 ?  <Typography variant='p'>No Assigned Carriers</Typography>
            : assignedCarriers.map(carrier=> <CarrierCard carrier={carrier}/>)
          }

      </DrawerForm> 
    )
}


