import { Typography } from '@material-ui/core';
import React,{useEffect} from 'react'
import { useQuery } from 'react-query';
import { getDataCarrier, getDataCustomer, getDataAdmin } from '../../../globals';

import ActionButtons from './ActionButtons';

export default function Crud({
    title='Title',
    getUrl,
    queryString,
    rowComponent,
    type='customer',
    responseNestedKey,
    formStructure
}) {
// Get Data
const {isLoading,data} = useQuery(queryString,()=> type==='customer'? getDataCustomer(getUrl):type==='admin'?getDataAdmin(getUrl):getDataCarrier(getUrl),{
    staleTime:2000,
    cacheTime:5000,
    enabled:true,
    onSuccess:(data)=>{
      console.log(data[responseNestedKey])
    },
  })

  useEffect(() => {
      console.log("dwdw",formStructure)
  },[formStructure])



  return (
    <div>
        <Typography variant='h4'>{title}</Typography>
        {isLoading?<h4>Loading</h4> : Array.isArray(data[responseNestedKey]) && data[responseNestedKey].map(d=>{
            return <>
            {React.cloneElement(rowComponent,{data:d,actionButtons: <ActionButtons record={d} _id={d._id} formStructure={formStructure} queryString={queryString} deleteUrl={`api/autoprice/${d._id}`}/>})}
            </>
        })}
    </div>
  )
}




