import React from 'react';
import InputField from '../../Common/InputField';
import logo from '../../../Pics/logo_with_text.svg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  createLocalStorageCarrier,
  getCustomerUserId,
} from '../../../LocalStorage/Auth';
import * as yup from 'yup';
import '../../../Styles/LogInForm.css';
import {  handleNotifications, trans } from '../../../globals';
import { customerServices } from '../../../Services/Customer/customer_services';
import { authServices } from '../../../Services/Auth/auth_services';
import ChangeLanguage from '../../../locale/ChangeLanguage';
import Row from '../../Common/Layout/Row';
import Column from '../../Common/Layout/Column';

const schema = yup.object().shape({
  email: yup.string().required().email().label('Email'),
  password: yup.string().required().min(6).label('Password'),
});
class LogInForm extends React.Component {
  state = {
    data: { email: '', password: '' },
    error: {},
    link: '',
    box_clicked_customer: false,
    box_clicked_carrier: false,
    box_error: false,
    invalid_login: false,
    loading: false,
    loginButtonSpinner: false,
  };

  changeHandler = (e) => {
    console.log(e.currentTarget.name);
    const data = { ...this.state.data };
    data[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ data });
  };


  spinnerButton = () => {
    this.setState({  });
  };
  //Submit the form
  carrierLogin= (e) => {
    const requestBody = {
      email: this.state.data.email,
      password: this.state.data.password,
    };
    schema
      .validate(requestBody, { abortEarly: false })
      .then((res) => {
        this.setState({ error: {} });
        this.callServerApi();
      })
      .catch((err) => {
        let error = {};
        console.log(err, 'errorlogin');
        if(err.inner){
        err.inner.map((err) => {
          error[err.path] = err.message;
        });
      }
        this.setState({ error });
      });
    e.preventDefault();
  };

  callServerApi = () => {
    this.setState({ invalid_login: false });
    const requestBody = {
      email: this.state.data.email,
      password: this.state.data.password,
    };
    this.props.loginCarrier(requestBody, this.LoginSucessCarrier);
  };

  LoginSucessCarrier = (res) => {
    createLocalStorageCarrier(res.data.authToken, res.data.carrier);
    handleNotifications();
    this.props.history.push('/CarrierHome');
  };
  

  render() {
     
      return (
        <React.Fragment>
          
         
             <ChangeLanguage style={{position:'absolute',left:30,top:10,zIndex:1000}} />
            <Row style={{height:'100vh',width:'100vw'}}>
             
              <Column style={{flex:1,padding:'2em',alignItems:'center'}}>
              <div style={{width:'60%',maxWidth:500,minWidth:300,marginTop:'4em'}}>
              <img src={logo} className="img-fluid" alt="logo"></img>
              <p className="fontchange d-block">{trans('login.carrier_title')}</p>
              <p className="gray_text">{trans('login.form.title')}</p>
             {/*-----------------Login Form begins--------------*/}
            
              <form
                noValidate={true}
                onSubmit={this.carrierLogin}
               >
                      <div className="form-group">
                        <InputField
                          labeltitle="EMAIL"
                          typename="email"
                          fieldname="email"
                          changeHandler={this.changeHandler}
                          value={this.state.data.email}
                          error={this.state.error.email}
                        />
                      </div>
                      <div className="form-group">
                        <InputField
                          labeltitle="PASSWORD"
                          typename="password"
                          fieldname="password"
                          changeHandler={this.changeHandler}
                          value={this.state.data.password}
                          error={this.state.error.password}
                        />
                      </div>
                      <button
                        disabled={this.state.loginButtonSpinner}
                        type="submit"
                        className="btn btn-primary btncolor btn-block btn-lg"
                      >
                        {this.state.loginButtonSpinner ? (
                          <span>Loading ...</span>
                        ) : (
                          <span>{trans('login.form.button')}</span>
                        )}
                      </button>
                      <div className="text--color text-center pb-4">
                        <span>{trans('login.form.noaccount')}</span> &nbsp;
                        <Link to="/carriersignup" className="font-weight-bold">
                          {trans('login.form.signup')}
                        </Link>
                      </div>
                    </form>
                    </div>
                    {/*-----------Login form End-----------  */}

              </Column>

                   
              <div style={{flex:1}}>
                <div className="bgpic"></div>
                </div>
              </Row>
        </React.Fragment>
      );
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    login: (requestBody, callback) =>
      dispatch(customerServices.customerLogin(requestBody, callback)),
    loginCarrier: (body, callback) =>dispatch(authServices.loginCarrier(body, callback)),
    getCustomerById: (callback) =>dispatch(customerServices.getCustomerById(getCustomerUserId().id, callback)),
  };
};
export default connect(null, mapDispatchToProps)(LogInForm);
