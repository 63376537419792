import React from "react";
import "../../Styles/CarrierDashboard.css";
export default function AddButtonCarr() {
  return (
    <div>
      <button className="plus_button">
        <span style={{ fontSize: "40px"}}>+</span>
      </button>
    </div>
  );
}
