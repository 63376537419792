import React from 'react'
import { useQuery } from 'react-query';
import { getDataCarrier, notEmptyArray, trans } from '../../../../globals';
import { Row } from '@dimararist/woocargo.layout.row';
import Column from '../../../Common/Layout/Column';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

export default function ShowDriverTruck({truck_id,driver_id}) {

    //get driver 
    const {isLoading:driverLoading,data:driver} = useQuery(['driver',driver_id],()=> getDataCarrier(`api/drivers?_id=${driver_id}`),{
    staleTime:2000,
    cacheTime:5000,
    onSuccess:(data)=>console.log("object",data)
    })
   //get truck 
   const {isLoading:truckLoading,data:truck} = useQuery(['vehicle',truck_id],()=> getDataCarrier(`api/vehicles?_id=${truck_id}`),{
    staleTime:2000,
    cacheTime:5000,
    })

    return (
        <Row>
              <Column style={{marginRight:20}}>
              <span className="black_text d-block"><PersonOutlineOutlinedIcon fontSize='medium'/> {trans("shipmentinfo.driver")}</span>
              {notEmptyArray(driver?.data)&&
              <>
              <span className="gray_text">{driver.data[0].driver_name}   </span>
              <span className="gray_text">{driver.data[0].driver_email}</span>
              <span className="gray_text">{driver.data[0].driver_mobilenum} </span>
              </>}
              </Column>
              <Column>

              <span className="black_text d-block"> <LocalShippingOutlinedIcon fontSize='medium'/> {trans("shipmentinfo.truck")}</span>
              <Row>
             
              {notEmptyArray(truck?.data) && <span className="gray_text">{truck.data[0].vehicle_name} - {truck.data[0].registeration_num}</span>}
              </Row>
              </Column>
            </Row>
    )
}
