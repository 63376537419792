import React, { Component } from 'react';
import { trans } from '../../../globals';
import userpic from "../../../Pics/profile-gray.png";
import Column from '../../Common/Layout/Column';
import Row from '../../Common/Layout/Row';


export default class ProfileData extends Component {
  render() {
    const { loadingButton } = this.props.state;
    return (
        <div className="shadow-sm container py-4" style={{ background: "#ffffff" }}>
          <Row style={{justifyContent:'space-between'}}>
            <span className="black_text pl-3">{trans('profile.edit.title')}</span>
            <button style={{cursor:'pointer',right:0,top:0,position:'relative'}} type='button' className='nobg_btn_red' onClick={()=>this.props.change_state("edit_profile",true)}>{trans("common.buttons.edit")}</button>
          </Row>
        <div className="col-12 justify-content-center row">
        <Column style={{alignItems:'center',marginBottom:'2em'}}>

          <img
            src={userpic}
            alt="userpic"
            className=""
            style={{ width: "9em", height: "9em" }}
          />
        </Column>
        </div>
        <span className="font-weight-bolder pl-3">
        {trans('profile.edit.message')}
        </span>
        <div className="form p-3 ">
          {/*  Edit Profile */}
         {this.props.state.edit_profile==true?(
            <form onSubmit={this.props.formPersonalData}>
              <div className="form-row mt-3">
                {this.props.renderNewInput("common.labels.company_name", "company_name")}
                {this.props.renderNewInput("common.labels.email", "carrier_email", "email")}
                {this.props.renderNewInput("common.labels.mobilenum", "carrier_phone", "tel")}
                {this.props.renderNewInput("common.labels.name", "carrier_name")}
                {this.props.renderNewInput("common.labels.address", "carrier_address")}
                {this.props.renderNewInput("common.labels.vat_number", "vat_number")}
                {this.props.renderNewInput("common.labels.tax_office", "tax_office")}
                {this.props.renderNewInput("common.labels.occupation", "occupation")}
            </div>
            
            
            <div className="form-row mt-3">
              <div className="form-group col-md-3">
                <button
                  className="btn btn-primary btn-block"
                  type="submit"
                  disabled={loadingButton}
                >
                  {loadingButton ? <span>Loading ...</span> : <span>{trans('common.buttons.save')}</span>}
                </button>
              </div>
            </div>
          </form>)
           //Show Profile Data
                  :
         ( <div>
            <div className="form-row">
                {this.props.renderField("common.labels.company_name", this.props.state.data.company_name)}
                {this.props.renderField("common.labels.email", this.props.state.data.carrier_email)}
                {this.props.renderField("common.labels.mobilenum", this.props.state.data.carrier_phone)}
                {this.props.renderField("common.labels.name", this.props.state.data.carrier_name)}
                {this.props.renderField("common.labels.address", this.props.state.data.carrier_address)}
                {this.props.renderField("common.labels.vat_number", this.props.state.data.vat_number)}
                {this.props.renderField("common.labels.tax_office", this.props.state.data.tax_office)}
                {this.props.renderField("common.labels.occupation", this.props.state.data.occupation)}
            </div>
          </div>
         )}
        </div>
      </div>
    );
  }
}
