import axios from "axios";
import { URL } from "../../globals";
import { AddDriverAction,DeleteDriverAction,EditDriverAction, GetDriversAction} from "../../Redux/Actions/actions";

export const addDriver = (
  requestbody,
  config,
  dispatch,
  AddDriverSucess,
  AddDriverFailure
) => {
  console.log("service driver", requestbody, config);
  axios
    .post(`${URL}api/carriers/drivers`, requestbody, config)
    .then(function (response) {
      dispatch(AddDriverAction(response.data));
      //callback function to change routes or etc
      AddDriverSucess();
    })
    .catch(function (error) {
      AddDriverFailure();
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.log("Error", error.message);

        console.log("error 1");
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    });
};

export const DeleteDriverService = (
  config,
  dispatch,
  driverId,
  editDeleteSuccess,
  editDeleteFails
) => {
  console.log("sevice driver edit", config, driverId);
  axios
    .delete(`${URL}api/carriers/drivers/${driverId}/`, config)
    .then(function (response) {
      dispatch(DeleteDriverAction(driverId));
      //callback function to change routes or etc
      editDeleteSuccess();
    })
    .catch(function (error) {
      editDeleteFails();
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.log("Error", error.message);

        console.log("error 1");
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    });
};

export const EditDriverService = (
  requestBody,
  config,
  dispatch,
  driverId,
  editDeleteSuccess,
  editDeleteFails
) => {
  console.log("sevice driver edit", requestBody, config, driverId);
  axios
    .put(`${URL}api/carriers/drivers/${driverId}/`, requestBody, config)
    .then(function (response) {
      dispatch(EditDriverAction(requestBody, driverId));
      //callback function to change routes or etc
      editDeleteSuccess();
    })
    .catch(function (error) {
      editDeleteFails();
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.log("Error", error.message);

        console.log("error 1");
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    });
};

export const GetDrivers = (
  requestbody,
  config,
  GetDriversSucess,
  GetDriversFailure
) => {
  return dispatch =>{
  axios
    .get(`${URL}api/carriers/${requestbody.carrierid}/drivers`, config)
    .then(function(response) {
      dispatch(GetDriversAction(response.data));
      //callback function to change routes or etc
      GetDriversSucess();
    })
    .catch(function(error) {
      GetDriversFailure();
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.log("Error", error.message);

        console.log("error 1");
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    });
  }
};