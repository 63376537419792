import React,{useState} from 'react'
import {  time_options, trans } from '../../../../globals';
import MySelect from '../../../Common/Forms/MySelect';

export default function Time({setFieldValue}) {

    const handleFieldValue =(name,value)=>setFieldValue(name,value)

    const timeOptions =[
        {value:'same-day-courier',label:'Same Day'},
        {value:'next-day-courier',label:'Next Day'},
    ]
    

    return (
        <div className='col-md-3 p-4 col-sm-12'>
            <MySelect 
             name='type'
             label={trans('shipmentinfo.time')}
             options={timeOptions} 
             defaultValue={timeOptions[0].value}
             onChange={handleFieldValue}
            />
      </div>
    )
}

