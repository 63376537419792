import React,{useState} from 'react';
import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';
import Cube from 'react-preloaders/lib/Cube/Cube';
import greece from '../Pics/locale/greece.png'
import uk from '../Pics/locale/uk.png'
 const ChangeLanguage= ({style={}})=> {
const [loading, setloading] = useState(false)
  const changeLanguage = (lng) => {
   
    setloading(true)

    
    i18n.changeLanguage(lng).then((res) =>{
      console.log(res)
     setloading(false)
    })
    .catch(error =>setloading(false))
   
  }
  if(loading){
  return <Cube customLoading={loading} time={0} />;
  }else{
  return (
    <div className='d-flex flex-row mr-5' style={{...style}}>
      <button className='language_button'   
      onClick={() =>{ 
      i18n.changeLanguage('gr')
      window.location.reload()
      }}> <img src={greece}></img> </button>
      <button className='language_button'   onClick={() => {
        i18n.changeLanguage('en')
        window.location.reload()
        }}> <img src={uk}></img></button>
    </div>
  )
  }
}

export default ChangeLanguage;