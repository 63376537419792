const Images = {
  shipicon1: require("../Pics/shipicon1.png"),
  ltl_icon: require("./NewShipmentIcons/ltl_icon.png"),
  ftl_icon: require("./NewShipmentIcons/ftl_icon.png"),
  ftl_icon: require("./NewShipmentIcons/ftl_icon.png"),
  removal_icon: require("./NewShipmentIcons/removal_icon.png"),
  drayage_icon: require("./NewShipmentIcons/drayage_icon.png"),
  car_icon: require("./NewShipmentIcons/car_icon.png"),
  heavyMachinery_icon: require("./NewShipmentIcons/heavyMachinery_icon.png"),
  shipicon2: require("../Pics/shipicon2.png"),
  shipicon3: require("../Pics/shipicon3.png"),
  shipicon4: require("../Pics/shipicon4.png"),
  shipicon5: require("../Pics/shipicon5.png"),
  shipicon6: require("../Pics/shipicon6.png"),
  signupicon1: require("../Pics/signupicon1.png"),
  bgpic: require("../Pics/LogInPic.png"),
  logo: require("../Pics/logo.svg"),
  messageicon: require("../Pics/messageicon.svg"),
  bellicon: require("../Pics/interface.png"),
  pickupicon: require("../Pics/up_icon.png"),
  deliveryicon: require("../Pics/down_icon.png"),
  userpic: require("../Pics/Account.png"),
  Cpickupicon: require("../Pics/pickupicon.png"),
  Cdeliveryicon: require("../Pics/deliveryicon.png"),
  vdicon1: require("../Pics/vdicon1.png"),
  vdicon2: require("../Pics/vdicon2.png"),
  vdicon3: require("../Pics/vdicon3.png"),
  vdicon4: require("../Pics/vdicon4.png"),
  vdicon5: require("../Pics/vdicon5.png"),
  chatpic: require("../Pics/chatpic.png"),
  logo_with_text: require("../Pics/logo_with_text.svg"),
  addbox: require("../Pics/box.png"),
};
export default Images;

