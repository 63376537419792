import React, { Component } from "react";
import AddVehicalModal from "./AddVehicalModal";
import Modal from "react-bootstrap/Modal";
import { trans } from "../../../globals";

export default class VehicalCard extends Component {
  state = {
    modal: false,
  };
  handleModal = () => {
    this.setState({ modal: !this.state.modal });
  };
  render() {
    const {
      vehicle_name,
      registeration_num,
      vehicle_model,
      vehicle_type,
      vehicle_capacity,
      vehicle_weight,
    } = this.props.list;

    return (
      <div
        className="container p-4 mt-3 shadow-sm rounded-lg"
        style={{ background: "#ffffff" }}
      >
        <div className="row">
          <div className="col-3">
            <div className="d-flex justify-contents-around">
              <div className="col-9">
                <span className="black_text-afterimage">{vehicle_name}</span>
                <br></br>
                <span className="black_text2-afterimage">{registeration_num}</span>
              </div>
            </div>
          </div>
          <div className="col-2">
            <span className="black_text">{vehicle_model}</span>
          </div>
          <div className="col-1">
            <span className="black_text">{vehicle_type}</span>
          </div>
          <div className="col-2">
            <span className="black_text">{vehicle_capacity} {trans('vehicles.palletes')} </span>
          </div>
          <div className="col-2">
            <span className="black_text">{vehicle_weight} {trans('vehicles.tones')}</span>
          </div>
          <div className="col-2">
            <button className="nobg_btn_purple" style={{ cursor: "pointer" }}onClick={this.handleModal}>
            {trans('common.buttons.open')}
            </button>
          </div>
          {/*---------MODAL FOR EDIT OR DELETE RECORD-------- */}
          <Modal
            size="lg"
            show={this.state.modal}
            onHide={this.handleModal}
            backdrop="static"
            animation="true"
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <AddVehicalModal
              handleModal={this.handleModal}
              title="EditOrdelete_vehicle"
              data={this.props.list}
            />
          </Modal>
        </div>
      </div>
    );
  }
}
