import React from 'react'

export default function Row({children,className,style={}}) {
    return (
        <div className={`d-flex flex-row ${className}`} style={{...style }}>
            {children}
        </div>
    )
}

