import React from 'react';
import { store } from 'react-notifications-component';
import { storee } from './index';
import axios from 'axios'
import InputField from './Components/Common/InputField';
import io from 'socket.io-client';
import {
  getCustomer,
  getCustomerUserId,
  getCarrierUserId,
  getCarrierObj,
  getCarrier,
  getAdminToken,
} from './LocalStorage/Auth';
import i18next from 'i18next';
import { shipmentServices } from './Services/Shipments/shipment_services';

export const api_url = () => {
  if (process.env.NODE_ENV == 'development') {
    return 'http://localhost:8000/';
  } else {
    return 'https://woocargo.herokuapp.com/';
  }
};

export const notAuthRedirectURL = () => {
  if (process.env.NODE_ENV == 'development') {
    return 'http://localhost:3000';
  } else {
    return 'https://www.woocargo.com';
  }
};

export const trans = (text) => {
  return i18next.t(text);
};
export const URL = api_url();

export const first_capital = (word) => {
  word = word.toLowerCase();
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const transform_shipment_attributes = (object) => {
  let my_obj = {};

  attributePriority.map(attribute=>{
    let name = attribute;
    let value = object[attribute];
    if (attribute == 'hydraulic_door') name = 'Hydraulic Doors';
    if(attribute=='dimensions'){
      value = (object['width'] && object['height'] && object['length'])? `${object['width']}*${object['height']}*${object['length']}` :""
    }
    if (object[attribute] === true) value = trans('yes');
    if (object[attribute] === false) value = trans('no'); 
    my_obj[first_capital(name)] = value;
  })
  return my_obj;
};
const attributePriority =['quantity','packing','weight','dimensions','hydraulic_door']
export const get_config = () => {
  return {
    headers: {
      Authorization: `Bearer ${getCustomer()}`,
      'Content-Type': 'application/json',
    },
  };
};
export const get_carrier_config = () => {
  return {
    headers: {
      Authorization: `Bearer ${getCarrier()}`,
      'Content-Type': 'application/json',
    },
  };
};

export const get_admin_config = () => {
  return {
    headers: {
      Authorization: `Bearer ${getAdminToken()}`,
      'Content-Type': 'application/json',
    },
  };
};
export const config = get_config();
export const api_callback = (success, title, message) => {
  if (success) {
    store.addNotification({
      title: title,
      message: message,
      type: 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 4000,
        onScreen: true,
      },
    });
  } else {
    store.addNotification({
      title: title,
      message: message,
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 4000,
        onScreen: true,
      },
    });
  }
};
export const notEmptyObject =(obj)=>  Object.keys(obj).length>0

export const notEmptyArray=(arr,length=0)=>{
  if(Array.isArray(arr)){
    if(arr.length>length){
      return true
    }else{
      return false
    }

  }else{
    return false
  }

}

export const minimizeLocation =(address)=>{
  const splitLocation = address ? address.split(',')  : []
  let location;
  if(notEmptyArray(splitLocation,1)){
    location = `${splitLocation[0]},${splitLocation[1]}`
  }
  return location
}

export const handleNotifications = () => {
  let userid;
  if (getCustomer()) {
    userid = getCustomerUserId().id;
  }
  if (getCarrierObj()) {
    userid = getCarrierUserId();
  }
  const notificationsocket = io(`${URL}notifications?userid=${userid}`);
  notificationsocket.on('connect', () => {});
  notificationsocket.on('notification', (notification) => {
    if(notification.type=='carrier'){
    console.log('NOTIFICATION -----', notification);
    store.addNotification({
      title: notification.notification_title,
      message: notification.notification_content,
      type: 'info',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 4000,
        onScreen: true,
      },
    });
  }
    if (getCustomer()) {
      const { id } = getCustomerUserId();
      const requestBody = { customerid: id };
      storee.dispatch(
        shipmentServices.getCustomerShipments(requestBody, false)
      );
    }
    if (getCarrier()) {
      storee.dispatch(
        shipmentServices.getCarrierShipments(getCarrierUserId(), false)
      );
    }
  });
};

export const re_fetch = (func, seconds) => {
  console.log('REFETCH');
  setInterval(func, seconds * 1000);
};
export const dispatchAction = (func) => {
  storee.dispatch(func);
};

export const time_options = [
  { value: '03:00 - 06:00', label: '03:00 - 06:00' },
  { value: '06:00 - 09:00', label: '06:00 - 09:00' },
  { value: '09:00 - 12:00', label: '09:00 - 12:00' },
  { value: '12:00 - 15:00', label: '12:00 - 15:00' },
  { value: '15:00 - 18:00', label: '15:00 - 18:00' },
  { value: '18:00 - 21:00', label: '18:00 - 21:00' },
  { value: '21:00 - 24:00', label: '21:00 - 24:00' },
];
export const packaging_options= [
  { value: 'Παλέτες', label: 'Παλέτες' },
  { value: 'Παλέτες - Ψυγείο', label: 'Παλέτες - Ψυγείο' },
  { value: 'Κιβώτια', label: 'Κιβώτια' },
  { value: 'Χωρίς Συσκευασία', label: 'Χωρίς Συσκευασία' },
 
  ]
  export const palets_types_with_labels = [
    { value: 'Ευρωπαλέτα - 120*80*100', label: 'Ευρωπαλέτα - 120*80*100' },
    { value: 'Βιομηχανική - 100*120*100', label: 'Βιομηχανική - 100*120*100' },
    { value: 'Custom', label: 'Custom' },
  ];
  export const kibvtia_types_with_labels = [
    { value: '60*40*40', label: '60*40*40' },
    { value: '40*40*40', label: '40*40*40' },
    { value: '40*30*40', label: '40*30*40' },
    { value: 'Βαρέλια - 60*60*100', label: 'Βαρέλια - 60*60*100' },
    { value: 'Custom', label: 'Custom' },
   
  ];
export const time_options_only_values = [
  '03:00 - 06:00',
  '06:00 - 09:00',
  '09:00 - 12:00',
  '12:00 - 15:00',
  '15:00 - 18:00',
  '18:00 - 21:00',
  '21:00 - 24:00',
];
export const palets_types = [
  'Ευρωπαλέτα - 120*80*100',
  'Βιομηχανική - 100*120*100',
  'Custom - 100*100*100',
];
export const kibvtia_types = [
  '60*40*40',
  '40*40*40',
  '40*30*40',
  'Βαρέλια - 60*60*100',
];

export  const getDataCustomer = async (getUrl)=>{
  const {data} = await axios.get(`${URL}${getUrl}`,get_config())
  return data
}

export  const deleteDataCustomer = async (deleteUrl)=>{
  const {data} = await axios.delete(`${URL}${deleteUrl}`,get_config())
  return data
}
export  const putDataCustomer = async (body,putUrl)=>{
  const {data} = await axios.put(`${URL}${putUrl}`,body,get_config())
  return data
}


export  const postDataCustomer = async (body,url)=>{
  const {data} = await axios.post(`${URL}${url}`,body,get_config())
  return data
}

export  const getDataCarrier = async (getUrl)=>{
  const {data} = await axios.get(`${URL}${getUrl}`,get_carrier_config())
  return data
}

export  const deleteDataCarrier= async (deleteUrl)=>{
  const {data} = await axios.delete(`${URL}${deleteUrl}`,get_carrier_config())
  return data
}
export  const putDataCarrier= async (body,putUrl)=>{
  const {data} = await axios.put(`${URL}${putUrl}`,body,get_carrier_config())
  return data
}


export  const postDataCarrier= async (body,postUrl)=>{
  const {data} = await axios.post(`${URL}${postUrl}`,body,get_carrier_config())
  return data
}

export  const getDataAdmin= async (getUrl)=>{
  const {data} = await axios.get(`${URL}${getUrl}`,get_admin_config())
  return data
}

export  const putDataAdmin= async (body,putUrl)=>{
  const {data} = await axios.put(`${URL}${putUrl}`,body,get_admin_config())
  return data
}

export  const deleteDataAdmin= async (body,deleteUrl)=>{
  const {data} = await axios.delete(`${URL}${deleteUrl}`,body,get_admin_config())
  return data
}


export  const postDataAdmin= async (body,postUrl)=>{
  const {data} = await axios.post(`${URL}${postUrl}`,body,get_admin_config())
  return data
}

export const MINIMUM_SHIPMENT_PRICE = 30

export const PALLET_STOPS_PRICING = {
  a_to_a :5,
  a_to_b :10,
}
export const BOX_STOPS_PRICING = {
  a_to_a :3,
  a_to_b :6,
}

export const throttle = (func, limit) => {
  let inThrottle;
  return function () {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};


export const getNextWorkDay=(date, busDays=1) => { // add business days to a da=te
  var wkdy = date.getDay(); // get weekday number
  var addDays = wkdy >= 5 ? (7-wkdy + 1) : busDays; // if it's wednesday or later set add days to 5 instead of 3 to account for the weekend
  date.setDate(date.getDate() + addDays); // add days to current date
  return new Date(date)
}