import React, { Component } from "react";
import Images from "../../Pics/Images";
import { NavLink, withRouter, Link } from "react-router-dom";
import { getCarrierUserId, removeToken } from "../../LocalStorage/Auth";
import "../../Styles/NavBar.css";
import axios from 'axios';
import { trans } from "../../globals";
import ChangeLanguage from "../../locale/ChangeLanguage";
import logo from '../../Pics/logo_with_text.svg'
import bell from '../../Pics/interface.png'
import profile from '../../Pics/Account.png'
import NotificationButton from './Notifications/NotificationButton';
class NavBarCarrier extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      payment_status: '',
      invoice_id: '',
      exp_date: '',
    };
  }
  state = {
    showNotification: false,
  };
  handleNotification = () => {
    this.setState({ showNotification: !this.state.showNotification });
  };
  componentDidMount() {
   // this.getPaymentStatus();
  }
  getPaymentStatus = async () => {
    const username = JSON.parse(localStorage.getItem("Carrier_Obj"))
    const config = {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("Customer_AuthToken"),
        "Content-Type": "application/json",
      },
    };
    const requestbody = {
      carrier_id: username._id,
      status: 1
    }

    let data = await axios
      .post(`${URL}api/stripe/payment-status`, requestbody, config)
      .then(async function (response) {
        console.log('update-invoice response', response);
        return await response.data.data.invoice_data;
      })
      .catch(async (err) => {
        console.log(err);
        console.log(err.response);
        return await err.response;
      });

    this.setState({
      payment_status: data.status,
      invoice_id: data._id,
      exp_date: data.expiry_date
    });
  }


  signOutCarrier = () => {
    removeToken();
  };
  render() {
    var dateFormat = require('dateformat');

    const { payment_status, invoice_id, exp_date } = this.state;
    const today = new Date().toISOString();
    if (payment_status && payment_status == 0 && invoice_id) {
      if (dateFormat(today,'yyyy-mm-dd') > dateFormat(exp_date,'yyyy-mm-dd')) {
        
        window.location.href = `/carrier-payment/${invoice_id}`;
      }
      else
      {
        console.log("false")
      }
    }
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container">
          <div>
            <span className="navbar-brand">
              <button style={{background:'transparent',borderWidth:0,cursor:'pointer'}} onClick={()=>this.props.history.push('/CarrierHome')}>
                <img src={logo} alt="logo" />
                </button>
            </span>
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="border--color_Carrier justify-center">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink
                    to="/CarrierHome"
                    style={{ fontWeight: 500 }}
                    activeClassName="active"
                    className="nav--item"
                  >
                    {trans("common.navbar.search_shipments")}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/MyShipment"
                    style={{ fontWeight: 500 }}
                    activeClassName="active"
                    className="nav--item"
                  >
                     {trans("common.navbar.shipments")}
                  </NavLink>
                </li>
                
                <li className="nav-item">
                  <NavLink
                    to="/vehicalDashboard"
                    style={{ fontWeight: 500 }}
                    activeClassName="active"
                    className="nav--item"
                  >
                     {trans("common.navbar.vehicles")}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/driverDashboard"
                    style={{ fontWeight: 500 }}
                    activeClassName="active"
                    className="nav--item"
                  >
                     {trans("common.navbar.drivers")}
                  </NavLink>
                </li>
                
              </ul>
            </div>
          </div>
          <ChangeLanguage/>
          <NotificationButton
          user_id={getCarrierUserId()}
          queryString={['carriers','notifications',getCarrierUserId()]}
          type='carrier'
          /> 
          <div className="ml-auto">
            {/*-------NOTIFICATION------- */}
           

            {/* profile pic */}
            <div className="dropdown d-inline-block">
              <img
                src={profile}
                alt="userpic"
                className="dropdown-toggle img-fluid mr-3"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  width: "2em",
                  height: "2em",
                  cursor: "pointer",
                }}
              />
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
               
              <Link to="/Profile">
                <li
                  className="dropdown_item"
                  style={{ cursor: "pointer" }}
                >
                {trans("common.navbar.settings")} 
                </li>
                </Link> 
                <li
                  className="dropdown_item"
                  style={{ cursor: "pointer" }}
                  onClick={this.signOutCarrier}
                >
                   {trans("common.navbar.logout")}
                </li>
              </div>
            </div>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
    );
  }
}
export default withRouter(NavBarCarrier);
