import React,{useState} from 'react'
import { useQuery } from 'react-query';
import { getDataCustomer, getDataAdmin, getDataCarrier, notEmptyArray } from '../../../globals';

export default function FetchSingleRecord({queryString,getUrl,name1,name2,type}) {


const [data,setData] = useState('')
    // Get Data
const {isLoading,data:data2} = useQuery(queryString,()=> type==='customer'? getDataCustomer(getUrl):type==='admin'?getDataAdmin(getUrl):getDataCarrier(getUrl),{
    onSuccess:(data)=>{
        if(data.data){
            setData(`${data.data.customer[name1]}`)
        }
    },
  })


  return (
    <p>{isLoading?"Loading...":data}</p>
  )
}
