import React,{useState} from 'react'
import Form from '../../../Components/Common/Forms/Form'
import BoxContainer from '../../../Components/CustomerSide/ShipmentInformation/BoxContainer'
import { postDataCarrier, postDataAdmin, api_callback } from '../../../globals';
import { useMutation, useQueryClient } from 'react-query';
import * as yup from 'yup';
import Crud from '../../../Components/Common/Crud/Crud';
import AutoPriceCard from '../../Components/Cards/AutoPriceCard/AutoPriceCard';
import Row from '../../../Components/Common/Layout/Row';
import NavBar from '../../Components/NavBar/NavBar';
import { Drawer } from '@mui/material';
import { Button } from '@material-ui/core';

export default function Deals() {

    const [addRecordDrawerOpen,setaddRecordDrawerOpen] = useState(false)
    const queryClient = useQueryClient()
    const autoPriceMutation= useMutation((body)=>postDataAdmin(body,`api/autoprice`),{
        onSuccess: (data)=>{
            api_callback(true,"Success","Deals Added Succesfully")
            queryClient.invalidateQueries('autoPrice')
        },
        onError:(err)=>{
            console.log(err)
        }
      })

      const formikParams = {
        initialValues:{},
        onSubmit:body=>{
            //alert(JSON.stringify(body))
            autoPriceMutation.mutate(body)
        },
        validationSchema
    }

  return (
      <>
          <NavBar/>
           
            <Drawer
            PaperProps={{
                sx: { width: "50%" },
              }}    
            anchor='right'
            open={addRecordDrawerOpen}
            onClose={()=>setaddRecordDrawerOpen(false)}
            >
            <div className='p-4'>

                <Form
                    title='New Deal'
                    structure={formStructure}
                    formikParams={formikParams}
                />
            </div>
           </Drawer> 
            <BoxContainer
            withLine={false}
             additionalStyle={{
                width:'100vw',
                maxHeight:'70vh',
                paddingTop:'5em'
            }}
            >
           <Button variant='contained' sx={{backgroundColor:'#1abc9c',color:'white'}} onClick={()=>setaddRecordDrawerOpen(true)}>Add </Button>
                <Crud
                formStructure={formStructure}
                title='Auto Price'
                    queryString='autoPrice'
                    getUrl='api/autoprice'
                    type='admin'
                    responseNestedKey='data'
                    rowComponent={<AutoPriceCard/>}
                />
            </BoxContainer>


      </>

  )
}


const formStructure =[
    {
        type:"select",
        label:"Customer",
        name:"customer_id",
        queryString:"customers",
        getUrl:"api/crud/customers",
        isFetching:true,
        divClass:'col-sm-12 col-md-12'
    },
    {
        type:"input",
        label:"From Zone",
        name:"fromZone",
        divClass:'col-sm-12 col-md-6'
    },
    {
        type:"input",
        label:"To Zone",
        name:"toZone",
        divClass:'col-sm-12 col-md-6'
    },
    {
        type:"input",
        label:"length",
        name:"length",
        datatype:"number",
        divClass:'col-sm-12 col-md-3'
    }, 
    {
        type:"input",
        label:"width",
        name:"width",
        datatype:"number",
        divClass:'col-sm-12 col-md-3'
    },
    {
        type:"input",
        label:"Max Height",
        name:"height",
        datatype:"number",
        divClass:'col-sm-12 col-md-3'
    },
   
    {
        type:"input",
        label:"weight",
        name:"weight",
        datatype:"number",
        divClass:'col-sm-12 col-md-3'
    },
  
    {
        type:"input",
        label:"Min Quantity",
        name:"minQuantity",
        datatype:"number",
        divClass:'col-sm-12 col-md-6'
    },
    {
        type:"input",
        label:"Max Quantity",
        name:"maxQuantity",
        datatype:"number", divClass:'col-sm-12 col-md-6'
    },
    {
        type:"select",
        label:"Packaging",
        name:"packaging",
        options:[{label:"Pallets",value:"PALLET"},{label:"Box",value:"BOX"},{label:"Pallets with Cooling",value:'PALLET-COOL'}],
        divClass:'col-sm-12 col-md-6',
        isFetching:false
    },
    {
        type:"input",
        label:"Starting Price",
        name:"startingPrice",
        datatype:"number",
        divClass:'col-sm-12 col-md-6'
    },
    {
        type:"input",
        label:"Added Price",
        name:"addedPrice",
        datatype:"number",
        divClass:'col-sm-12 col-md-6'
    },
    

]

const validationSchema = yup.object().shape({
    fromZone: yup.string().required(),
    toZone: yup.string().required(),
    width: yup.number().min(1).required(),
    length: yup.number().min(1).required(),
    height: yup.number().min(1).required(),
    weight: yup.number().min(1).required(),
    startingPrice: yup.number().min(1).required(),
    addedPrice: yup.number().min(1).required(),
    maxQuantity: yup.number().min(1).required(),
    minQuantity: yup.number().min(1).required(),
  });