import React,{useState} from 'react'
import { palets_types, trans, kibvtia_types,time_options, notEmptyArray } from '../../../../globals';
import InputPlacesAutoComplete from '../../../Common/Forms/InputPlacesAutoComplete';
;

export default function Location({formik,shipment,handleFieldValue,handleChange}) {

   
    


    return (
        <div className={`shadow-sm p-4 bg-white`}>
          <p className={`pt-4 black_text`}>{trans("newshipment.locationtime.title")}</p>
          <p className='gray_text'> {trans("newshipment.locationtime.message")}</p>
          <div className='row'>

        
                    <div className='col-md-4 col-sm-12'>
                        <InputPlacesAutoComplete
                            label={trans('shipmentinfo.pickup_location')}
                            onChange={handleFieldValue}
                            defaultValue={shipment?.pickup?.location?.address}
                            name='pickup.location'
                            error={formik.errors?.pickup?.location?.coordinates}
                            />
                        </div>
                        
                        <div className='col-md-4 col-sm-12'>
                        <InputPlacesAutoComplete
                        label={trans('shipmentinfo.dropoff_location')}
                        defaultValue={shipment?.dropoff?.location?.address}
                        onChange={handleFieldValue}
                        name='dropoff.location'
                        error={formik.errors?.dropoff?.location?.coordinates}
                        />
                        </div>
                       
                     
            </div>
        </div>
    )
}
