import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { removeToken } from "../../LocalStorage/Auth";
import "../../Styles/NavBar.css";
import ChangeLanguage from "../../locale/ChangeLanguage";
import { trans } from "../../globals";
import {withRouter} from 'react-router'
import logo from '../../Pics/logo_with_text.svg'
import user from '../../Pics/Account.png'
import NotificationButton from './Notifications/NotificationButton'
import NavBarLogo from './NavBar/NavBarLogo';
import NavList from './NavBar/NavList';
import {getCustomerUserId} from '../../LocalStorage/Auth'
class NavBar extends Component {
  state = {
    showNotification: false,
    unreadNotifications:0
  };

  handleNotification = () => {
    this.setState({ showNotification: !this.state.showNotification });
  };
  signOutCustomer = () => {
    removeToken();
  };
  
  render() {
    return (
      <NavBarLogo>

         
          <NavList navlinks={navlinks}/>

          <ChangeLanguage/>
          <NotificationButton 
          user_id={getCustomerUserId().id}
          queryString={['customers','notifications',getCustomerUserId().id]}
          />
          <div className="ml-auto">
        

            {/* profile pic */}
            <div className="dropdown d-inline-block">
              <img
                src={user}
                alt="userpic"
                className="dropdown-toggle img-fluid"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  width: "2em",
                  height:"2em",
                  cursor: "pointer",
                }}
              />
              <div className="dropdown-menu rounded" aria-labelledby="navbarDropdown">
                <Link to="/CustomerAccount">
                <li
                  className="dropdown_item"
                  style={{ cursor: "pointer" }}
                >
                  {trans("common.navbar.settings")} 
                </li>
                </Link>
                <li
                  className="dropdown_item"
                  style={{ cursor: "pointer" }}
                  onClick={this.signOutCustomer}
                >
                   {trans("common.navbar.logout")}  
                </li>
              </div>
            </div>
          </div>

          {/* button toggler */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
      </NavBarLogo> 
    );
  }
}
export default withRouter(NavBar);


const navlinks=[
  {
    label: "common.navbar.home",
    link:'/Shipments'
  },
  {
    label: "common.navbar.newshipment",
    link:'/newshipment'
  },
  
]