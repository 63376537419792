import React,{ useEffect } from 'react';
import { Route, Redirect,useHistory } from 'react-router-dom';
import { getCustomer, getCustomerObj } from '../../LocalStorage/Auth';
import VerifyEmail from '../../pages/VerifyEmail';
import Telephone from './Telephone'

const ProtectedRouteC = ({ customer, path, component: Component, render }) => {

  useEffect(() => {
    let customer = getCustomerObj()
    if(customer!==null){

      
      if(Object.keys(customer).length>0){
        
        window.$crisp=[];
        window.$crisp.push(["set", "user:email", [customer.customer_email]])
        window.CRISP_WEBSITE_ID="7e09d75a-fa5d-46f2-9596-f9c9bf26e6a9";
        (function(){
        let d=document;
        let s=d.createElement("script");
        s.src="https://client.crisp.chat/l.js";
        s.async=1;
        d.getElementsByTagName("head")[0].appendChild(s); 
      })();
      }
    }
  }, [])
  
  return (
    <Route
      path={path}
      render={(props) => {
        if (!getCustomer()) return <Redirect to={`/customerlogin/${window.location.pathname.substring(1).replace("/","*")}`} />;
        if (Object.keys(getCustomer()).length > 0) customer = getCustomerObj();
        return !customer.email_verified ? (
          <VerifyEmail />
        ) : (
          <>
          <Component {...props} />
          <Telephone/>
          </>
        );
      }}
    />
  );
};

export default ProtectedRouteC;
