import React from 'react'
import NavBarLogo from '../../../Components/Common/NavBar/NavBarLogo';
import NavList from '../../../Components/Common/NavBar/NavList';

export default function NavBar() {
    
    return (
        <NavBarLogo>
            <NavList navlinks={navlinks}/>
        </NavBarLogo>
    )
}

const navlinks=[
    {
      label: "common.navbar.home",
      link:'/admin/shipments'
    },

    {
        label: "common.navbar.deals",
        link:'/admin/deals'
      },
      
      {
        label: "common.navbar.newshipment",
        link:'/admin/newshipment'
      },
    
    
  ]