import React, { Component } from "react";
import { connect } from "react-redux";
import ModalBody from "react-bootstrap/ModalBody";
import {
  AddVehicalCarrierSide,
  edit_vehicle,
  delete_vehicle,
} from "../../../Redux/Actions/actioncreators";
import { getCarrier, getCarrierUserId } from "../../../LocalStorage/Auth";
import { api_callback, trans } from "../../../globals";
import * as yup from 'yup';
import InputField from "../../Common/InputField";
import OptionField from "../../CustomerSide/OptionField";
import { carrierServices } from "../../../Services/Carriers/carrier_sevices";
import closeButton from '../../../Pics/close.png';

const vehicleSchema= yup.object().shape({
  vehicle_name: yup.string().required().label('Name'),
  registeration_num: yup.string().required().label('Registration Number'),
  vehicle_type: yup.string().required().label('Vehicle Type'),
  vehicle_capacity:yup.number().required().label('Capacity'),
  vehicle_weight:yup.number().required().label('Weight'),
  vehicle_model:yup.string().required().label('Model'),
 });


class AddVehicalModal extends Component {
  state = {
    token: getCarrier(),
    data: { ...this.returnState() ,},
    errors:{}
  };

  returnState() {
    if (this.props.title === "EditOrdelete_vehicle") {
      return this.props.data;
    } else{
      return {vehicle_type:'Κόφα'}
    }

  }
  
  changeHandler = ({ target }) => {
    const data = { ...this.state.data };
    data[target.name] = target.value;
    this.setState({ data });
  };
  //INPUT FIELDS OF MODAL
  renderInput(labelTitle, fieldname, typefield) {
    const { data } = this.state;
    return (
        <InputField
          labeltitle={labelTitle}
           typename={typefield}
           fieldname={fieldname}
           value={data[fieldname]}
           changeHandler={this.changeHandler}
           error={this.state.errors[fieldname]}
        />
    );
  }
  //-----------------------------Options -------------------------------------
  renderOptionField=(label, name, options)=> {
    const { data, error } = this.state;
    return (
      <OptionField
        labelTitle={label}
        name={name}
        Option={options}
        value={data[name]}
        error={this.state.errors[name]}
        changeHandler={this.changeHandler}
      />
    );
  }
//-----------------------------------------------ADD VEHICLE----------------------------------------------
  addVehical = () => {
    const  _id  = getCarrierUserId();
    const { data } = this.state;
    const requestBody = {
      vehicle_name: data.vehicle_name,
      registeration_num: data.registeration_num,
      vehicle_model: data.vehicle_model,
      vehicle_type: data.vehicle_type,
      vehicle_capacity: data.vehicle_capacity,
      vehicle_weight: data.vehicle_weight,
      carrier_id: _id,
    };
    vehicleSchema.validate(requestBody,{abortEarly:false})
    .then(res=>{
      this.props.addVehical(requestBody,this.AddVehicalSucess);
    })
    .catch((err)=> {
      let errors={}
      err.inner.map(err=>{
         errors[err.path] =err.message;
       });
       this.setState({errors,loadingButton:false});
      });
  };

  AddVehicalSucess = () => {
    api_callback(true,'Επιτυχία','Προσθέσατε ένα φορτηγό')
    window.location.reload();
  };
 
//-----------------------------------------------END ADD VEHICLE----------------------------------------------
//-----------------------------------------------EDIT VEHICLE----------------------------------------------
  edit_vehicle = () => {
    
    const vehicalId =this.props.data._id;
    const { data } = this.state;
    const requestBody = {
      vehicle_name: data.vehicle_name,
      registeration_num: data.registeration_num,
      vehicle_model: data.vehicle_model,
      vehicle_type: data.vehicle_type,
      vehicle_capacity: data.vehicle_capacity,
      vehicle_weight: data.vehicle_weight,
    };

     vehicleSchema.validate(requestBody,{abortEarly:false})
    .then(res=> this.props.editVehicle(requestBody,vehicalId,this.editDeleteSuccess))
    .catch((err)=> {
      let errors={}
      if(err){
        if(Array.isArray(err.inner)){
          err.inner.map(err=>{
            errors[err.path] =err.message;
          });
          this.setState({errors,loadingButton:false});
        }
      }
     
      });
  };
  //-----------------------------------------------END EDIT  VEHICLE----------------------------------------------
//-----------------------------------------------DELETE VEHICLE----------------------------------------------
  delete_vehicle = () => {
    const id = this.props.data._id;
    this.props.deleteVehicle(id,this.editDeleteSuccess);
  };

  editDeleteSuccess = () => {
    api_callback(true,'Επιτυχία','Επιτυχία')
    this.props.handleModal();
    window.location.reload();
  };
  editDeleteFail = () => {
    api_callback(false,'Αποτυχία','Error Occured!')
  };
  //-----------------------------------------------END DELETE VEHICLE----------------------------------------------
  render() {
    console.log(this.props.data)
    return (

      <ModalBody>
        <div className="p-4">
          <div className="container">
            <button
          style={{
            position: "fixed",
            top: 10,
            right: 10,
            background: "transparent",
            border: 0,
          }}
          onClick={this.props.handleModal}
        >
          <img style={{height:'2em',width:'2em'}}src={closeButton}></img>
        </button>
        <p className="black">
              {trans('vehicles.edit.subtitle')}
            </p>
            <p className="gray_text">
              {trans('vehicles.edit.title')}
            </p>
            <div>
              <div className="form-row">
                <div className="form-group col-md-4">
                  {this.renderInput("vehicles.edit.name", "vehicle_name", "text")}
                </div>
                <div className="form-group col-md-4">
                  {this.renderInput(
                    "vehicles.edit.plate",
                    "registeration_num",
                    "text"
                  )}
                </div>
                <div className="form-group col-md-4">
                  {this.renderInput("vehicles.edit.model", "vehicle_model", "text")}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-4">
                  {this.renderOptionField("vehicles.edit.type", "vehicle_type", [
                  "Κόφα",
                  "Ψυγείο",
                  "Μουσαμάς"
                ])}
                </div>
                <div className="form-group col-md-4">
                  {this.renderInput( "vehicles.edit.capacity", "vehicle_capacity","number")}
                </div>
                <div className="form-group col-md-4">
                  {this.renderInput(
                    "vehicles.edit.weight",
                    "vehicle_weight",
                    "number"
                  )}
                </div>
              </div>
             
              {this.props.title === "EditOrdelete_vehicle" ? (
                <div className="row">
                  <div className="col-md-4">
                    <button
                      onClick={this.edit_vehicle}
                      className="btn btn-success btn-block"
                    >
                        {trans('common.buttons.save')}
                    </button>
                  </div>
                  <div className="col-md-4">
                    <button
                      className="btn btn-danger btn-block"
                      onClick={this.delete_vehicle}
                    >
                      {trans('common.buttons.delete')}
                    </button>
                  </div>
                  
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-4">
                    <button
                      onClick={this.addVehical}
                      className="btn btn-primary btn-block"
                    >
                        {trans('common.buttons.save')}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
    );
  }
}
const mapDispatchToProos = (dispatch) => {
  return {
    addVehical: (body,callback ) =>dispatch(carrierServices.addVehicle(body, callback,)),
    editVehicle: (body, id, callback) =>dispatch(carrierServices.editVehicle(body,id,callback,)),
    deleteVehicle: (id, callback) =>dispatch(carrierServices.deleteVehicle(id, callback)),
  };
};
export default connect(null, mapDispatchToProos)(AddVehicalModal);
