import React, { Component } from "react";
import OfferModalForm from "./OfferModalForm";
import "../../../Styles/OfferModal.css";
import ShipmentFullCard from "../../Common/Shipments/ShipmentFullCard/ShipmentFullCard";
import MyModal from "../../Common/Modals/MyModal";
import AcceptDeclineShipmentForm from "./AcceptDeclineShipmentForm";

export default function OfferModal({shipmentobj,offerModalVisible,modalVisibility,showMakeOfferForm,showAcceptDeclineShipmentForm=false}) {
  return (
    <MyModal 
    show={offerModalVisible} 
    hasHeader={true}
    title='Πληροφορίες Μεταφοράς' 
    setShow={modalVisibility} 
    dialogClassName='modal-70w'
    >
          
          <ShipmentFullCard
          isContainer={false}
          hasTitle={false} 
          shadowClass='' 
          showPrice={false} 
          showStatusChange={false} 
          shipment={shipmentobj}/>    
            {/* form */}
            {showMakeOfferForm &&
            <OfferModalForm
              shipment_id={shipmentobj._id}
              modalHide={modalVisibility}
              shipment={shipmentobj}
            />
            }
            {showAcceptDeclineShipmentForm &&
            <AcceptDeclineShipmentForm setShowFormModal={modalVisibility} price={shipmentobj.price} shipment_id={shipmentobj._id}/>
            }


    </MyModal>
  )
}
