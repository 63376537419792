import React from 'react'
import MyModal from '../../../Common/Modals/MyModal';
import { useFormik } from 'formik';
import RatingWidget from 'react-rating-widget'
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Button, Input } from '@material-ui/core';
import MyInput from '../../../Common/Forms/MyInput';
import { api_callback, putDataCustomer } from '../../../../globals';
import { useMutation,  useQueryClient } from 'react-query';
function RatingModal({show,setShow,shipment_id}) {
    
    const formik = useFormik({
        initialValues: {},
        onSubmit:(body)=>{
          let mybody ={...body}
          if(shipment_id){
            mybody.shipment_id = shipment_id
            ratingMutation.mutate(mybody)
          }else{
            alert("Please try again")
          }
        }
       
      });
      const queryClient = useQueryClient()
      const ratingMutation = useMutation((body)=> putDataCustomer(body,`api/shipments/update/rating`),{
        onSuccess:(data)=>  {
           queryClient.invalidateQueries(['customer','shipmentinfo',shipment_id])
           api_callback(true,"Επιτυχία",'H Βαθμολογία σας καταχωρήθηκε') 
           setShow(false)
        } 
    })

      const storeAnswers = (answer)=>{
          console.log("You have rated us " + answer.answer);
          formik.setFieldValue('rating',parseInt(answer.answer))
      }

    return (
        <MyModal 
        show={show} 
        setShow={setShow} 
        hasHeader={true} 
        title='Βαθμολόγησε τον μεταφορέα'
        >
        <form onSubmit={formik.handleSubmit}>
        <RatingWidget 
        iconCount={5}
        IconStyleActive = { ActiveStar }
        IconStyleInactive =  { InactiveStar }
        reactionLables={ratingReactions}
        reactionStyle= {reactionStyle}
        storeAnswers={storeAnswers}
        />
       
        <Button 
        type='submit'
        variant='contained' 
        color='primary'
        style={{marginTop:'2em'}}
        >
        Καταχωρηση Βαθμολογιας
        </Button>
        </form>            

        </MyModal> 
    )
}

export default RatingModal


const ActiveStar = () =>  <StarIcon style={{color:'#f1c40f',fontSize:50}} />
const InactiveStar = () => <StarBorderIcon style={{color:'#f1c40f',fontSize:50}}/>;
   
  const ratingReactions = {1: "", 2: "", 3: "", 4: "", 5: ""};
      
  const reactionStyle = {
      textAlign: 'center',
      padding: '7px 0 4px',
    };
   