import React, { useEffect ,useState} from 'react';
import './Styles/style.scss';
import './Styles/bootstrap.css';
import './Styles/bootstrap-grid.min.css';
import { BrowserRouter, Route, Switch,useLocation} from 'react-router-dom';
import CustomerLogin from './Components/Auth/Pages/CustomerLogin';
import Invoice from './Components/Invoice/Invoice'
import CarrierLogin from './Components/Auth/Pages/CarrierLogin';
import GuestShipment from './Components/CustomerSide/GuestShipment/GuestShipment';
import CustomerSignup from './Components/Auth/Pages/CustomerSignup';
import CarrierSignup from './Components/Auth/Pages/CarrierSignup';
import CustomerAccount from './Components/CustomerSide/Profile/CustomerAccount';
import { default as CustomerShipmentInformation } from './Components/CustomerSide/ShipmentInformation/index';
import ShipmentInformation from './Components/CarrierSide/ShipmentInformation/ShipmentInformation';
import CarrierAccount from './Components/CarrierSide/Profile/Account';
import NewShipment from './Components/CustomerSide/NewShipment/NewShipment';
import Shipments from './Components/CustomerSide/Shipments/Shipments';
import SearchShipments from './Components/CarrierSide/SearchShipments/SearchShipments';
import Vehicles from './Components/CarrierSide/Vehicle/Vehicles';
import DriverDashboard from './Components/CarrierSide/Drivers/DriverDashboard';
import { default as CarrierShipments } from './Components/CarrierSide/Shipments/Shipments';
import { default as AdminLogin} from './Admin/Pages/Login/Login';
import { default as AdminDeals} from './Admin/Pages/Deals/Deals';
import ProtectedRouteC from './Components/Common/ProtectedRouteC';
import ProtectedRCarrier from './Components/Common/ProtectedRCarrier';
import {
  getCustomerUserId,
  getCustomer,
} from './LocalStorage/Auth';
import { handleNotifications, getDataCustomer } from './globals';
import './Styles/react_dates_overrides.css';
import VerifyEmail from './pages/VerifyEmail';
import CustomerVerification from './pages/EmailVerification/CustomerVerification';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import TrackRoutes from './TrackRoutes';
import {default as AdminShipment} from './Admin/Components/Shipments/Shipments'
import {default as AdminShipmentInformation} from './Admin/Components/ShipmentInformation/ShipmentInformation'
import {default as AdminNewShipment} from './Admin/Pages/Shipments/NewShipment'

const App =()=>{



//get customer by id
const {isLoading,data} = useQuery(['customer',getCustomerUserId()?.id],()=> getDataCustomer(`api/customers/${getCustomerUserId()?.id}`),{
  staleTime:2000,
  cacheTime:5000,
  
  onSuccess:(data)=>{
    console.log(data.data.customer)
    setcustomer(data.data.customer)
  },
  onError:err=>console.log(err)
})
const [customer, setcustomer] = useState({})

useEffect(() => {
    if (getCustomer() &&Object.keys({}).length === 0) {
      handleNotifications();
   }
}, [])


  return (
    <div style={{ background: '#f4f6fc', height: '100%' }}>
    <BrowserRouter onChange={()=>alert("fef")}>
      <TrackRoutes/>
      <Switch >
        
        {/* Form */}
        <Route

          path="/carrierlogin"
          exact
          render={(props) => <CarrierLogin {...props} />}
        />
          <Route
          path="/customerlogin/:redirect?"
          exact
          render={(props) => <CustomerLogin {...props} />}
        />
        <Route
          path="/verifyemail"
          exact
          render={(props) => <VerifyEmail {...props} />}
        />
        <Route
          path="/customer/emailverification/:customerid/:token"
          exact
          render={(props) => <CustomerVerification {...props} />}
        />


        <Route
          path="/guestshipment/:description/:pickup_location/:pickup_coord_0/:pickup_coord_1/:dropoff_location/:dropoff_coord_0/:dropoff_coord_1"
          exact
          render={(props) => <GuestShipment {...props} />}
        />
        <Route path="/customersignup" render={(props) => <CustomerSignup {...props} />} />
        <Route path="/carriersignup" render={(props) => <CarrierSignup {...props} />} />

        {/* Customer Side */}
        <ProtectedRouteC
          customer={customer}
          path="/Shipments"
          component={Shipments}
        />
        <ProtectedRouteC
          customer={customer}
          path="/invoice/:shipment_id/:customer_id"
          component={Invoice}
        />
        <ProtectedRouteC
          customer={customer}
          path="/shipment/:id"
          component={CustomerShipmentInformation}
        />
        <ProtectedRouteC path="/newshipment/:repeat?/:shipment_id?" component={NewShipment} />
        <ProtectedRouteC
          path="/CustomerAccount"
          component={CustomerAccount}
        />
        {/* Carrier Side */}
        <ProtectedRCarrier
          path="/CarrierHome"
          component={SearchShipments}
        />
        <ProtectedRCarrier path="/Profile" component={CarrierAccount} />
        <ProtectedRCarrier
          path="/MyShipment"
          component={CarrierShipments}
        />
        <ProtectedRCarrier
          path="/carrier_shipment/:id"
          component={ShipmentInformation}
        />
        <ProtectedRCarrier path="/vehicalDashboard" component={Vehicles} />
        <ProtectedRCarrier
          path="/driverDashboard"
          component={DriverDashboard}
        />
        <Route
          path="/admin/login"
          component={AdminLogin}
        />
        <Route
          path="/admin/shipments"
          component={AdminShipment}
        />
        <Route
          path="/admin/deals"
          component={AdminDeals}
        />
         <Route
          path="/admin/shipment/:id"
          component={AdminShipmentInformation}
        />
         <Route
          path="/admin/newshipment"
          component={AdminNewShipment}
        />
       
      </Switch>
    </BrowserRouter>
  </div>
  )
}

export default App;

