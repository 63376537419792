import React from 'react'
import PropTypes from 'prop-types'
import Row from '../../../Common/Layout/Row'
import Divider from '@material-ui/core/Divider'
import BoxContainer from '../BoxContainer'
import { Typography } from '@material-ui/core'
import { trans } from '../../../../globals'
import DriverStateBardge from '../../../Common/Badges/DriverStateBadge/DriverStateBardge';
function ShipmentState({state}) {

    return (
         <div>
              <Typography variant='h5' color='primary'>Εξέλιξη Μεταφοράς</Typography>
            {state.map((s,index)=><StateCard state={s} index={index+1} />)}
         </div>
    )
}

ShipmentState.propTypes = {
    state: PropTypes.array.isRequired,
}

export default ShipmentState


const StateCard = ({state,index})=>{
    const createdAt = new Date(state.createdAt)
    return (
        <div style={{marginTop:'.2em'}}>
        <Row style={{justifyContent:'space-between'}}>
         <StateMap index={index} state={state.state}/>
         <DriverStateBardge driver_state={state.state} showLastOne={false}/>
        </Row>
            <Row style={{justifyContent:'space-between'}}>
                <p className='gray_text'>Σχολια : {state.comment|| " - "}</p>
                <p className='black_text'>{createdAt.toLocaleDateString()} {minTwoDigits(createdAt.getHours())}:{minTwoDigits(createdAt.getMinutes())}</p>
            </Row>
        <Divider/>
        </div>
    )    
}


const StateMap =({index,state})=>{
    if(states.includes(state)){
      return <Typography color='black' style={{fontWeight:'bold'}}>{index}. {trans(`states.${String(state).toUpperCase()}`)}</Typography>
    }else{
        return null
    }

}
function minTwoDigits(n) {
    return (n < 10 ? '0' : '') + n;
  }
  

const states =['START','PICKUP','DROPOFF','END']