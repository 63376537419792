import React from "react";
import OfferApiCall from "./OfferApiCall";
import { connect } from "react-redux";
import {
  AcceptSimpleCustomerOffer,
} from "../../../Redux/Actions/actioncreators";
import { trans } from "../../../globals";
import Column from "../../Common/Layout/Column";
import dateformat from 'dateformat'
class OfferModule extends OfferApiCall {
  constructor(props) {
    super(props);
    this.state = {
      modals_visibility:false,
      carriers_selected: [],
      split_modal_later: null,
      nosplit_modal_visibility: false,
      shipment_id: null,
      shipment: null,
      successModalShow:false
	
    };
  }
  setsuccessModalShow =(val)=>this.setState({setsuccessModalshow:val})

  

  render() {
    const {
      date_pickup,
      date_dropoff,
      cost,
      offer_type,
      time_pickup,
      time_dropoff,
      _id: offerID,
      carrier,
    } = this.props.offer;

    const pickup_date= date_pickup.slice(0, 10);
    const dropoff_date = date_dropoff.slice(0, 10);
    const {shipment} =this.props
    
     return (
        <React.Fragment>
        
          <div className="row">
            <div className="col-5">
              <Column>
              <span className="fontbold fontsize mb-1">{trans("shipmentinfo.pickup_date")}</span>
              <span className="gray_text">{dateformat(pickup_date,'dd-mm-yyyy')}</span>
              </Column>
              <Column style={{marginTop:'.5em'}}>
              <span className="fontbold fontsize mb-1">{trans("shipmentinfo.dropoff_date")}</span>
              <span className="gray_text">{dateformat(dropoff_date,'dd-mm-yyyy')}</span>
              </Column>
            </div>
            <div className="col-4">
            <Column>
              <span className="fontbold fontsize mb-1 d-block">{trans("shipmentinfo.time")}</span>
              <span className="gray_text">{time_pickup}</span>
              </Column>
              <Column style={{marginTop:'.5em'}}>
              <span className="fontbold fontsize mb-1 d-block">{trans("shipmentinfo.time")}</span>
             <span className="gray_text">{time_dropoff}</span>
             </Column>
            </div>
            <div className="col-2">
              <span className="fontbold fontsize   ">{trans("shipmentinfo.price")}</span>
              <span className="gray_text d-block  ">{cost} €</span>

              {shipment.offer_accepted &&
              shipment.offer_accepted === this.props.offer._id ? (
                <button className="btn mybtn-green mx-auto disabled ">
                  Accepted
                </button>
              ) : (
                <button className="nobg_btn_purple "
                style={{marginLeft:'-1.2em',marginTop:'1em'}} 
                onClick={() => {
                  this.offerClicked(offerID, shipment, carrier._id);
                }}
                >{trans('common.buttons.accept')}</button>
              )}
            </div>
            
          </div>
          <hr />
      
        </React.Fragment>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    CustomerShipments: state.customerShipmentReducer.CustomerShipments,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
   
    AcceptSimpleOffer: (
      requestbody,
      config,
      shipment,
      callback
    ) =>
      dispatch(
        AcceptSimpleCustomerOffer(
          requestbody,
          config,
          shipment,
          callback,
        )
      ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferModule);

