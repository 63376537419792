import React, { Component } from "react";
import { connect } from "react-redux";
import { getCarrierUserId, getCarrier } from "../../../LocalStorage/Auth";
import { api_callback, get_carrier_config, notEmptyArray, time_options, trans } from "../../../globals";
import dateformat from 'dateformat'
import { send_offer_async } from "../../../Services/Carriers/CarrierOfferSend";
import Select from 'react-select'
import { stubTrue } from "lodash";

class OfferModalForm extends Component {
  state = {
    deliverydate:dateformat(new Date(), "yyyy-m-dd"),
    pickupdate:dateformat(new Date(), "yyyy-m-dd"),
    pickuptime:'09:00 - 12:00',
    deliverytime:'09:00 - 12:00',
    price:'',
    soffer_price: '',
    soffer_type: "",
    soffer_pickupdate :dateformat(new Date(), "yyyy-m-dd"),
    soffer_pickuptime: "09:00 - 12:00",
    soffer_deliverydate:dateformat(new Date(), "yyyy-m-dd"),
    soffer_deliverytime: "09:00 - 12:00",
    offerSend: false,
	second_offer:false,
	second_offer_error:false,
  pickup_time_index:null,
  dropoff_time_index:null
  };

  componentDidMount(){
    console.log(this.props)
    if(this.props.shipment){
      if(notEmptyArray(this.props.shipment.data)){
        if(this.props.shipment.data[0].pickup.date){
          this.setState({pickupdate:dateformat(this.props.shipment.data[0].pickup.date.slice(0, 10),'yyyy-mm-dd')})
        }
        if(this.props.shipment.data[0].dropoff.date){
          this.setState({deliverydate:dateformat(this.props.shipment.data[0].dropoff.date.slice(0, 10),'yyyy-mm-dd')})
        }
      }
   
     if(this.props.shipment.pickup_time ){
      this.setState({pickuptime:this.props.shipment.pickup_time,pickup_time_index:time_options.findIndex(o=>o.value==this.props.shipment.pickup_time)})
    }else{
        this.setState({pickup_time_index:2})
    }
    if(this.props.shipment.dropoff_time){
      this.setState({deliverytime:this.props.shipment.dropoff_time,dropoff_time_index:time_options.findIndex(o=>o.value==this.props.shipment.dropoff_time)})
    }else{
      this.setState({dropoff_time_index:2})
    }
  
  }
    }

  changeHandle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  
   sofferVisibility = () => {
    this.setState({ second_offer: !this.state.second_offer });
  };
  
  SendOffer = (e) => {
    const  _id = getCarrierUserId();
    const ShipmentID = this.props.shipment_id;
    let requestbody ={}
	if(this.state.second_offer)
	{
		if(this.state.price !='' && this.state.pickupdate !='' && this.state.deliverydate !='' && this.state.pickuptime !='' && this.state.deliverytime !='')
		{
			this.setState({ second_offer_error: false });
			   requestbody = {
			  carrier: _id,
			  second_offer:this.state.second_offer,
			  cost: this.state.price,
			  offer_type: this.state.type,
			  date_pickup: this.state.pickupdate,
			  date_dropoff: this.state.deliverydate,
			  time_pickup: this.state.pickuptime,
			  time_dropoff: this.state.deliverytime,
			  soffer_cost: this.state.soffer_price,
			  soffer_offer_type: this.state.soffer_type,
			  soffer_date_pickup: this.state.soffer_pickupdate,
			  soffer_date_dropoff: this.state.soffer_deliverydate,
			  soffer_time_pickup: this.state.soffer_pickuptime,
			  soffer_time_dropoff: this.state.soffer_deliverytime,
			  shipment_id: ShipmentID,
			};
		
		}else{
			this.setState({ second_offer_error: true });
		}
	}else{	
     requestbody = {
    carrier: _id,
    cost: this.state.price,
	  offer_type: this.state.type,
    date_pickup: this.state.pickupdate,
    date_dropoff: this.state.deliverydate,
	  time_pickup: this.state.pickuptime,
    time_dropoff: this.state.deliverytime,
	  second_offer:this.state.second_offer,
    shipment_id: ShipmentID,
    };
  }
  this.props.SendOffer(
    requestbody,
  get_carrier_config(),
  this.offer_send_callback
  );
            
   e.preventDefault();
  };
offer_send_callback =(success)=>{
    if(!success){
      return false
    }
    window.location.reload();
   }
  renderInput(labelTitle, fieldname, typename,defaultValue='rr3') {
    return (
      <>
        <label className="flabelStyle">{trans(labelTitle)}</label>
        <input
          type={typename}
          className="form-control"
          name={fieldname}
          value={this.state[fieldname]}
          onChange={this.changeHandle}
          defaultValue={defaultValue}
          required
        />
      </>
    );
  }
  render() {
    console.log(this.state.pickuptime)  
    console.log(time_options.findIndex(o=>o.value==this.state.pickuptime))
    return (
      <div className="mt-5">
        <form onSubmit={this.SendOffer}>
        <span className="font15">{trans("shipmentinfo.offers.makeoffer")}</span>
          <div className="form-row mt-3">
            <div className="form-group col-md-4">
              {this.renderInput("shipmentinfo.price", "price", "number")}
              <p className='blue_text'>{trans('novat')}</p>
            </div>
           
          </div>
          <div className="form-row mt-3">
            <div className="form-group col-md-3">
              {this.renderInput("shipmentinfo.pickup_date", "pickupdate", "date",this.state.pickupdate)}
            </div>
            <div className="form-group col-md-3">
            <label className="flabelStyle">{trans("shipmentinfo.time")}</label>
             {this.state.pickup_time_index!=null && <Select defaultValue={time_options[this.state.pickup_time_index]} onChange={(e)=>this.setState({pickuptime:e.value})} options={time_options} />}
             </div>
            <div className="form-group col-md-3">
              {this.renderInput("shipmentinfo.dropoff_date", "deliverydate", "date",this.state.deliverydate)}
            </div>
            <div className="form-group col-md-3">
            <label className="flabelStyle">{trans("shipmentinfo.time")}</label>
              {this.state.dropoff_time_index!=null && <Select defaultValue={time_options[this.state.dropoff_time_index]} onChange={(e)=>this.setState({deliverytime:e.value})} options={time_options} />}
            </div>
          </div>
		  
		   {this.state.second_offer && (
		  <div className="second_offer">
		  {this.state.second_offer_error && (
		  <span className="font15 f_error">Please fill all fields</span>
		  )}
		      <div className="form-row mt-3">
				<div className="form-group col-md-4">
				  {this.renderInput("shipmentinfo.price", "soffer_price", "number")}
				</div>
			
			  </div>
			  <div className="form-row mt-3">
				<div className="form-group col-md-3">
				  {this.renderInput("shipmentinfo.pickup_date", "soffer_pickupdate", "date")}
				</div>
				<div className="form-group col-md-3">
        <label className="flabelStyle">{trans("shipmentinfo.time")}</label>
              <Select defaultValue={time_options[this.state.pickup_time_index]} onChange={(e)=>this.setState({soffer_pickuptime:e.value})} options={time_options} />
            </div>
				<div className="form-group col-md-3">
				  {this.renderInput("shipmentinfo.dropoff_date", "soffer_deliverydate", "date")}
				</div>
         <div className="form-group col-md-3">
         <label className="flabelStyle">{trans("shipmentinfo.time")}</label>
              <Select defaultValue={time_options[2]} onChange={(e)=>this.setState({soffer_deliverytime:e.value})} options={time_options} />
            </div>
            </div>
		  </div>
		  ) }
          <div className="form-row mt-6">
            <div className="form-group col-md-3 col-sm-12">
              <button className="btn btn-primary " type='submit'>{trans("common.buttons.submit")}</button>
            </div>
			<div className="form-group col-md-3 col-sm-12 offset-md-6">
			  <button className="nobg_btn_blue float-right" type="button" onClick={this.sofferVisibility}>{trans("shipmentinfo.offers.addsecondoffer")}</button>
            </div>
          
          </div>
       </form>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    SendOffer: (requestbody, config,callback ) => dispatch(send_offer_async(requestbody,config,callback))
  };
};
export default connect(null, mapDispatchToProps)(OfferModalForm);
