import { Component } from "react";
import { getCustomer } from "../../../LocalStorage/Auth";
import {api_callback} from '../../../globals'
import { shipmentServices } from "../../../Services/Shipments/shipment_services";
export default class OfferApiCall extends Component {

  offerClicked = (offerId, shipment, carrierid) => {
    const	body = {
      carrier: carrierid,
      _id: offerId,  
  }
  shipmentServices.acceptOffer(body,shipment._id,this.acceptOfferCallback)
  };

  acceptOfferCallback = (res)=>{
    this.props.setshowOfferSuccessModal(true)
  }

  //-----------SIMPLE OFFER------------
  simpleOfferApi = (token, carrierID, offerID, shipmentID) => {
    console.log("carrierID", carrierID);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const requestbody = {
      carrier: carrierID,
      _id: offerID,
    };
    const shipment = {
      id: shipmentID,
    };
    this.props.AcceptSimpleOffer(
      requestbody,
      config,
      shipment,
      api_callback,
     
    );
  };
  
}


