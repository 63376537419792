import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { add_carrier_payment_method_async } from './api';
import { api_callback } from '../../../globals';
import InputField from '../../Common/InputField';
import { getCarrierUserId } from '../../../LocalStorage/Auth';

export class AddPaymentMethod extends Component {

    constructor(props){
        super(props)
        this.state ={
            carrier:{}
        }
    }
    componentDidMount=()=>{
        if(this.props.carrier){
           this.setState({carrier:this.props.carrier})
        }
    }
    addPaymentMethod =(e)=>{
        console.log(this.state)
        e.preventDefault();
        if(this.state.carrier.carrier_payment_public_key && this.state.carrier.carrier_payment_secret_key){
        const body = {
            carrier_id:getCarrierUserId(),
            carrier_payment_public_key:this.state.carrier.carrier_payment_public_key,
            carrier_payment_secret_key:this.state.carrier.carrier_payment_secret_key,
        }  
        this.props.addPaymentMethod(body);

       }else{
       api_callback(false,'Αποτυχία','Please Input your Keys.')
       }
    }
    changeHandler =(e)=>{
        let carrier = this.state.carrier; 
        carrier[e.target.name]=e.target.value
        this.setState({carrier})
    }
    renderInput=(labelTilte, fieldname, fieldType = "text")=> {

        const  state = this.state.carrier;
    
        return (
          <React.Fragment>
            <label className="gray_text">{labelTilte}</label>
            <input
              type={fieldType}
              name={fieldname}
              className="form-control"
              onChange={this.changeHandler}
              value={state[fieldname]}
              required
            />
          </React.Fragment>
        );
      }

    render() {
        return (
         <div className='container bg-white'>
            <form className='row' onSubmit={this.addPaymentMethod}>
                <div className='col-6'>
              {this.renderInput("Public Key", "carrier_payment_public_key")}
              </div>
            <div className='col-6'>
              {this.renderInput("Secret Key", "carrier_payment_secret_key")}
              </div>
              <button type='submit' className="btn  mt-3  mb-4 btn-primary">{this.props.loading ? 'Loading ...' : 'Add Payment'}</button> 
            </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.carrierReducer.loading,
    carrier:state.carrierReducer.Carrier
})

const mapDispatchToProps = (dispatch)=>{return {
    addPaymentMethod: (body) => dispatch(add_carrier_payment_method_async(body)),
}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentMethod)
