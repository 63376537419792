import React from 'react'
import { trans } from '../../../globals';
import Row from "../../Common/Layout/Row";
function BoxContainer({children,title,titleSubComponent,showSubComponent=true,withLine=true,
additionalStyle={height: "40vh",
maxHeight:390,
minHeight:350,}}) {
    return (
     <div className="container p-4 shadow-sm"
        style={{
          backgroundColor: "#ffffff",
          ...additionalStyle,
          overflowX: "none",
          overflowY: "auto",
          msOverflowStyle: "none",
        }}
      >
      
     <Row style={{justifyContent:'space-between'}}>
       <span className="blue_text fontsize">{trans(title)}</span>
       {titleSubComponent}
     </Row>
     {withLine &&<hr/>}
       {children} 
     </div>
    )
}

export default BoxContainer
