import React from 'react'
import MyInput from '../../../Common/Forms/MyInput';
import { trans, notEmptyArray } from '../../../../globals';
import { IconButton } from '@mui/material';
import DeleteOutline from '@mui/icons-material/DeleteOutline';

export default function ProductsSection({formik,handleChange,index=0,deleteProduct,id}) {



    return (
       <div className={`p-4 pb-1 bg-white`}>
         
          <div className='row'>

                <p className='gray_text'>{index+1}.</p>
                <div className='col-md-3 col-sm-12'>
                        <MyInput
                        name={`shipment_attributes[${index}].description`}
                        label ={trans('shipmentinfo.description')}
                        value={notEmptyArray(formik.values.shipment_attributes,index)&&formik.values.shipment_attributes[index]?.description}
                        error={notEmptyArray(formik.errors.shipment_attributes,index)&&formik.errors.shipment_attributes[index]?.description}
                        onChange={handleChange} 
                        type='text'
                         />
                    </div>
                    
                    <div className='col-md-2 col-sm-12'>
                        <MyInput
                        name={`shipment_attributes[${index}].quantity`}
                        label ={trans('shipmentinfo.quantity')}
                        value={notEmptyArray(formik.values.shipment_attributes,index)&&formik.values.shipment_attributes[index]?.quantity}
                        error={notEmptyArray(formik.errors.shipment_attributes,index)&&formik.errors.shipment_attributes[index]?.quantity}
                        onChange={handleChange} 
                        type='number'
                         />
                    </div>
                    
                    <div className='col-md-2 col-sm-12'>
                        <MyInput 
                         name={`shipment_attributes[${index}].weight`}
                        label ={`${trans('shipmentinfo.weight')} (kg)`}
                        value={notEmptyArray(formik.values.shipment_attributes,index)&&formik.values.shipment_attributes[index]?.weight}
                        error={notEmptyArray(formik.errors.shipment_attributes,index)&&formik.errors.shipment_attributes[index]?.weight}
                        onChange={handleChange} 
                         />
                        </div>

                    
                        <div className=' col-md-2 col-sm-12'>

                            <MyInput 
                             name={`shipment_attributes[${index}].length`}
                            label ={trans('shipmentinfo.length')}
                            value={notEmptyArray(formik.values.shipment_attributes,index)&&formik.values.shipment_attributes[index]?.length}
                            error={notEmptyArray(formik.errors.shipment_attributes,index)&&formik.errors.shipment_attributes[index]?.length}
                            type='number'
                            onChange={handleChange} 
                            />
                        </div>
                        <div className='col-md-2 col-sm-12'>
                            <MyInput 
                            name={`shipment_attributes[${index}].width`}
                            label ={trans('shipmentinfo.width')}
                            type='number'
                            value={notEmptyArray(formik.values.shipment_attributes,index)&&formik.values.shipment_attributes[index]?.width}
                            error={notEmptyArray(formik.errors.shipment_attributes,index)&&formik.errors.shipment_attributes[index]?.width}
                            onChange={handleChange} 
                            />
                            </div>
                        <div className='col-md-2 col-sm-12'>
                            <MyInput 
                             name={`shipment_attributes[${index}].height`}
                            label ={trans('shipmentinfo.height')}
                            type='number'
                            value={notEmptyArray(formik.values.shipment_attributes,index)&&formik.values.shipment_attributes[index]?.height}
                            error={notEmptyArray(formik.errors.shipment_attributes,index)&&formik.errors.shipment_attributes[index]?.height}
                            onChange={handleChange} 
                            />
                            </div>
                            {index>0&&
                            <div className='col-md-1 d-flex align-items-center justify-content-center'   >
                            <IconButton
                             onClick={()=>deleteProduct(index)}
                            aria-label="delete" 
                            size='medium'
                            style={{color:'red'}}>
                            <DeleteOutline />
                            </IconButton>
                            </div>
                            }
                            
                       
                       
                        
       </div>
       </div>
    )
}
