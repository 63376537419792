import React, { useState,useEffect } from 'react';
import logo from '../Pics/logo_with_text.svg';
import EmailValidation from '../Pics/emailvalidation.png';
import {useQuery,useMutation} from 'react-query'
import { getDataCustomer, putDataCustomer, trans } from '../globals';
import { createLocalStorageCustomer, getCustomerUserId } from '../LocalStorage/Auth';
import { useHistory } from "react-router-dom";
import { abort } from 'joi-browser';
import { Button } from '@material-ui/core';
import Loading from '../Components/Common/Loading';

export default function VerifyEmail() {
  //state
  const {isLoading,data} = useQuery(['customers',getCustomerUserId().id],()=> getDataCustomer(`api/customers/${getCustomerUserId().id}`),{
    onSuccess:(data)=>{
      if(data.data.customer){
        if(data.data.customer.email_verified){
          localStorage.setItem('Customer_Obj', JSON.stringify(data.data.customer));
          history.push('/Shipments')
        }
      }
    }
  })

  const sendEmailAgain = useMutation((body)=>putDataCustomer(body,`api/customers/send/verification/email`))

  //navigation
  const history = useHistory()

  if (isLoading){
    return <Loading/>
  }else{
  return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          className="container bg-white rounded mt-5 shadow-sm p-4 col-sm-12 col-md-8 col-lg-6 col-xl-3 m-3"
        >
           <img
          src={logo}
          className="mt-3 mb-5"
          alt="logo"
          width={200}
        />
        <Button>

        </Button>

          <h2 style={{ textAlign: 'center' }}> {trans("hello")}  {' '}{data.data.customer?.customer_name}</h2>
          <p className='gray_text' style={{ textAlign: 'center',fontSize:'1.1em' }}>
            {trans("verify_email.title")} 
            </p>
          <img src={EmailValidation} className="mt-5" alt="logo" width={200} />
        
          
          <Button 
          style={{textTransform:'none'}}
           onClick={()=>sendEmailAgain.mutate({customer_id:getCustomerUserId().id })} 
          color="primary">
          {sendEmailAgain.isLoading? "Loading" : trans("verify_email.sendagain")}
          </Button>
        </div>
      </div>
    )
 }
}
