import { ADD_GUEST_SHIPMENT_START, ADD_GUEST_SHIPMENT_FAIL, ADD_GUEST_SHIPMENT_SUCCESS } from "../Actions/actions";

const initialstate = {
  auth_Token: "",
  Customer: {},
  loading:false,
  chat_with_carrier:[]
  //any other customer object can be added here and later used
};

const customerReducer = (state = initialstate, action) => {
  if (action.type === "LOGIN_CUSTOMER") {
    return {
      ...state,
      auth_Token: action.data.authToken,
      Customer: { ...action.data.customer },
    };
  }
  if (action.type === "UPDATE_CUSTOMER_SUCCESS") {
    let Customer= state.Customer;
    Customer=action.payload.data.data.updateduser;
    return {
      ...state,
      Customer
    };
  }
  if(action.type=='SIGNUP_CUSTOMER_START') return {...state,loading: true}
  if(action.type=='SIGNUP_CUSTOMER_FAIL') return {...state,loading: false,error_message:action.error_message}
  if(action.type=='SIGNUP_CUSTOMER_SUCCESS') return {...state,loading: false}

  if (action.type=== ADD_GUEST_SHIPMENT_START) return{ ...state,loading:true}
  if (action.type=== ADD_GUEST_SHIPMENT_FAIL) return { ...state,loading:false,error_message:action.error_message}
  if (action.type=== ADD_GUEST_SHIPMENT_SUCCESS){
    return state;
  }

  if (action.type === "SIGNUP_CUSTOMER") {
    return {
      ...state,
      auth_Token: action.data.authToken,
      Customer: { ...action.data.data.newcustomer },
    };
  }
  if (action.type === "GET_CUSTOMER_BY_ID_SUCCESS") {
    return {
      ...state,
      Customer: { ...action.payload.data.data.customer},
    };
  }
  if (action.type === 'CHAT_CUSTOMER_WITH_ADMIN') {
    const messages = action.payload;
    const chat_by_shipment = {shipment_id:action.shipment_id,messages}      
    let chat_with_carrier = state.chat_with_carrier;
    chat_with_carrier.push(chat_by_shipment)
    return {
      ...state,
      chat_with_carrier
      }
};
  return state;
};

export default customerReducer;
