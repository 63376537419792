import React from 'react'
import Column from '../../../../Components/Common/Layout/Column';
import Row from '../../../../Components/Common/Layout/Row';
import FetchSingleRecord from '../../../../Components/Common/FetchSingleRecord/FetchSingleRecord';

export default function AutoPriceCard({data,key,actionButtons}) {
  return (
    <Column style={{borderRadius:5,flexWrap:'wrap',margin:'.5em',padding:'.5em'}}>
      <Row style={{flexWrap:'wrap',justifyContent:'space-between'}}>
        <Row>
        <TextWithTitle className='mr-3' title='Zones' text={`${data.fromZone} to ${data.toZone}`}/>
        <TextWithTitle className='mr-3' title='LxHxW' text={`${data.length} x ${data.height} x ${data.width}`}/>
        <TextWithTitle className='mr-3' title='Quantity' text={`${data.minQuantity} έως ${data.maxQuantity}`}/>
        <TextWithTitle className='mr-3'title='Packaging' text={data.packaging}/>
        {data.customer_id &&
        <div>
          <p className='blue_text mb-0'>Customer</p>
          <FetchSingleRecord 
          queryString={['customer',data.customer_id]} 
          getUrl={`api/customers/${data.customer_id}`}
          name1='customer_name'
          type='admin'
          />
        </div>
        }
        <TextWithTitle className='mr-3' title='Starting Price' text={`${data.startingPrice} €`}/>
        <TextWithTitle className='mr-3' title='Added Price' text={`${data.addedPrice} €`}/>
        </Row>
        {actionButtons}
      </Row>

      <hr/>
    </Column>
  )
}

const TextWithTitle = ({title,text,className=''})=>{
if(text!=undefined){


return (
    <div className={className}>
        <p className='blue_text mb-0'>{title}</p>
        <p>{text||"-"}</p>
    </div>
    
)}else{
    return null;
}
}