import React,{useState} from 'react'
import { trans, getDataCustomer } from '../../../globals';
import Column from '../../Common/Layout/Column'
import Row from '../../Common/Layout/Row'
import { useQuery, useQueryClient } from 'react-query';

export default function ShowCustomer({customer_id}) {
  const [customer,setcustomer] = useState({})
  const queryClient = useQueryClient();
  const {data, isLoading} = useQuery(['customer',customer_id],()=>getDataCustomer(`api/crud/customers?_id=${customer_id}`),{
    initialData: () => {
     return queryClient.getQueryData(['customer',customer_id])
      },
      onSuccess: (data)=>{ 
        console.log("FF",data.data[0])
        setcustomer(data.data[0])
      }
      
  })

    const fields=[
       {label:"company_name",value:"company_name"},
       {label:"name",value:"customer_name"},
       {label:"mobilenum",value:"customer_mobilenum"},
       {label:"email",value:"customer_email"},
       
       //{label:"tax_office",value:"tax_office"},
       //{label:"vat_number",value:"vat_number"},
    ]
    const renderField = (labelTilte, value) => {
        return (
          <React.Fragment>
            <p className="black_text">{trans("common.labels."+labelTilte)}</p>
            <p className='gray_text'>{value}</p>
          </React.Fragment>
        );
      }
    if(customer){
        return (
            <React.Fragment>
                 <hr />
            <Column>
            <p className='blue_text'>{trans("shipmentinfo.customerinfo")}</p>
            <Row style={{flexWrap:'wrap'}}>
                {fields.map(field=>{
                if(customer[field.value]){
                  return <Column style={{marginRight:30}}>
                   {renderField(field.label,customer[field.value])}
                </Column>
                }
                })}
                
               
            </Row>
            </Column>
          </React.Fragment>
        )
   }else return null
   
}
