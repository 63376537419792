import React from "react";
import "../../../Styles/CustomerHome.css";
import i18next from 'i18next'
import BoxContainer from "../ShipmentInformation/BoxContainer";
export default function EmptyOffer({shipment_status}) {
  return (
    <BoxContainer title='shipmentinfo.offers.title'>
      {shipment_status.toLowerCase()=='waiting'?
      <>
       <p className="gray_text" style={{alignSelf:'cendter',marginTop:'2em'}}> - Σύντομα θα λάβετε την προσφορά σας και θα ειδοποιηθείτε με <bold className='blue_text' style={{fontWeight:'bold'}}>email</bold> .</p> 
       <p className="gray_text" style={{alignSelf:'center',marginTop:'0em'}}>- Όλες οι προσφορές θα εμφανιστούν στο συγκεκριμένο πλαίσιο και θα μπορείτε να τις αποδεχτείτε πατώντας το κουμπί "Αποδοχή"</p> 
      </>
      :
      <p className="gray_text" style={{alignSelf:'center',marginTop:'2em'}}>{i18next.t("shipmentinfo.offers.nooffers")}</p>
      }
    </BoxContainer>
  );
}
