import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getCarrierUserId,
  getCustomerUserId, removeTokenOnly,
} from "../../../LocalStorage/Auth";
import { api_callback, trans } from "../../../globals";
import { change_password } from "../../../Services/Customer/customer_services";
import { withRouter } from "react-router-dom";
class ChangePassword extends Component {
  state = {
    data: { oldpassword: "", newpassword: "", renewpassword: "" },
    newPassError: false,
    oldPassError: false,
    loadingButton: false,
  };
  //After form submit
  passwordForm = (e) => {
    e.preventDefault();
    const { data } = this.state;
    this.loadingButton();
    const isSame = this.verifyPassword();
    if (!isSame)
      return this.setState({ newPassError: true, loadingButton: false });
    
    const requestbody = {
      oldpass: data.oldpassword,
      newpass: data.newpassword,
    };
    console.log(getCarrierUserId())
   
    this.props.changePassword(
      this.props.isCustomer,
      requestbody,
     this.props.isCustomer?getCustomerUserId()?.id:getCarrierUserId(),
      this.successChange,
      this.failSuccess
    );
    
  };
  loadingButton = () => {
    this.setState({ loadingButton: true });
  };
  successChange = () => {
    api_callback(true,'Επιτυχία','Ο κωδικός σας άλλαξε')
    let data ={}
    this.setState({ loadingButton: false ,data});
    removeTokenOnly();
    this.props.history.push('/customerlogin')
  };
  failSuccess = () => {
    let data={}
    this.setState({ oldPassError: true,loadingButton:false ,data});
  };
  changeHandler = ({ target }) => {
    this.setState({ newPassError: false, oldPassError: false });
    const data = { ...this.state.data };
    data[target.name] = target.value;
    this.setState({ data });
  };

  //Verifying the password enter by user
  verifyPassword = () => {
    const { newpassword, renewpassword } = this.state.data;
    if (newpassword !== renewpassword) return false;
    return true;
  };
  //Render Input field
  renderInput(labelTitle, fieldname, typename = "password") {
    const { data } = this.state;
    return (
      <React.Fragment>
        <label className="gray_text">{trans(labelTitle)}</label>
        <input
          type={typename}
          name={fieldname}
          className="form-control"
          onChange={this.changeHandler}
          value={data.fieldname}
          required
        />
      </React.Fragment>
    );
  }
  render() {
    const { newPassError, oldPassError } = this.state;
    return (
      <div
        className="container shadow-sm my-5 pb-3"
        style={{ background: "#ffffff" }}
      >
        <br />
        <span className="black_text">{trans('profile.change_password.title')}</span>
        {newPassError && (
          <div
            className="alert alert-danger float-right"
            style={{ fontSize: "14px" }}
          >
            New and Re-New Password are not same
          </div>
        )}
        {oldPassError && (
          <div
            className="alert alert-danger float-right"
            style={{ fontSize: "14px" }}
          >
            Old Password is not Correct
          </div>
        )}
        <br />
        <p className="gray_text mt-3">{trans('profile.change_password.title2')}</p>
        <form onSubmit={this.passwordForm} className="mt-5">
          <div className="form-row">
            <div className="form-group col-md-4">
              {this.renderInput("profile.change_password.oldpass", "oldpassword")}
            </div>
            <div className="form-group col-md-4">
              {this.renderInput("profile.change_password.newpass", "newpassword")}
            </div>
            <div className="form-group col-md-4">
              {this.renderInput("profile.change_password.renewpass", "renewpassword")}
            </div>
          </div>
          <div className="form-row mt-2">
            <div className="form-group col-md-3">
              <button
                className="btn btn-primary btn-block"
                type="submit"
                disabled={this.state.loadingButton}
              >
                {this.state.loadingButton ? (
                  <span>Loading...</span>
                ) : (
                  <span> {trans('profile.change_password.button')}</span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
const mapDispatchToprops = (dispatch) => {
  return {
    changePassword: (
      isCustomer,
      requestbody,
      customerId,
      successChange,
      failSuccess
    ) => {
      dispatch(
        change_password(
          isCustomer,
          requestbody,
          customerId,
          successChange,
          failSuccess
        )
      );
    },
  };
};
export default connect(null, mapDispatchToprops)(withRouter(ChangePassword));
