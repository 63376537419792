import React,{useState,useEffect} from 'react'
import { notEmptyArray } from '../../../../globals';
import StopsDetails from './StopsDetails';
import ShipmentStopDrawer from './ShipmentStopDrawer';


export default function ShipmentDetails({shipment}) {
    const [expanded, setExpanded] = useState(false);
    const [stopDrawerOpen,setstopDrawerOpen] = useState(false)
    const [drawerIndex,setdrawerIndex] = useState(-1)
    const [drawerData,setDrawerData] = useState({})

    useEffect(()=>{
      if(drawerIndex>=0){
        setDrawerData(shipment.data[drawerIndex])
      }
    },[drawerIndex])  

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div>
      
       
    {Array.isArray(shipment.data) && 
    shipment.data.map(((item,index)=>{
        const sa = notEmptyArray(item.shipment_attributes) ? item.shipment_attributes[0]: {}
        return ( <>
        <StopsDetails shipment_status={shipment.status} setstopDrawerOpen={setstopDrawerOpen} setdrawerIndex={setdrawerIndex} data={[item]} index={index}/>
        <ShipmentStopDrawer shipment={shipment} open={stopDrawerOpen} setOpen={setstopDrawerOpen} data={drawerData}/>
      </>
      )
    }))
    }

  
     

    </div>
  )
}
