import { Typography } from '@material-ui/core';
import React from 'react'
import BoxContainer from './BoxContainer';

export default function AutoPriceWaitingForCarrierCard() {
    return (
        <BoxContainer title='shipmentinfo.carrierinfo'>
            <Typography 
            style={{textAlign:'center',fontWeight:'500'}}
            variant='h5'
            >Το δίκτυο μεταφορέων μας εξετάζει την μεταφορά σας και σύντομα θα βρείτε μεταφορέα</Typography>
        </BoxContainer>
    )
}
