import { createLocalStorageCarrier } from "../../LocalStorage/Auth";
import { httpRequest } from "../services_utils";

const defaultConfig ={
    loading:true,
    type:"carrier",
    showError:true
}
export const authServices={

    signupCarrier:
    (body,callback)=> 
    dispatch=>httpRequest({...defaultConfig,http_type:'post',url:`api/carriers`,action:'SIGNUP_CARRIER',body,callback},dispatch),

    loginCarrier:
    (body,callback)=> 
    dispatch=>httpRequest({...defaultConfig,http_type:'post',url:`api/carriers/login`,action:'LOGIN_CARRIER',body,callback},dispatch),

}

