import React,{useState,useEffect} from 'react'
import DrawerForm from '../../../Common/Forms/DrawerForm/DrawerForm'
import Button from '@material-ui/core/Button'
import { useFormik } from 'formik';
import MySelect from '../../../Common/Forms/MySelect'
import MyInput from '../../../Common/Forms/MyInput'
import { useMutation } from 'react-query';
import { api_callback, postDataCarrier, trans } from '../../../../globals';

import * as yup from 'yup';
import { getCarrierUserId } from '../../../../LocalStorage/Auth';

export default  ({shipment_id,stops}) => {

    const [isOpen, setOpen] = useState(false)
    const [stopsOptions,setstopOptions] = useState([])
    
    // handle stops options for multiselect
    useEffect(() => {
        let temp =[]
        for (let index = 0; index < stops; index++) {
            temp.push({label:`Στάση ${index+1}`,value:index+1})
        }
        setstopOptions(temp) 
    },[stops])

   
    // Form
    const formik = useFormik({
        initialValues:{},
        validationSchema,
        onSubmit:(body)=>{
            //alert(JSON.stringify(body))
            changeShipmentState.mutate(body)
            formik.resetForm({})
        }
    })
    useEffect(() => formik.resetForm({}), [isOpen])

    //Mutation
    const changeShipmentState = useMutation((body)=> postDataCarrier(body,`api/shipments/${shipment_id}/addtruckdriver`),{
        onSuccess:()=>{
        api_callback(true,trans('notifications.success'),'Η προσθήκη οδηγόυ & φορτηγού καταχωρήθηκε')
        setOpen(false)
        window.location.reload()
        } 
    })

    return (
        <>
        <Button
         style={{textTransform:'none'}}
         variant='contained' 
         color='primary'
         onClick={()=>setOpen(!isOpen)}
         >Προσθήκη Οδηγού/Φορτηγού</Button>
 
        <DrawerForm
        isOpen={isOpen} 
        setOpen={setOpen}
        title={'Προσθήκη Οδηγού & Φορτηγού'}
        >
        <form onSubmit={formik.handleSubmit}>
       
       
        <MySelect 
        isMulti={true}
        options={stopsOptions}
        name='stops'
        error={formik.errors.stops}
        onChange={formik.setFieldValue}
        label={'Στάσεις'}
        />
        
        <MySelect 
        isFetching={true}
        type='carrier'
        queryString={['driversbycarrier',getCarrierUserId()]}
        getUrl={`api/carriers/${getCarrierUserId()}/drivers`}
        label1='driver_name'
        label2='driver_email'
        name='driver_id'
        error={formik.errors.driver_id}
        onChange={formik.setFieldValue}
        label={trans('shipmentinfo.driver')}
        />
        <MySelect 
        isFetching={true}
        queryString={['vehiclesbycarrier',getCarrierUserId()]}
        type='carrier'
        getUrl={`api/carriers/${getCarrierUserId()}/vehicles`}
        name='truck_id'
        label1='vehicle_name'
        label2='vehicle_model'
        error={formik.errors.truck_id}
        onChange={formik.setFieldValue}
        label={trans('shipmentinfo.truck')}
        />

    
      
        <Button
         style={{textTransform:'none'}}
        type='submit'
        variant='contained'
        color='primary'
        className='mt-3'
        >
        {changeShipmentState.isLoading? "Loading...":trans('common.buttons.submit') }
        </Button>
        </form>

    </DrawerForm>
 </>
    )
}



const validationSchema =  yup.object().shape({
    driver_id: yup.string().required().label('Driver'),
    truck_id: yup.string().required().label('Truck'),
  });