import React, { Component } from "react";
import userpic from "../../../Pics/userpic.png";
import "../../../Styles/CustomerHome.css";
import { connect } from "react-redux";
import { customerServices, updateCustomer } from "../../../Services/Customer/customer_services";
import { trans } from "../../../globals";
import profile from '../../../Pics/profile-gray.png'
class PersonalData extends Component {

  render() {
    const { loadingButton } = this.props.state;
    console.log(this.props)
    return (
      <div className="shadow-sm" style={{ background: "#ffffff" }}>
        <br />
        <span className="black_text pl-3">{trans('profile.edit.title')}</span>
        <button type='button'className='nobg_btn_red float-right d-block mr-3' onClick={()=>this.props.change_state("edit_profile",true)}>{trans("common.buttons.edit")}</button>
        <div className="main__div">
          <img
            src={profile}
            alt="userpic"
            className="img__position"
            style={{ width: "140px", height: "140px" }}
          />
        </div>
        <span className="font-weight-bolder pl-3">
        {trans('profile.edit.message')}
        </span>
        <div className="form p-3">
         {this.props.state.edit_profile==true?(
            <form onSubmit={this.props.formPersonalData}>
              
            <div className="form-row">
            <div className="form-group col-md-4">
                {this.props.renderInput("common.labels.company_name", "company_name", "text")}
              </div>
              <div className="form-group col-md-4">
                {this.props.renderInput("common.labels.name", "customer_name")}
              </div>
              <div className="form-group col-md-4 col-sm-6">
                {this.props.renderInput("common.labels.email", "customer_email", "email")}
              </div>
              
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                {this.props.renderInput("common.labels.mobilenum", "customer_mobilenum", "tel")}
              </div>
            
              <div className="form-group col-md-4">
                {this.props.renderNewInput("common.labels.address", "customer_address")}
              </div>
              <div className="form-group col-md-4">
                {this.props.renderInput("common.labels.vat_number", "vat_number", "text")}
              </div>
              <div className="form-group col-md-4">
                {this.props.renderInput("common.labels.tax_office", "tax_office", "text")}
              </div>
             
              <div className="form-group col-md-4">
                {this.props.renderInput("common.labels.occupation", "occupation", "text")}
              </div>
              
            </div>
            
            <div className="form-row mt-3">
              <div className="form-group col-md-3">
                <button
                  className="btn btn-primary btn-block"
                  type="submit"
                  disabled={loadingButton}
                >
                  {loadingButton ? <span>Loading ...</span> : <span>{trans('common.buttons.save')}</span>}
                </button>
              </div>
            </div>
          </form>)
                  :
         ( <div>
           
            <div className="form-row">
            <div className="form-group col-md-4">
                {this.props.renderField("company_name", this.props?.customer?.company_name)}
              </div>
              <div className="form-group col-md-4">
                {this.props.renderField("name", this.props?.customer?.customer_name)}
              </div>
              <div className="form-group col-md-4 col-sm-6">
                {this.props.renderField("email", this.props?.customer?.customer_email)}
              </div>
           
              
            </div>
            <div className="form-row">
            <div className="form-group col-md-4">
                {this.props.renderField("mobilenum", this.props?.customer?.customer_mobilenum)}
              </div>
            
              <div className="form-group col-md-4">
                {this.props.renderField("address", this.props?.customer?.customer_address)}
              </div>
            <div className="form-group col-md-4">
                {this.props.renderField("vat_number", this.props?.customer?.vat_number)}
              </div>
              
               
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                {this.props.renderField("tax_office", this.props?.customer?.tax_office)}
              </div> 
              <div className="form-group col-md-4">
                {this.props.renderField("occupation", this.props?.customer?.occupation)}
              </div>
              
            </div>
            
          </div>
         )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    customer: state.customerReducer.Customer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateCustomer: (
      requestBody,
      updateSuccess,
      customerId
    ) => {
      dispatch(
        customerServices.updateCustomer(
          requestBody,
          updateSuccess,
          customerId
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PersonalData);
