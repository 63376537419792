import React,{useEffect,useState} from 'react'
import {useParams} from "react-router-dom";
import { httpRequest } from '../../Services/services_utils';

export default function CustomerVerification(props) {
    const params = useParams();
    const [loading, setloading] = useState(false)

    useEffect(() => {
       const {customerid,token} = params;
       
        httpRequest({
            http_type:'put',
            url:`api/customers/verifyemail/${customerid}/${token}`,
            type:'auth',
            callback:verifyEmailCallback
        },null)
        .catch(err=>setloading(false))

    }, [params])
    const verifyEmailCallback=(res)=>{
        localStorage.setItem("CustomerObj", JSON.stringify(res.data.data.customer));
        setloading(false)
        props.history.push("/Shipments")
    }
    return (
        <div>   
            <p>{loading ?"Loading...":""}</p>
        </div>
    )
}
