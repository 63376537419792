import React,{useState,useEffect} from 'react'
import Modal from "react-bootstrap/Modal";
import {Button} from "@material-ui/core"
import Column from '../../Layout/Column'
import {CheckCircleOutline} from '@material-ui/icons';

export default function SuccessFeedbackModal({show,setShow,children,primaryMessage,secondaryMessageComponent,onSubmit,hasHeader=true,buttonText}) {
    
  

    return (
        <Modal
        size="lg"
        show={show}
        onHide={()=>setShow(false)}
        backdrop="static"
        animation="true"
        centered
        //aria-labelledby="example-modal-sizes-title-lg"
      >
        
          <Modal.Body style={{padding:0}}>
              <Column style={{justifyContent:'center',alignItems:'center',backgroundColor:'#1abc9c',padding:'1em'}}>
                  <CheckCircleOutline color='white'  style={{ fontSize: '7em',color:'white' }}/>
                  <h4 style={{color:'white',marginTop:'1em'}}>{primaryMessage}</h4>
              </Column>
              {secondaryMessageComponent}
          </Modal.Body>
          <Modal.Footer>
          <Button variant="outlined" style={{color:'#1abc9c',borderColor:'#1abc9c'}} color='primary'  onClick={onSubmit}  >{buttonText}</Button>
          </Modal.Footer>

        </Modal>
    )
}
