import React, { Component } from 'react'
import { filter_carrier_shipments, filter_customer_shipments } from '../../../../Redux/Actions/actions';
import InputField from '../../../Common/InputField';
import { connect } from 'react-redux'
import Select from 'react-select'
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';// PropTypes.func.isRequired,
import i18next from 'i18next'
import dateformat from 'dateformat'
import { trans } from '../../../../globals';
import 'moment/locale/el';

let states = [
    { value: 'all', label: 'All' },
    { value: 'waiting', label:  trans('select') },
    { value: 'upcoming', label:  trans('common.status.upcoming')},
    { value: 'inprogress', label:  trans('common.status.inprogress') },
    { value: 'completed', label:  trans('common.status.completed') },
  ]
  const shipment_type = [
    { value: 'all', label: 'All' },
    { value: 'ftl', label: 'FTL' },
    { value: 'ltl', label: 'LTL' },
  ]

 class ShipmentsFilter extends Component {

    constructor(props){
        super(props);
        this.state = {
            description:'',
            status:'all',
            type:'all',
            shipment_type:'all',
            date:[new Date(),new Date()]
        }
    }
    componentWillMount(){
        
        states=[
            { value: 'all', label:trans('common.status.all')},
            { value: 'waiting', label:  trans('common.status.waiting') },
            { value: 'upcoming', label:  trans('common.status.upcoming')},
            { value: 'inprogress', label:  trans('common.status.inprogress') },
            { value: 'completed', label:  trans('common.status.completed') },
          ]
    }

    filter=(e)=>{
        e.preventDefault()

        const pickup_date =[new Date(dateformat(this.state.pickup_date_start,'yyyy-m-d')),new Date(dateformat(this.state.pickup_date_end,'yyyy-m-d'))]
        const dropoff_date =[new Date(dateformat(this.state.dropoff_date_start,'yyyy-m-d')),new Date(dateformat(this.state.dropoff_date_end,'yyyy-m-d'))]
        const {description,status,shipment_type} =this.state;
        const {shipments}=this.props;
        console.log("Shipments",shipments)
        let myshipments = shipments.filter(s=> s.shipment_description.toLowerCase().includes(description.toLowerCase()))
        .filter(s=> s.status.toLowerCase()==status.toLowerCase()?true: status.toLowerCase()=='all'?true:false)
        //.filter(s=> s.shipment_type.toLowerCase()==shipment_type.toLowerCase()?true: shipment_type.toLowerCase()=='all'?true:false)
        .filter(s=> this.state.pickup_date_start ? new Date(s.pickup_date) >=new Date(pickup_date[0]) && new Date(s.pickup_date) <= new Date(pickup_date[1]):true)
        .filter(s=> this.state.dropoff_date_start ?  new Date(s.dropoff_date) >=new Date(dropoff_date[0]) && new Date(s.dropoff_date) <= new Date(dropoff_date[1]):true)
        
        //.filter(s=> new Date(s.dropoff_date)>dropoff_date[0] & new Date(s.dropoff_date)<dropoff_date[1])
        //write on shipments state
        if(this.props.type=='carrier'){
            this.props.filter_carrier_shipments(myshipments)
        }else if(this.props.type=='customer'){
            this.props.setFilteredShipments(myshipments)
            this.props.setFilterApplied(true)
        }

    }
    renderInput=(label, fieldname, type = "text") =>{
        return (
          <InputField
            labeltitle={label}
            typename={type}
            fieldname={fieldname}
            changeHandler={this.changeHandler}
            value={this.state[fieldname]}
          />
        );
      }

      changeHandler = ({ target }) => this.setState({[target.name]:target.value});

    render() {
        
        return (
            <div className='col-md-2 col-sm-12 d-flex bg-white shadow-sm justify-content-center pb-4 p-3' style={{height:'fit-content'}}>
                <form className='w-100' onSubmit={this.filter}>
                    <p  style={{fontWeight:600,fontSize:'1.3em'}}className='black_text text-bold'>{i18next.t('common.filters')}</p>
                    {this.renderInput('shipmentinfo.description','description')}
                    <div className='mt-4'>
                    <label className='gray_text'>{i18next.t('shipmentinfo.status')}</label>
                    <Select options={states} name='status' placeholder={trans("select")} onChange={(e)=>this.setState({status:e.value})}/>
                    </div>
                   {/* <div className='mt-4'>
                     <label className='gray_text'>{i18next.t('shipmentinfo.shipment_type')}</label>
                    <Select options={shipment_type} placeholder={trans("select")}  name='shipment_type' onChange={(e)=>this.setState({shipment_type:e.value})}/>
                    </div>
                    */}
                    <div className='mt-4'>
                    <label className='gray_text'>{i18next.t('shipmentinfo.pickup_date')}</label>
                    <DateRangePicker
                    startDatePlaceholderText={trans("common.startDate")} 
                    endDatePlaceholderText={trans("common.endDate")} 
                    isOutsideRange={() => false}
                    displayFormat='DD-MM-YYYY'
                    startDate={this.state.pickup_date_start} 
                    startDateId='pickup_date_start' 
                    endDate={this.state.pickup_date_end} 
                    endDateId="pickup_date_end" 
                    onDatesChange={(dates) =>{
                     this.setState({ pickup_date_start:dates.startDate,pickup_date_end: dates.endDate})
                    }} 
                    focusedInput={this.state.focusedInput} 
                    onFocusChange={focusedInput => this.setState({ focusedInput })} 
                    /> 
                    </div>
                    <div className='mt-4'>
                    <label className='gray_text'>{i18next.t('shipmentinfo.dropoff_date')}</label>
                    <DateRangePicker
                    startDatePlaceholderText={trans("common.startDate")} 
                    endDatePlaceholderText={trans("common.endDate")} 
                    isOutsideRange={() => false}
                    displayFormat='DD-MM-YYYY'
                    startDate={this.state.dropoff_date_start} 
                    startDateId='dropoff_date_start' 
                    endDate={this.state.dropoff_date_end} 
                    endDateId="dropoff_date_end" 
                    onDatesChange={(dates) =>{
                     this.setState({ dropoff_date_start:dates.startDate, dropoff_date_end: dates.endDate})
                    }} 
                    focusedInput={this.state.focusedInput2} 
                    onFocusChange={focusedInput2 => this.setState({ focusedInput2 })} 
                    /> 
                    </div>
                    <button className='btn btn-primary w-100 mt-4' type='submit'>{i18next.t('common.buttons.submit')}</button>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = (dispatch) => {
    return {
  filter_carrier_shipments:(shipments)=>dispatch(filter_carrier_shipments(shipments)), 
  filter_customer_shipments:(shipments)=>dispatch(filter_customer_shipments(shipments)), 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentsFilter)