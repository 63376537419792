import { PALLET_STOPS_PRICING, BOX_STOPS_PRICING} from "../../../globals";
import { getCustomerUserId } from "../../../LocalStorage/Auth";

const handlePackagingMatching = (packaging)=>{
  if(packaging=='Παλέτες'){
    return "PALLET"
  }else if(packaging=='Κιβώτια'){
    return "BOX"
  }else{
    return ""
  }
}

// calculates the price of auto-price shipment
 export const calculatePrice =(zones,autoPrice,postal_code,postal_code2,packing,width,height,length,quantity,packaging,index)=>{

    // takes packaging and outputs the packaging enum value in database 
    let pack = handlePackagingMatching(packaging)

    // if zones and autoprice exists
    if(zones!=null && autoPrice!=null && Array.isArray(zones) && Array.isArray(autoPrice)){
      //finds the zones
      const foundZones = findZonesOfShipment(zones,postal_code,postal_code2)
      if(foundZones===false){return -2}
      let pricee=-2
      let autoPrices = []
      //Finds Zones 
        autoPrice.map(autoprice=>{
        
          if(autoprice.toZone.toLowerCase()==foundZones.dropoffZone.toLowerCase()&&autoprice.width===parseInt(width)&&autoprice.height===parseInt(height)&&autoprice.length===parseInt(length)){
            if(quantity >= autoprice.minQuantity && quantity<=autoprice.maxQuantity && pack==autoprice?.packaging){//quantity in range 
              autoPrices.push(autoprice)
            }
          }
        })
        let hasCustomerId = autoPrices.find(a=>a.customer_id === getCustomerUserId().id) || autoPrices
       
        if(Array.isArray(hasCustomerId)){
          if(hasCustomerId.length>0){
          hasCustomerId = hasCustomerId[0]
          }
        }
        console.log(hasCustomerId)
        if(typeof hasCustomerId === 'object' && hasCustomerId !== null){
            [hasCustomerId].map(autoprice=>{
              console.log(autoprice.startingPrice)
              console.log(autoprice.addedPrice)
              console.log(quantity)
              pricee= autoprice.startingPrice+ (quantity-autoprice.minQuantity) * autoprice.addedPrice
              console.log("PRICE: " ,pricee)
              //handles the stop price
              if(index>=1){
                if(foundZones.pickupZone.toLowerCase()=='a' && foundZones.dropoffZone.toLowerCase()=='a'){
                  if(pack=="PALLET"){
                    pricee+= PALLET_STOPS_PRICING.a_to_a
                  }else if(pack=="BOX"){
                    pricee+= BOX_STOPS_PRICING.a_to_a
                  }
                 
                }else if(foundZones.pickupZone.toLowerCase()=='b' || foundZones.dropoffZone.toLowerCase()=='b'){
                  if(pack=="PALLET"){
                    pricee+= PALLET_STOPS_PRICING.a_to_b
                  }else if(pack=="BOX"){
                    pricee+= BOX_STOPS_PRICING.a_to_b
                  }
                }
              }
          })
        }
      return pricee // -2 σημαινει οτι πρεπει να παρει προσφορα
    }
  }

  const findZonesOfShipment = (zones,pickupPostalCode,dropoffPostalCode)=>{
     //find zone
     let pickupZone=null;
     let dropoffZone=null;
     zones.map(zone=>{
       if(Array.isArray(zone.postal_codes)){
         let postal1=  pickupPostalCode.replace(/\s+/g, '').trim();
         if(zone.postal_codes.includes(parseInt(postal1))){
          pickupZone = zone.name
         }
         let postal2=  dropoffPostalCode.replace(/\s+/g, '').trim();
         if(zone.postal_codes.includes(parseInt(postal2))){
          dropoffZone = zone.name
         }
       }
     })
     if(pickupZone==null || dropoffZone==null){
       return false
     }else{
     return {pickupZone,dropoffZone}
     }
  }