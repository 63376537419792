
import axios from 'axios'
import { api_callback, get_carrier_config, get_config, notAuthRedirectURL,URL } from '../globals';
import { removeTokenOnly } from '../LocalStorage/Auth';
import { createActions } from '../Redux/Actions/actions';
export const handleHttpErrors =(error)=>{
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
         if(error.response.status==401){
            removeTokenOnly()
           window.location.href = notAuthRedirectURL()
         }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.log("Error", error.message);

        console.log("error 1");
      } else if (error.request) {
        // No Response
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
}


export const httpRequest= (config={http_type:'get',url:'',loading:false,type:'customer'},dispatch=null)=>{
   let http_config = {}
   if(config?.type=='customer'){
      http_config = get_config()
   }else if(config?.type=='carrier'){
      http_config = get_carrier_config()
   }else {
      http_config={
         headers: {
           "Content-Type": "application/json",
         },
       }
   }
   

    const actions =config?.action!=null ?  createActions(config?.action.toUpperCase()):-1
    let request= null;
    if(config?.loading){
        dispatch!=null && dispatch(actions?.start())
     }
     if(config.http_type.toLowerCase()=='get'){
        request =  axios.get(`${URL}${config?.url}`,http_config)
        
      }else if(config.http_type.toLowerCase()=='post'){
         request = axios.post(`${URL}${config?.url}`,config?.body ,http_config)
      }else if(config.http_type.toLowerCase()=='put'){
         request = axios.put(`${URL}${config?.url}`,config?.body ,http_config)
      }
      else if(config.http_type.toLowerCase()=='delete'){
         request = axios.delete(`${URL}${config?.url}`,http_config)
      }
     
        request.then( (response)=> {
         console.log(`${config?.http_type.toUpperCase()} ->  ${config?.url} `)
         //console.log("HTTP",response)
         dispatch!=null && dispatch(actions?.success(response));
         if(config?.callback){
            config.callback(response);
         }
      })
      .catch((err)=>{ 
         if(err.response?.data?.error_message){api_callback(false,'Αποτυχία',err.response?.data?.error_message) }
         dispatch!=null && dispatch(actions?.fail("Error Occured"))
         handleHttpErrors(err)
      })

      return request
        
}