import React, { Component } from "react";
import { connect } from "react-redux";
import NavBar from "../../Common/NavBar";
import PersonalData from "../../CustomerSide/Profile/PersonalData";
import ChangePassword from "../../CustomerSide/Profile/ChangePassword";
import { getCustomerObj, getCustomerUserId, getCustomer } from "../../../LocalStorage/Auth";
import { api_callback, trans } from "../../../globals";
import { customerServices} from "../../../Services/Customer/customer_services";
import { updateCustomer } from "../../../Services/Customer/customer_services";

class CustomerAccount extends Component {
  constructor(props){
    super(props);
    this.state={
    customer_obj:null,
    data:{},
    customer_address: "",
    customer_phonenum: "",
    customer_name:"r",
    loadingButton: false,
    smsNotification: false,
    emailNotification: false,
    edit_profile:false
  }
}
  componentDidMount(){
    this.props.get_customer_by_id(getCustomerUserId().id,this.get_customer_callback) 
  }
  get_customer_callback=(success)=>{
    if(!success){
      //api_callback(false,'Αποτυχία','Fail Occured')
      return false
    }
    this.setState({data:this.props.customer.Customer})
  }


  change_state=(name,value)=>{
    this.setState({[name]:value})
  }
  changeHandler = ({ target }) => {
    const data = { ...this.state.data };
    data[target.name] = target.value;
    this.setState({ data });
  };
  //---------FOR SWITCH TOGGLER----
  handleSwitchEmail = () => {
    this.setState({ emailNotification: !this.state.emailNotification });
  };
  handleSwitchSms = () => {
    this.setState({ smsNotification: !this.state.smsNotification });
  };
  loadingButton = () => {
    this.setState({ loadingButton: true });
  };
  //----handle the form
  formPersonalData = (e) => {
    e.preventDefault();
    this.loadingButton();
    const {
      data,
      smsNotification,
      emailNotification,
      language,
      customer_phonenum,
    } = this.state;

    const requestBody = {
      customer_name: data.customer_name,
      customer_username: data.customer_username,
      customer_email: data.customer_email,
      customer_mobilenum: data.customer_mobilenum,
      customer_address: data.customer_address,
      customer_smsNotify: smsNotification,
      customer_emailNotify: emailNotification,
      customer_language: language,
      customer_phonenum: customer_phonenum,
      vat_number: data.vat_number,
      tax_office: data.tax_office,
      company_name: data.company_name,
      occupation: data.occupation,
    };
    const { id: customerId } = getCustomerUserId();
    this.props.updateCustomer(
      requestBody,
      this.updateSuccess,
      customerId
    );
  
  };
  updateSuccess = () => {
    this.setState({ loadingButton: false,edit_profile:false });
    
    api_callback(true,'Επιτυχία','Έγινε αλλαγή στις πληροφορίες προφίλ')
  };
 
  //-----render input field data coming from DB-----------------
  renderInput(labelTilte, fieldname, fieldType = "text") {
    const { data } = this.state;
    return (
      <React.Fragment>
        <label className="gray_text">{trans(labelTilte)}</label>
        <input
          type={fieldType}
          name={fieldname}
          className="form-control"
          onChange={this.changeHandler}
          value={data[fieldname]}
        />
      </React.Fragment>
    );
  }
  renderField(labelTilte, value) {
    return (
      <React.Fragment>
        <label className="black_text">{trans("common.labels."+labelTilte)}</label>
        <p className='gray_text'>{value}</p>
      </React.Fragment>
    );
  }
  //--------------newly data enter-----------------------
  renderNewInput(labelTilte, fieldname, fieldType = "text") {
    const { data } = this.state;
    return (
     
      <React.Fragment>
        <label className="gray_text">{trans(labelTilte)}</label>
        <input
          type={fieldType}
          name={fieldname}
          className="form-control"
          onChange={this.changeHandler}
          value={data[fieldname]}
        />
      </React.Fragment>
    );
  }
  render() {
    console.log(this.state.data)
    return (
      <div
        style={{
          height: "100vh",
          overflowY: "auto",
          overflowX: "none",
          msOverflowStyle: "none",
        }}
      >
        <NavBar />
        <div className="container-fluid">
          <div className="container pt-5">
            <h2 className="mb-4">{trans("profile.my_account")}</h2>
            <PersonalData
            renderInput={this.renderInput}
            renderField={this.renderField}
            renderNewInput={this.renderNewInput}
            formPersonalData={this.formPersonalData}
            changeHandler={this.changeHandler}
            state={this.state}
            change_state={this.change_state}
            />
            <ChangePassword  isCustomer={true}/>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    customer: state.customerReducer,
  };
};

const mapDispatchToProps = dispatch=> {
  return {
  get_customer_by_id: (id,callback)=>dispatch(customerServices.getCustomerById(id,callback)),
  updateCustomer: (requestBody,callback,customerId)=>{ dispatch( customerServices.updateCustomer(requestBody,callback,customerId)
  );
  },
  }  
  }

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAccount);
