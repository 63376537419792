import React,{useState} from 'react'
import { useHistory } from 'react-router-dom'
import SuccessFeedbackModal from '../../../Common/Modals/SuccessFeeback/SuccessFeedbackModal'

export default function SuccessFailMessage({showSuccessModal,setShowSuccessModal,showSuccessModalAutoPrice,setshowSuccessModalAutoPrice}) {

  const history = useHistory();
  return (
      <>
      <SuccessFeedbackModal
      title='Η μεταφορά σας καταχωρήθηκε'
      buttonSubmit='Ok'
      hasHeader={false}
      onSubmit={()=>history.push('/Shipments')}
      show={showSuccessModal} 
      setShow={setShowSuccessModal} 
      buttonText="Δες τις μεταφορες σου"
      primaryMessage='Η μεταφορά σας καταχωρήθηκε'
      secondaryMessageComponent={<ShipmentAddedSecondaryMessage/>}
      />
      <SuccessFeedbackModal
      title='Η μεταφορά σας καταχωρήθηκε'
      buttonSubmit='Ok'
      hasHeader={false}
      onSubmit={()=>history.push('/Shipments')}
      show={showSuccessModalAutoPrice} 
      setShow={setshowSuccessModalAutoPrice} 
      buttonText="Δες τις μεταφορες σου"
      primaryMessage='Η μεταφορά σας καταχωρήθηκε'
      secondaryMessageComponent={<ShipmentAddedSecondaryMessageAutoPrice/>}
      />
      </>
  )
}


const ShipmentAddedSecondaryMessage =()=>{
    return (
     <div className='p-3' >
     <h3 style={{color:'#1abc9c',marginBottom:'1em'}}>Διαδικασία ολοκλήρωσης της μεταφοράς</h3>
     <p>1. Θα λάβετε προσφορά εντός <bold style={{fontSize:'1.2em',color:'#2E5BFF'}}>30 λεπτών</bold>. </p>
     <p>2. Παρακαλώ αποδεχτείτε την κατάλληλη για εσάς προσφορά. </p>
     <p>3. Κατόπιν συμπληρώστε τον Αποστολέα και τον Παραλήπτη. </p>
     </div>
     )
   }
  
   const ShipmentAddedSecondaryMessageAutoPrice =()=>{
    return (
     <div className='p-3' >
     <h3 style={{color:'#1abc9c',marginBottom:'1em'}}>Διαδικασία ολοκλήρωσης της μεταφοράς</h3>
     <p>1. Σύντομα το δίκτυο μεταφορέων μας θα επεξεργαστεί την μεταφορά σας. </p>
     <p>2. Θα επιλεχτεί ο κατάλληλος μεταφορέας για τη μεταφορά αυτή και θα ενημερωθείτε με email και ειδοποιήση στην πλατφόρμα. </p>
     </div>
     )
   
   }
  
  
