import { api_callback, config, get_carrier_config, get_config, URL } from "../../globals";
import axios from "axios";
import { get_customer_by_id_action, LoginAction, signup_customer_action, updateCustomerAction } from "../../Redux/Actions/actions";
import { createLocalStorageCustomer } from "../../LocalStorage/Auth";
import { httpRequest } from "../services_utils";


export const customerServices ={
    //Customers
    updateCustomer:
    (body,callback,customerId)=> 
    dispatch=>httpRequest({http_type:'put',url:`api/customers/${customerId}/updateprofile`,action:'UPDATE_CUSTOMER',loading:false,type:'customer',body,callback},dispatch),

    getCustomerById:
    (customer_id,callback)=> 
    dispatch=>httpRequest({http_type:'get',url:`api/customers/${customer_id}`,action:'GET_CUSTOMER_BY_ID',loading:false,type:'customer',callback},dispatch),

    customerLogin:
    (body,callback)=> 
    dispatch=>httpRequest({http_type:'post',url:`api/customers/login`,body,action:'LOGIN_CUSTOMER',loading:false,type:'auth',callback},dispatch),

    customerSignup:
    (body,callback)=> 
    dispatch=>httpRequest({http_type:'post',url:`api/customers`,body,action:'SIGNUP_CUSTOMER',loading:false,type:'auth',callback},dispatch)
    .then(res=>createLocalStorageCustomer(res.data.authToken, res.data.data.newcustomer))
    ,

}



export const change_password = (
    isCustomer,
    requestbody,
    id,
    successChange,
    failSuccess
  ) => {
      return dispatch=>{
    axios
      .put(`${URL}api/${isCustomer?"customers":"carriers"}/${id}/resetpass`, requestbody, isCustomer?get_config():get_carrier_config())
      .then(function (response) {
        console.log(response.data);
        successChange();
      })
      .catch(function (error) {
        if (error.response) {
          failSuccess()
          console.log(error.response)
        } else if (error.request) {
          console.log(error.request);
        } else {
          //console.log("Error", error.message);
        }
           console.log(error.config);
        });
  };
}





