import React,{useEffect,useState} from 'react'
import { notEmptyArray } from '../../../../globals';

export default function DriverStateBardge({driver_state,showLastOne=true}) {
    const [state,setstate] = useState(null);

    useEffect(() =>{
        console.log("Last State",driver_state)
        if(showLastOne){
            setstate(notEmptyArray(driver_state) ? driver_state[driver_state.length - 1].state : null)
        }else{
            setstate(driver_state)
        }
    },[])

    switch (state) {
        case 'START':
           return <p style={{backgroundColor:'rgba(247, 193, 55, 0.212)',color:'#F7C137',...defaultStyle}}>Καθοδόν για παραλαβή</p>;
           break;
        case 'PICKUP':
           return <p style={{backgroundColor:'rgba(141, 84, 255, 0.247)',color:'#8C54FF',...defaultStyle}}>Παραλήφθηκε</p>;
        break;
        case 'DROPOFF':
           return <p style={{backgroundColor:'rgba(0, 194, 212, 0.219)',color:'#00C1D4',...defaultStyle}}>Καθοδόν για παράδοση</p>;;
        break;
        case 'END':
           return <p style={{backgroundColor:'rgba(50, 172, 46, 0.212)',color:'#33AC2E',...defaultStyle}}>Παραδόθηκε</p>;
          break;   
        default:
           return null;
           break;
     }
}

const defaultStyle = {
    height:'fit-content',textAlign:'center',
    fontSize:11,
    borderRadius:20,paddingLeft:10,paddingRight:10
}