import React from 'react'
import { trans } from '../../globals'
import noshipments from '../../Pics/web_search.svg'
import { useHistory } from "react-router-dom";
export default function NoShipmentsFound({type='carrier',filterMode=false}) {

    let history = useHistory();

    return (
        <div className='d-flex flex-column align-items-center mt-4'> 
           <h4 className="mb-5 mr-5 mt-5">{trans("noshipmentsfound")}</h4>
           <img width={150} src={noshipments}></img>
                      
          {(type=='customer'&& filterMode==false) &&            
          <button 
          onClick={()=> history.push("/newshipment")}
          className='btn btn-primary mt-4'>Προσθήκη Νέας Μεταφοράς - Δεχτείτε προσφορά σε 30 λεπτά</button>
          }
         </div>
    )
}
