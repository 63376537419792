import { Drawer } from '@material-ui/core'
import React from 'react'
import { Column } from '@dimararist/woocargo.layout.column';
import ShipmentAttributes from './ShipmentAttributes';
import ShipmentState from './ShipmentState';
import ShowDriverTruck from '../../../CarrierSide/ShipmentInformation/DriverTruck/ShowDriverTruck';
import { notEmptyArray } from '../../../../globals';

export default function ShipmentStopDrawer({shipment,data,open,setOpen}) {
  return (
    <Drawer
      anchor={'right'}
      onClose={()=>setOpen(false)}
      open={open}
    >
        <Column style={{width:'100%',padding:'2em'}}>
            <ShipmentAttributes shipment_attributes={data?.shipment_attributes}/>
            {notEmptyArray(data.shipment_attributes) && (
              <>
               <hr></hr>
               <p> Σημειώσεις : {data.shipment_attributes[0].notes || ""}</p>
              </>
            )}
           

            <hr></hr>
            {shipment.status.toLowerCase()!=='waiting' &&  <ShipmentState state={data.states} />}
           
          {(data?.selected_truck||data?.selected_driver) && <ShowDriverTruck truck_id={data?.selected_truck} driver_id={data?.selected_driver} />}
          </Column>
    </Drawer>
  )
}
