import React,{useEffect} from 'react'
import NavbarCarrier from '../../Common/NavbarCarrier';
import ShipmentSection from '../../CustomerSide/Shipments/ShipmentSection/ShipmentSection';
import useFetchData from '../../CustomerSide/Shipments/ShipmentSection/useFetchData';

export default function SearchShipments() {

  const [data,isLoading]= useFetchData(`api/shipments?status=WAITING`,['carrier','shipments','search'],'carrier','data')


  return (
    <>
      
      <NavbarCarrier />
     <div className="container-fluid pt-5" style={{ minHeight: "90vh" }}>
         <div className="row">
             <ShipmentSection
             shipments={data}
             isLoading={isLoading}
             offerButton={true}
             showFilterTabs={true}
             type='carrier'
             initialFilters={'status=WAITING&'}
             />
           </div>
     </div>
    </>
  )
}
