import {ADD_CARRIER_PAYMENT_METHOD_FAIL, ADD_CARRIER_PAYMENT_METHOD_START, ADD_CARRIER_PAYMENT_METHOD_SUCCESS, FILTER_CARRIER_SHIPMENTS, GET_CARRIER_SHIPMENTS_FAIL, GET_CARRIER_SHIPMENTS_START, GET_CARRIER_SHIPMENTS_SUCCESS, GET_CARRIER_SHIPMENT_BY_ID_FAIL, GET_CARRIER_SHIPMENT_BY_ID_START, GET_CARRIER_SHIPMENT_BY_ID_SUCCESS, RESET_FILTER_APPLIED} from "../Actions/actions"
const initialstate = {
  auth_Token: "",
  carrier_shipments:[],
  Carrier: {},
  Carrier_Shipments: [],
  filter_applied:false,
  loading:false,
  filtered_carrier_shipments:[],
  //any other customer object can be added here and later used
};

const carrierReducer = (state = initialstate, action) => {
  if (action.type === "LOGIN_CARRIER_SUCCESS") {
    return {
      ...state,
      auth_Token: action.payload.data.authToken,
      Carrier: { ...action.payload.data.carrier },
    };
  }

  if (action.type === "GET_CARRIER_SHIPMENTS") {
    return {
      ...state,
      Carrier_Shipments: action.data.data.newshipments,
    };
  }
  if (action.type === "GET_CARRIER_BY_ID_SUCCESS") {
    return {
      ...state,
      Carrier: action.payload.data.data.carrier,
    };
  }
  if (action.type === "UPDATE_CARRIER") {
    return {
      ...state,
      Carrier: action.data,
    };
  }
  if(action.type===FILTER_CARRIER_SHIPMENTS){
    return {...state,filtered_carrier_shipments:action.filtered_carrier_shipments,filter_applied:true}
  }
  if (action.type === "ADD_DRIVER_TRUCK_SUCCESS") {
    const updatedshipment = action.payload.data.data.Shipment;
    const allcarrierShipments = [...state.Carrier_Shipments];
    const index = allcarrierShipments.findIndex(
      (shipment) => shipment._id === updatedshipment._id
    );
    allcarrierShipments[index] = updatedshipment;
    return {
      ...state,
      Carrier_Shipments: [...allcarrierShipments],
    };
  }
  if (action.type=== RESET_FILTER_APPLIED) return{ ...state,filter_applied:false}
  if (action.type=== ADD_CARRIER_PAYMENT_METHOD_START) return{ ...state,loading:true}
  if (action.type=== ADD_CARRIER_PAYMENT_METHOD_FAIL) return { ...state,loading:false,error_message:action.error_message}
  if (action.type=== ADD_CARRIER_PAYMENT_METHOD_SUCCESS)return {...state,loading:false}
  // GET CARRIER SHIPMENTS
  if (action.type=== GET_CARRIER_SHIPMENTS_START) return{ ...state,loading:true}
  if (action.type=== GET_CARRIER_SHIPMENTS_FAIL) return { ...state,loading:false,error_message:action.error_message}
  if (action.type=== GET_CARRIER_SHIPMENTS_SUCCESS){
  const shipments = action.payload.data.data.newshipments
  let carrier_shipments = shipments.filter(s=> s.status.toLowerCase()!='waiting');
  console.log(carrier_shipments)
  return {
    ...state,loading:false,Carrier_Shipments: [...shipments],carrier_shipments
  }
}

if (action.type=== GET_CARRIER_SHIPMENT_BY_ID_START) return{ ...state,loading:true}
if (action.type=== GET_CARRIER_SHIPMENT_BY_ID_FAIL) return { ...state,loading:false,error_message:action.error_message}
if (action.type=== GET_CARRIER_SHIPMENT_BY_ID_SUCCESS){
   let shipments = state.Carrier_Shipments;
  const fetched_shipment = action.payload.data.data.shipment[0];
  const index = shipments.findIndex(s=>s._id==fetched_shipment._id)
  if(index!=-1){
    shipments[index]=fetched_shipment; 
  }else{
    shipments.push(fetched_shipment)
  }
  console.log("Shipments",shipments)
  return {...state,Carrier_Shipments:shipments,loading:false};
}
  return state;
};

export default carrierReducer;
