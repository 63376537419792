import { Typography } from '@material-ui/core'
import React from 'react'

export default function MyInput({error,label,onChange,id,name,placeholder,value,type='text',defaultValue=''}) {
    return (
        <div style={{marginTop:'0em'}}>
           <Typography variant='p' className='gray_text' >{`${label[0].toUpperCase()}${label.substring(1,label.length)}`}</Typography>
           <input
           id={id}
           name={name}
           value={value}
           defaultValue={defaultValue}
           type={type}
           onChange={onChange}
           placeholder={placeholder}
           className='form-control'
           style={{borderColor:error&&"red",backgroundColor:error&&"rgba(255,0,0,.1"}}
           />
        {error && <p style={{color:'red'}}>{error}</p>}
        </div>
    )
}
