import React from 'react'
import { useMutation } from 'react-query'
import Form from '../../../Components/Common/Forms/Form'
import BoxContainer from '../../../Components/CustomerSide/ShipmentInformation/BoxContainer'
import { postDataCarrier } from '../../../globals';
import { createLocalStorageAdmin } from '../../../LocalStorage/Auth';

export default function Login() {


     //Login
   const loginMutation= useMutation((body)=>postDataCarrier(body,`api/admin/login`),{
    onSuccess: (data)=>{
        console.log(data)
        createLocalStorageAdmin(data.authToken,{})
        alert("Success")
    }
  })
  const formikParams ={
    initialValues:{email:"dimar@gmail.com"},
    onSubmit: body=>{
        loginMutation.mutate(body)
    }

}

  return (
    <div>
      <BoxContainer>
        <Form
        title='Admin Login'
        structure={formStructure}
        formikParams={formikParams}
        isLoading={loginMutation.isLoading}
        />
      </BoxContainer>

    </div>
  )
}

const formStructure =[
    {
        type:"input",
        label:"Email",
        name:"email"
    },
    {
        type:"input",
        label:"Password",
        name:"password"
    },


]

