import React, { useState,useEffect } from "react";
import NavBar from "../../Common/NavBar";
import OfferInformation from "../../CustomerSide/Offers/OfferInformation";
import ChatRoom from '../../Chat/index'
import i18next from 'i18next'
import Row from "../../Common/Layout/Row";
import Price from "./InfoRepresantation/Price";
import SuccessFeedbackModal from "../../Common/Modals/SuccessFeeback/SuccessFeedbackModal";
import { useQuery } from "react-query";
import { useParams,useHistory } from "react-router-dom";
import { getDataCustomer } from "../../../globals";
import { Button } from "@material-ui/core";
import AutoPriceWaitingForCarrierCard from './AutoPriceWaitingForCarrierCard';
import Loading from "../../Common/Loading";
import RatingModal from "./RatingModal/RatingModal";
import ShipmentDetails from "./InfoRepresantation/ShipmentDetails";
import StateCard from '../../Common/StateCard';
import CarrierCard from "../../Common/Cards/CarrierCard/CarrierCard";
import BoxContainer from "./BoxContainer";

 export default function ShipmentInformation({}) {
   
  const { id } = useParams();
  const history = useHistory();
  const [shipmentobj, setshipmentobj] = useState({})
  const [showRatingModal, setshowRatingModal] = useState(false)
  const [offers, setoffers] = useState({})
  const [showOfferSuccessModal, setshowOfferSuccessModal] = useState(false)
  //get shipment by id
  const {isLoading,data} = useQuery(['customer','shipmentinfo',id],()=> getDataCustomer(`api/shipments?_id=${id}`),{
    staleTime:2000,
    cacheTime:5000,
    onSuccess:(data)=>{
      setshipmentobj(data?.data[0])
    },
  })
  //get offers
  const {isLoading:offerLoading,data:offersResponse} = useQuery(['customer','shipmentinfo','offers',id],()=> getDataCustomer(`api/shipments/${id}/offers`),{
    staleTime:2000,
    cacheTime:5000,
    onSuccess:(data)=>{
      setoffers(data?.data?.offers)
    }
  })


  if (isLoading) {
    return (
      <Loading/>
    );
  }else if(Object.keys(shipmentobj).length>0){
  return (
    <>
      <NavBar />
       <ChatRoom chat_room_id={shipmentobj.chat_room_id} shipment_type={shipmentobj.status} shipment_id={shipmentobj._id} type='customer' />
      <div
        className="container-fluid"
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          maxHeight: "90vh",
          msOverflowStyle: "none",
        }}
      >
        <div className="row">
         
          <div className="container pt-5">
            <div className='d-flex flex-row justify-content-between'>
                <Row>
                    <h2 className="mb-5">{i18next.t('shipmentinfo.title')}</h2>
                   

                    {String(shipmentobj?.status).toUpperCase()=='COMPLETED' &&  <Button
                    variant="outlined"
                    color='secondary'
                    size="small"
                    style={{maxHeight:35,marginLeft:'2em'}}
                    onClick={()=> setshowRatingModal(true)}
                    >
                    Βαθμολογησε τον Μεταφορεα
                    </Button> }
                </Row>
                
                <Price  price={shipmentobj.price}/>
          </div>
          <StateCard state={shipmentobj?.status}/>
          <ShipmentDetails shipment={shipmentobj}/>
           
            <div className="row">
              
              <div className="col-md-6 col-sm-12 col-lg-6 mt-2">
                {/*---------2ND CARD-------------- */}
                {shipmentobj.status.toUpperCase() == "WAITING" ?
                shipmentobj?.needsOffer===true? (
                  <OfferInformation
                    offers={offers}
                    shipmentid={id}
                    shipment_obj={shipmentobj}
                    setshowOfferSuccessModal={setshowOfferSuccessModal}
                  />
                ) :(<AutoPriceWaitingForCarrierCard/>)
                : (
                  <BoxContainer additionalStyle={{height:'fit-content'}} title={'shipmentinfo.carrier_information'}>
                    <CarrierCard carrier_id={shipmentobj.selected_carrier} />
                  </BoxContainer>
                )}
              </div>
            
              
           </div>
      </div>
     </div>
     </div>

          <SuccessFeedbackModal
          title='Αποδεχτήκατε την προσφορά'
          buttonSubmit='Ok'
          hasHeader={false}
          onSubmit={()=> window.location.reload()}
          show={showOfferSuccessModal} 
          setShow={setshowOfferSuccessModal}
          buttonText="Το κατάλαβα"
          primaryMessage='Αποδεχτήκατε την προσφορά'
          secondaryMessageComponent={<ShipmentAddedSecondaryMessage/>}
          />
          <RatingModal
          show={showRatingModal}
          setShow={setshowRatingModal}
          shipment_id={shipmentobj._id}
          />
    </>
  )}
  else return null 
 }
 
 const ShipmentAddedSecondaryMessage =()=>{
  return (
   <div className='p-3' >
   <h3 style={{color:'#1abc9c',marginBottom:'1em'}}>Πληροφορίες για την έναρξη της μεταφοράς</h3>
   <p>1. Θα λάβετε <bold style={{fontSize:'1.1em',color:'#2E5BFF'}}>Email</bold> κατά την έναρξη της μεταφοράς.</p>
   <p>2. Το κόστος της μεταφοράς θα πρέπει να εξοφληθεί στον μεταφορέα κατά την φόρτωση. </p>
   </div>
   )

 }
