import React,{useEffect} from 'react'
import { getCarrierUserId } from '../../../LocalStorage/Auth';
import NavbarCarrier from '../../Common/NavbarCarrier';
import ShipmentSection from '../../CustomerSide/Shipments/ShipmentSection/ShipmentSection';
import useFetchData from '../../CustomerSide/Shipments/ShipmentSection/useFetchData';

export default function Shipments() {

  const [data,isLoading]= useFetchData(`api/shipments?status[$not]=WAITING&selected_carrier=${getCarrierUserId()}`,['carrier','shipments'],'carrier','data')

  return (
    <>
      
      <NavbarCarrier />
     <div className="container-fluid pt-5" style={{ minHeight: "90vh" }}>
         <div className="row">
             <ShipmentSection
             shipments={data}
             isLoading={isLoading}
             type='carrier'
             initialFilters={`selected_carrier=${getCarrierUserId()}&`}
             />
           </div>
     </div>
    </>
  )
}
