import React from "react";
import VehicalCard from "./VehicalCard";
import VehicalApiCall from "./VehicalApiCall";
import Empty from "../../Common/EmptyCarrier";
import AddButton from "../../Common/AddButtonCarr";
import NavBarCarrier from "../../Common/NavbarCarrier";
import Modal from "react-bootstrap/Modal";
import AddVehicalModal from "./AddVehicalModal";
import { connect } from "react-redux";
import "../../../Styles/CarrierDashboard.css";
import TitleRow from "./TitleRow";
import { trans } from "../../../globals";
import { carrierServices } from "../../../Services/Carriers/carrier_sevices";
import Loading from "../../Common/Loading";

class Vehicles extends VehicalApiCall {
  constructor(props) {
    super(props);
    this.state = {
      record: [],
      loading: true,
      modal: false,
    };
  }
  handleModal = () => {
    this.setState({ modal: !this.state.modal });
  };
  componentDidMount() {
    if (this.props.carrier_AllVehicals.length > 0) {
      return this.setState({
        record: this.props.carrier_AllVehicals,
        loading: false,
      });
    }
    this.requestToLocalStorage();
  }
  render() {
    const { loading } = this.state;
    const count = this.state.record.length;
    if (loading) {
      return <Loading/>;
    } else if (count === 0) {
      return <Empty isDriver={false} title={trans('vehicles.title')} />;
    } else {
      return (
        <React.Fragment>
          <NavBarCarrier />
          <div
            style={{
              height: "90vh",
              overflowY: "auto",
              overflowX: "none",
              msOverflowStyle: "none",
            }}
          >
            <div className="container pt-5">
              <div className="row">
                <div className="col-md-10">
                  <span className="vd-title">{trans('vehicles.title')}</span>
                  <span className="vd-total ml-3">{count} {trans('common.buttons.total')}</span>
                  <div>
                    {/*--------------title start---------- */}
                    <TitleRow/>
                    {/*--------------Cards start---------- */}
                    <div
                      style={{
                        height: "70vh",
                        overflowX: "none",
                        overflowY: "auto",
                        msOverflowStyle: "none",
                      }}
                    >
                      {this.props.carrier_AllVehicals.map((item, index) => {
                        return <VehicalCard key={index} list={item} />;
                      })}
                    </div>
                    {/*--------------Cards end---------- */}
                  </div>
                </div>
                {/*--------------side plus button---------- */}
                <div className="col-2">
                  <div onClick={this.handleModal}>
                   <AddButton />
                  </div>
                  <Modal
                    size="lg"
                    show={this.state.modal}
                    onHide={this.handleModal}
                    backdrop="static"
                    animation="true"
                    aria-labelledby="example-modal-sizes-title-lg"
                  >
                  <AddVehicalModal
                   handleModal={this.handleModal}
                   title="AddVehical"
                  />
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    carrier_AllVehicals: state.vehicalReducer.Vehicals,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllVehicals: (body,callback) =>dispatch(carrierServices.getVehicles(body,callback))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Vehicles);
