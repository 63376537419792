import React,{useState,useEffect} from 'react'
import { trans} from '../../../../globals';
import NoShipmentsFound from '../../../Common/NoShipmentsFound';
import ReactLoading from 'react-loading'
import Card from '../../../Common/Card'
import CardTitle from '../../../Common/CardTitle';
import ShipmentsFilter from './ShipmentsFilter';
import { Button } from '@material-ui/core';
import Row from '../../../Common/Layout/Row';
import {
  getCarrierUserId,
} from '../../../../LocalStorage/Auth'
import { useTheme } from '@material-ui/styles';
import Filters from '../../../Common/Shipments/Filters/Filters';

export default function ShipmentSection({
 isLoading,
shipments,
offerButton,
type='customer',
showFilterTabs =false,
initialFilters=''
}) {
    

  const [filteredShipments, setFilteredShipments] = useState(null)
  const [filterApplied, setFilterApplied] = useState(false)
  const [needsOffer, setNeedsOffer] = useState(false)
  const theme = useTheme();

  useEffect(() => {
   console.log(theme) 
  }, [])

  const renderShipments =()=>{

    console.log(needsOffer)
  
        if(filterApplied===true){
            if(Array.isArray(filteredShipments)){
                if(filteredShipments.length>0){
                   return filteredShipments.map((item, index) =>{
                    if(type=='carrier'){
                      if(showFilterTabs===true){
                      if(item.needsOffer===needsOffer){
                        if(needsOffer===false){
                         if(item.selected_carrier==null){
                           if(Array.isArray(item.carrier_decline)){
                             if(!item.carrier_decline.includes(getCarrierUserId())){
                               return <Card  key={index} offerButton={offerButton} list={item}  type={type} />
                             }
                           }else{
                             return <Card  key={index} offerButton={offerButton} list={item}  type={type} />
                           }
                         }
                        }else{
                         return <Card  key={index} offerButton={offerButton} list={item}  type={type} />
                        }
                       }
                      else return null
                      }else{
                       return <Card  key={index} offerButton={offerButton} list={item}  type={type} />
                      }
                    }else{
                     return <Card key={index} offerButton={offerButton} list={item}  type={type} />
                    }
                   })
                   
                }else{
                    return <NoShipmentsFound type={type} filterMode={filterApplied} /> 
                }
            }
        }else{
            if(Array.isArray(shipments)){
                if(shipments.length>0){
                   return shipments.map((item, index) =>{
                     if(type=='carrier'){
                       if(showFilterTabs===true){
                       if(item.needsOffer===needsOffer){
                         if(needsOffer===false){
                          if(item.selected_carrier==null){
                            if(Array.isArray(item.carrier_decline)){
                              if(!item.carrier_decline.includes(getCarrierUserId())){
                                return <Card  key={index} offerButton={offerButton} list={item}  type={type} />
                              }
                            }else{
                              return <Card  key={index} offerButton={offerButton} list={item}  type={type} />
                            }
                          }
                         }else{
                          return <Card  key={index} offerButton={offerButton} list={item}  type={type} />
                         }
                        }
                       else return null
                       }else{
                        return <Card  key={index} offerButton={offerButton} list={item}  type={type} />
                       }
                     }else{
                      return <Card key={index} offerButton={offerButton} list={item}  type={type} />
                     }
                    }) 
                }else{
                    return <NoShipmentsFound type={type} filterMode={filterApplied}  /> 
                }
            }

        }
  }

    return (
        <>
       <div className="col-md-8 offset-md-1 offset-sm-0 col-sm-12">
        <h2 className="d-inline">{trans("shipmentinfo.shipments")}</h2>

        <span className="vd-total ml-3">
                    {isLoading ? 'Loading...':
                    shipments&& ( 
                    filterApplied?
                    filteredShipments!==null ? filteredShipments?.length:null
                     :
                     shipments!=null? shipments?.length:null)} {trans('common.buttons.total')} </span>

                    {/*--------dont show when no filter data found-------- */}
                    {shipments && Array.isArray(shipments) && shipments.length > 0 ? (
                      <CardTitle />
                    ) : null}
              
                   <div
                    style={{
                      overflowY: "auto",
                      height: "80vh",
                      marginTop:'0.2em',
                      msOverflowStyle: "none",
                      overflowX:'none'
                    }}
                  >
             {isLoading && <Loading/>}
             {(showFilterTabs &&!isLoading) &&
             <>
             <Row className='mt-3 mb-3'>
                <Button color='primary'variant={`${!needsOffer?'outlined':'contained'}`} onClick={()=>setNeedsOffer(true)}>Μεταφορες για Προσφορα</Button>
                <Button className='ml-3' color='primary' variant={`${needsOffer?'outlined':'contained'}`}  onClick={()=>setNeedsOffer(false)}>Μεταφορeς με αυτοματη τιμη</Button>
             </Row>
             </>}
             {shipments&& renderShipments()}

             </div>         
         </div>         
         {shipments &&
         <Filters 
         setFilterApplied={setFilterApplied} 
         setFilteredShipments={setFilteredShipments}
         initialFilters={initialFilters}
         />
        
         }
        </>
    )
}


const Loading = ()=>{
    return (
    <div className='container h-50 d-flex flex-column justify-content-center align-items-center'>
      <ReactLoading type='spin' color='#2E5BFF' height='4em' width='4em'/> 
     </div>
    )
}

