const initialstate = {
  Vehicals: [],
  //any other customer object can be added here and later used
};

const driverReducer = (state = initialstate, action) => {
  if (action.type === "GET_VEHICLES_SUCCESS") {
    return {
      ...state,
      Vehicals: [...action.payload.data.data],
    };
  }

  if (action.type === "ADD_VEHICAL") {
    return {
      ...state,
      Vehicals: [action.data.data.newVehicle, ...state.Vehicals],
    };
  }
  if (action.type === "EDIT_VEHICLES_SUCCESS") {
    const updatedVehical = { ...action.data };
    const updatedVehicalId = action.id;
    const VehicalsRecord = [...state.Vehicals];
    const Index = VehicalsRecord.findIndex((vehical) => {
      return vehical._id === updatedVehicalId;
    });
    VehicalsRecord[Index] = updatedVehical;
    return {
      ...state,
      Vehicals: [...VehicalsRecord],
    };
  }
  if (action.type === "DELETE_VEHICLES_SUCCESS") {
    console.log(action.payload)
    const delete_vehicleId = action.payload;
    const VehicalsRecord = [...state.Vehicals];
    const newRecord = VehicalsRecord.filter((vehical) => {
      return vehical._id !== delete_vehicleId;
    });
    return {
      ...state,
      Vehicals: [...newRecord],
    };
  }
  return state;
};

export default driverReducer;
