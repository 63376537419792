import React, { Component } from "react";
import { connect } from "react-redux";
import ChangePassword from "../../CustomerSide/Profile/ChangePassword";
import { api_callback, trans,notEmptyArray } from "../../../globals";
import NavbarCarrier from "../../Common/NavbarCarrier";
import ProfileData from "../../CarrierSide/Profile/ProfileData";
import { getCarrier, getCarrierUserId, getCarrierObj } from "../../../LocalStorage/Auth";
import PaymentMethod from "../../CarrierSide/Profile/PaymentMethod";
import { carrierServices } from "../../../Services/Carriers/carrier_sevices";

class Account extends Component {
  constructor(props){
    super(props);
    this.state={
    data:[],
    edit_profile:false,
    loadingButton:false,
  }
}
  componentDidMount(){
    this.props.get_carrier_by_id(getCarrierUserId(),this.get_carrier_callback) 
  }
  loadingButton = () => {
    this.setState({ loadingButton: true });
  };
  get_carrier_callback=(res)=>{
    console.log(res)
    if(notEmptyArray(res.data.data.carrier)){
      this.setState({data:res.data.data.carrier[0]})
    }
  }
  change_state=(name,value)=>{
    this.setState({[name]:value})
  }
  changeHandler = ({ target }) => {
    const data = { ...this.state.data };
    data[target.name] = target.value;
    this.setState({ data });
  };
  //---------FOR SWITCH TOGGLER----
  handleSwitchEmail = () => {
    this.setState({ emailNotification: !this.state.emailNotification });
  };
  handleSwitchSms = () => {
    this.setState({ smsNotification: !this.state.smsNotification });
  };
  loadingButton = () => {
    this.setState({ loadingButton: true });
  };
  //----handle the form
  formPersonalData = (e) => {
    e.preventDefault();
    this.loadingButton();
    const {data} = this.state;
    console.log(data)
    const requestBody = {
      carrier_name: data.carrier_name,
      carrier_email: data.carrier_email,
      carrier_phone: data.carrier_phone,
      carrier_username: data.carrier_username,
      carrier_address: data.carrier_address,
      vat_number: data.vat_number,
      tax_office: data.tax_office,
      company_name: data.company_name,
      occupation: data.occupation,
      
    };
    const carrier_id = getCarrierUserId();

    this.props.update_carrier(
      requestBody,
      this.update_carrier_callback,
      carrier_id ,
    );
  
  };
    update_carrier_callback= (success) => {

    this.setState({ loadingButton: false,edit_profile:false });
    api_callback(true,'Επιτυχία','Έγινε αλλαγή των προσωπικών δεδομένων σας')
    return true;
  };
  
  //-----render input field data coming from DB-----------------
  renderInput=(labelTilte, fieldname, fieldType = "text")=> {

    const  data  = this.state.data;
    return (
      <React.Fragment>
        <label className="gray_text">{trans(labelTilte)}</label>
        <input
          type={fieldType}
          name={fieldname}
          className="form-control"
          onChange={this.changeHandler}
          value={data[fieldname]}
          required
        />
      </React.Fragment>
    );
  }
  renderField(labelTilte, value) {
    return (
      <div className="form-group col-md-4">
        <label className="black_text">{trans(labelTilte)}</label>
        <p className='gray_text'>{value}</p> 
      </div>
    );
  }
  //--------------newly data enter-----------------------
  renderNewInput(labelTilte, fieldname, fieldType = "text") {
    const { data } = this.state;
    return (
     
      <div className="form-group col-md-4">
        <label className="gray_text">{trans(labelTilte)}</label>
        <input
          type={fieldType}
          name={fieldname}
          className="form-control"
          onChange={this.changeHandler}
          value={data[fieldname]}
        />
      </div>
    );
  }
  render() {
    console.log(this.state.data)
    const { loadingButton } = this.state;
    return (
      <div
        style={{
          height: "100vh",
          overflowY: "auto",
          overflowX: "none",
          msOverflowStyle: "none",
        }}
      >
        <NavbarCarrier/>
        <div className="container-fluid">
          <div className="container pt-5">
            <h2 className="mb-4">{trans("profile.my_account")}</h2>
            <ProfileData
            renderInput={this.renderInput}
            renderField={this.renderField}
            renderNewInput={this.renderNewInput}
            formPersonalData={this.formPersonalData}
            changeHandler={this.changeHandler}
            state={this.state}
            change_state={this.change_state}
            />
            <PaymentMethod data={this.state.data} renderInput={this.renderInput} />
            <ChangePassword  isCustomer={false}/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
carrier: state.carrierReducer
})

const mapDispatchToProps = (dispatch) => {
return {
     get_carrier_by_id:(carrier_id,callback)=>dispatch(carrierServices.getCarrierById(carrier_id,callback)),
     update_carrier:(requestBody,callback,carrier_id)=>dispatch(carrierServices.updateCarrier(requestBody,callback,carrier_id))
}
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
